import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getCategoriesByID, getProductsByCategory } from '../../api/shop'
import { useNavigate } from 'react-router'
import { CategoriesStyled } from './CategoriesStyled'
import AddToCartLocalStorage from '../../components/addToCartLocalStorage/AddToCartLocalStorage'
import { ProductsStyled } from '../products/ProductsStyled'

function Categories({ inCartProducts }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [catProducts, setCatProducts] = useState([])
  const [categories, setCategories] = useState(null)
  const addToCartStyle = 'multipleProducts'

  useEffect(() => {
    getProductsByCategory(id, (response) => {
      setCatProducts(response)
      getCategoriesByID(id, (response) => {
        setCategories(response)
      })
    })
  }, [id])

  return (
    <>
      {categories !== null && (
        <ProductsStyled>
          <div className='catTitle'>
            <h2>{categories.name}</h2>
          </div>
          <div className='container'>
            <div className='row productList'>
              {catProducts.length > 0 ? (
                catProducts.map((el, index) => {
                  return (
                    <div className='col-3 tumbnailProduct' key={index}>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault()
                          navigate(`/product/${el.product_link}`)
                        }}
                      >
                        <div className='tumbnailImg'>
                          <img
                            src={
                              el.cover_image
                                ? el.cover_image.image_link
                                : 'https://www.aroma-pasiunii.intorsura.ro/media/aroma-pasiunii-no-photo.png'
                            }
                            alt={el.name}
                          />
                        </div>
                        <div className='productName'>
                          <h2>{el.name}</h2>
                        </div>
                        <div className='prodWeight'>
                          Portie: {el.weight} {el.weight_classes.name_short}
                        </div>
                        <div className='price'>
                          {el.price} {el.currency.currency_short}
                        </div>
                      </a>
                      {el.frozen_products === 1 ? (
                        <div className='frozenProduct'>
                          Contine produse congelate
                        </div>
                      ) : (
                        <div className='frozenProduct'></div>
                      )}
                      <div className='addToCart'>
                        <AddToCartLocalStorage
                          index={index}
                          id={el.id}
                          eanCode={el.ean_code}
                          productName={el.name}
                          addToCartStyle={addToCartStyle}
                          inCartProducts={inCartProducts}
                        />
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className='col-12'>
                  <div className='noProducts'>
                    <h2>Nu exista produse in aceasta categorie</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ProductsStyled>
      )}
    </>
  )
}

export default Categories
