import { apiRest } from './config'
// unit data
function getUnitData(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get('/api/unit-data-first')
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getUnitDataActive(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get('/api/unit-data-active')
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getUnitDataHeader(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get('/api/unit-data-header')
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateUnitData(
  unitID,
  unitData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/unit-data/${unitID}`, unitData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// about
function getAboutUnit(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/about`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateAboutUnitActive(
  aboutID,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-about/${aboutID}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateAboutUnit(
  unitID,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/update-about/${unitID}`, formData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateAboutUnitPhoto(
  aboutId,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/update-about-photo/${aboutId}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// function updateAboutUnitPhoto(
//   unitID,
//   formData,
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     .post(`/api/update-about-photo/${unitID}`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
//       },
//     })
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

// update-about/{id}/{uploadFolder}

// special offers

function getAllSpecOffer(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-special-offers`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getFirstSpecOffer(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/first-special-offer`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getActiveSpecOffers(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-active-special-offers`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getSpecOfferById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/special-offer/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateSpecOffersActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-special-offer/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateSpecOffer(
  unitID,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!unitID) {
    request = apiRest.post('/api/add-new-special-offer', formData)
  } else {
    request = apiRest.put(`/api/update-special-offer/${unitID}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateSpecialOfferPhoto(
  id,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/update-special-offer-photo/${id}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// const deleteSpecialOffer = (
//   id,
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) => {
//   apiRest
//     .delete(`/api/special-offer/${id}`)
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

function deleteSpecialOffer(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/special-offer/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// ----- facility -----
function getFacilitys(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-facilitys`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getActiveFacilitys(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-active-facilitys`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getShortFacilitys(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-active-fshort-facilitys`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getFacilityById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/facility/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addOrUpdateFacility(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!id) {
    request = apiRest.post('/api/add-new-facility', formData)
  } else {
    request = apiRest.put(`/api/update-facility/${id}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateFacilityActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateFacilityPrincipal(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/principal-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateFacilityIcon(
  id,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/update-facility-icon/${id}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function deleteFacility(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/facility/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// localization
function getLocalization(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/localization`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateLocalizationActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-localization/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateLocalization(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/update-localization/${id}`, formData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateLocalizationPhoto(
  aboutId,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(
      `/api/update-localization-photo/${aboutId}/${uploadFolder}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
        },
      }
    )
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// ----- working hours -----
function getWorkingHours(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-working-hours`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateWorkingHours(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/update-working-hours/${id}`, formData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// ----- delivery -----
function getDeliveryConditions(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-active-delivry-details`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateDeliveryConditionsActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-delivery-details/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateDeliveryConditions(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/update-delivery-details/${id}`, formData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getAdminDeliveryConditions(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/delivry-details`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getDeliveryPrices(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-active-delivry-prices`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// function getUnitLocalization(
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     .get(`/where-we-are`)
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

// ------ categorys

function getShopCategorys(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/categories`)
    .get(`/api/shop-categories`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getShopCategoryById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/shop-categorie/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addOrUpdateShopCategory(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!id) {
    request = apiRest.post('/api/add-new-shop-category', formData)
  } else {
    request = apiRest.put(`/api/update-shop-category/${id}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateShopCategoryImage(
  id,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/update-shop-category-image/${id}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateShopCategoryActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-shop-category/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateShopCategoryPrincipal(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/principal-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function deleteShopCategory(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/category/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// ----- users
function getAllPaginatedCustomers(
  page,
  orderBy,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/admin-all-paginated-customers?page=${page}&orderby=${orderBy}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// function getAllCustomers(
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     // .get(`/categories`)
//     .get(`/api/customers`)
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

function getAllCustomers(
  orderBy,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/categories`)
    .get(`/api/admin-all-customers?orderby=${orderBy}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getSCustomerById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/customer/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addOrUpdateCustomer(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!id) {
    request = apiRest.post('/api/add-new-shop-category', formData)
  } else {
    request = apiRest.put(`/api/update-shop-category/${id}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateCustomerActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-shop-category/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function deleteCustomer(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/delete-customer/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

export {
  getUnitDataHeader,
  getUnitData,
  getUnitDataActive,
  updateUnitData,
  getAboutUnit,
  updateAboutUnitActive,
  updateAboutUnit,
  updateAboutUnitPhoto,
  // ---
  getAllSpecOffer,
  getFirstSpecOffer,
  getActiveSpecOffers,
  getSpecOfferById,
  updateSpecOffersActive,
  updateSpecOffer,
  updateSpecialOfferPhoto,
  deleteSpecialOffer,
  // ---
  getFacilitys,
  getActiveFacilitys,
  getShortFacilitys,
  getFacilityById,
  addOrUpdateFacility,
  updateFacilityActive,
  updateFacilityPrincipal,
  updateFacilityIcon,
  deleteFacility,
  // ---
  getLocalization,
  updateLocalizationActive,
  updateLocalization,
  updateLocalizationPhoto,
  // ---
  getWorkingHours,
  updateWorkingHours,
  // ---
  getDeliveryConditions,
  getAdminDeliveryConditions,
  updateDeliveryConditionsActive,
  updateDeliveryConditions,
  // ---
  getDeliveryPrices,
  // ---
  // getUnitLocalization,
  // ---
  getShopCategorys,
  getShopCategoryById,
  addOrUpdateShopCategory,
  updateShopCategoryActive,
  updateShopCategoryPrincipal,
  updateShopCategoryImage,
  deleteShopCategory,
  // ---
  getAllPaginatedCustomers,
  getAllCustomers,
  getSCustomerById,
  addOrUpdateCustomer,
  updateCustomerActive,
  deleteCustomer,
}
