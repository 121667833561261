import React, { useState, useEffect } from 'react'

import {
  AddToCartMultipleStyled,
  AddToCartSingleStyled,
} from './AddToCartLocalStorageStyled'
import SuccesAlert from '../alert/SuccesAlert'

function AddToCartLocalStorage({
  id,
  eanCode,
  productName,
  addToCartStyle,
  inCartProducts,
}) {
  // console.log(inCartProducts)

  const TemplateStyle =
    addToCartStyle === 'multipleProducts'
      ? AddToCartMultipleStyled
      : AddToCartSingleStyled

  const [quantity, setQuantity] = useState(1)
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart')
    return savedCart ? JSON.parse(savedCart) : []
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  // Persist cart to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart))
  }, [cart])

  const handleQuantity = (e) => {
    const newValue = parseInt(e.target.value, 10)
    setQuantity(isNaN(newValue) || newValue < 1 ? 1 : newValue)
  }

  const decreaseValue = (e) => {
    e.preventDefault()
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1))
  }

  const increaseValue = (e) => {
    e.preventDefault()
    setQuantity((prevValue) => prevValue + 1)
  }

  const handleShowAlert = (message) => {
    setAlertMessage(message)
    setShowAlert(true)
    setTimeout(() => setShowAlert(false), 1000)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Retrieve the current cart from localStorage
    const storedCart = JSON.parse(localStorage.getItem('cart')) || []

    // Check if the product already exists in the cart
    const existingItem = storedCart.find((item) => item.ean_code === eanCode)

    let updatedCart
    // If product exists, update its quantity
    if (existingItem) {
      updatedCart = storedCart.map((item) =>
        item.ean_code === eanCode
          ? { ...item, quantity: item.quantity + quantity }
          : item
      )
    } else {
      // If product doesn't exist, add it to the cart
      updatedCart = [
        ...storedCart, // Spread the previous cart to keep existing products
        { id, ean_code: eanCode, name: productName, quantity }, // Add the new product
      ]
    }
    // Update localStorage with the new cart
    localStorage.setItem('cart', JSON.stringify(updatedCart))

    //  Update the cart state
    setCart(updatedCart)

    // Show success alert
    handleShowAlert(
      `Produsul "${productName}" a fost adaugat in cos. Cantitate: ${quantity}`
    )

    inCartProducts()
    // Reset quantity after adding
    setQuantity(1)
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <TemplateStyle>
        <form id={eanCode} onSubmit={handleSubmit}>
          <div className='inputData'>
            <button type='button' className='decrease' onClick={decreaseValue}>
              -
            </button>
            <input
              type='number'
              name='quantity'
              id='quantity'
              className='quantityInput'
              value={quantity}
              onChange={handleQuantity}
              min='1'
            />
            <button type='button' className='increase' onClick={increaseValue}>
              +
            </button>
          </div>
          <button type='submit' className='addToCart'>
            Comanda
          </button>
        </form>
      </TemplateStyle>
    </>
  )
}

export default AddToCartLocalStorage
