import React from 'react'
import { TogleSwitchStyled } from './TogleSwitchStyled'

function TogleSwitch({ checked, onChange, label }) {
  return (
    <TogleSwitchStyled>
      <span className='label'>{label}</span>
      <div className='switch-wrapper'>
        <input type='checkbox' checked={checked} onChange={onChange} />
        <span className='slider round'></span>
      </div>
    </TogleSwitchStyled>
  )
}

export default TogleSwitch
