import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  getAdminDeliveryConditions,
  updateDeliveryConditions,
  updateDeliveryConditionsActive,
} from '../../api/unitDataAPI'
import { DeliveryFormStyled } from './DeliveryFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButton,
  GreenSubmitButton,
} from '../../styles/elements/buttons'

import SuccesAlert from '../alert/SuccesAlert'
import ErrorAlert from '../alert/ErrorAlert'

import BackToSetings from '../../views/buttons/backToSetings/BackToSetings'
import SingleImageUploader from '../singleImageUploader/SingleImageUploader'
import FadeLoading from '../loaders/fadeLoading/FadeLoading'

const cleanFormData = {
  // unit_id: '',
  title: '',
  description: '',
  // image: '',
}

const validation = {
  title: (data) => data.title.length < 10,
  description: (data) => data.description.length < 50,
}

function DeliveryForm() {
  const [formData, setFormData] = useState(cleanFormData)
  const [deliveryConditions, setDeliveryConditions] = useState({})
  const [showChangeAboutImage, setShowChangeAboutImage] = useState(null)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const ActivateButtonComponent =
    deliveryConditions.active === 1 ? RedSubmitButton : GreenSubmitButton

  useEffect(() => {
    getAdminDeliveryConditions((response) => {
      setDeliveryConditions(response)
    })
  }, [])

  useEffect(() => {
    if (deliveryConditions && Object.keys(deliveryConditions).length > 0) {
      setFormData({
        title: deliveryConditions.title || '',
        description: deliveryConditions.description || '',
        // image: deliveryConditions.image || '',
      })
    }
  }, [deliveryConditions])

  const handleChange = (e) => {
    const { name, value } = e.target

    // Handle other fields
    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleActivateSection = (event) => {
    event.preventDefault()
    const newActive =
      deliveryConditions.active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateDeliveryConditionsActive(
      deliveryConditions.id,
      newActive,
      handleActivateSuccess,
      handleError
    )
  }

  function handleActivateSuccess(response) {
    console.log(response.active)

    setAlertMessage(
      'Sectiunea a fost ' +
        (deliveryConditions.active === 1 ? 'dezactivata' : 'activata') +
        ' cu succes!'
    )
    setShowAlert(true)

    // Reset loading state
    setIsLoading(false)
    // set new unit data
    setDeliveryConditions(response)
    const timer = setTimeout(() => {
      // reload page
      window.location.reload()
    }, 2100)
    return () => clearTimeout(timer)
  }

  // console.log(formData.image);
  const handleSubmit = (event) => {
    event.preventDefault()

    // Set loading state to true
    setIsLoading(true)

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      updateDeliveryConditions(
        deliveryConditions.id,
        formData,
        handleSuccess,
        handleError
      )
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Datele au fost modificate cu succes!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    // const timer = setTimeout(() => {
    //   // window.location.reload()
    //   // window.location.href = '/setari'
    //   setShowChangeAboutImage(
    //     showChangeAboutImage === deliveryConditions.id
    //       ? null
    //       : deliveryConditions.id
    //   )
    // }, 2100)
    // return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      {deliveryConditions ? (
        <DeliveryFormStyled>
          <div className='title'>
            <h2>Detalii pentru livrari</h2>
          </div>
          <div className='changeStatusFormContainer'>
            <ActivateButtonComponent
              onClick={handleActivateSection}
              disabled={isLoading}
            >
              {deliveryConditions.active === 1
                ? isLoading
                  ? 'Asteapta! Se incarca...'
                  : 'Dezactiveaza sectiunea'
                : isLoading
                ? 'Asteapta! Se incarca...'
                : 'Activeaza sectiunea'}
            </ActivateButtonComponent>
          </div>
          <GeneralFormStyled>
            <form onSubmit={handleSubmit}>
              <div className='oneField'>
                <div>
                  <FieldLabel>
                    Titlu
                    {errors.includes('title') === true ? (
                      <ErrorLabel>Titlu prea scurt!</ErrorLabel>
                    ) : formData.title.length >= 10 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 10 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='title'
                    value={formData.title}
                    onChange={handleChange}
                    placeholder='Titlu'
                  />
                </div>
              </div>
              <div className='oneField'>
                <div>
                  <FieldLabel>
                    Descriere unitate
                    {errors.includes('description') === true ? (
                      <ErrorLabel>Descrierea este prea scurta!</ErrorLabel>
                    ) : formData.description.length >= 50 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <textarea
                    name='description'
                    value={formData.description}
                    onChange={handleChange}
                    placeholder='Descriere unitate'
                  />
                </div>
              </div>
              <div className='aboutImage'>
                <img src={deliveryConditions.image} alt='' />
              </div>
              <div className='submitContainer'>
                <SubmitButton disabled={isLoading}>
                  {isLoading
                    ? 'Asteapta! Se incarca...'
                    : !deliveryConditions.title
                    ? 'Salveaza'
                    : 'Modifica datele'}
                </SubmitButton>
              </div>
            </form>
          </GeneralFormStyled>
          {/* {deliveryConditions.id && (
            <div className='changeImageButtonFormContainer'>
              Nu este obilgatoriu sa incarcati o imagine dar poate avea un
              impact placut pentru utilizatori.
              <RedSubmitButton
                onClick={() => {
                  setShowChangeAboutImage(
                    showChangeAboutImage === deliveryConditions.id
                      ? null
                      : deliveryConditions.id
                  )
                }}
              >
                {deliveryConditions.image
                  ? 'Schimba imaginea'
                  : 'Adauga imagine'}
              </RedSubmitButton>
              {showChangeAboutImage === deliveryConditions.id ? (
                <SingleImageUploader
                  apiName={'updateDeliveryConditionsPhoto'}
                  id={deliveryConditions.id}
                  formTitle={
                    deliveryConditions.image
                      ? 'Schimba imaginea'
                      : 'Adauga imagine'
                  }
                  oldImage={deliveryConditions.image}
                  uploadFolder={'about'}
                  fileType={''}
                  cancel={() => setShowChangeAboutImage(null)}
                  backUrl={'setari'}
                />
              ) : (
                (document.body.style.overflow = '') // Re-enable body scroll
              )}
            </div>
          )} */}
        </DeliveryFormStyled>
      ) : (
        <FadeLoading />
        // <p>Incarc datele</p>
      )}
    </>
  )
}

export default DeliveryForm
