import React, { useEffect, useState } from 'react'
import { PageLocalizationStyled } from './PageLocalizationStyled'
import { getLocalization } from '../../../api/unitDataAPI'
import GoogleMap from '../../googleMaps/GoogleMap'

function PageLocalization() {
  const [localization, setLocalization] = useState([])

  useEffect(() => {
    getLocalization((response) => {
      setLocalization(response)
    })
  }, [])

  return (
    <>
      {localization ? (
        <PageLocalizationStyled id='localization'>
          <article>
            <div className='col-12 title'>
              <h2>{localization.title}</h2>
            </div>
            <div className='articleContainer'>
              <div className='map'>
                <GoogleMap gps={localization.gps} />
              </div>
              <div className='description'>{localization.description}</div>
            </div>
          </article>
        </PageLocalizationStyled>
      ) : (
        ''
      )}
    </>
  )
}

export default PageLocalization
