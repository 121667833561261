import { apiRest } from './config'

// ------ categorys
function getProductCategorys(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/categories`)
    .get(`/api/categories`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getProductCategorysByID(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/categories/${id}`)
    .get(`/api/categories/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getProductsByCategory(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/products/category/${id}`)
    .get(`/api/products/categories/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// ------ products
function getProducts(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/products`)
    .get(`/api/products`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// function getAllProducts(
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     // .get(`/products`)
//     .get(`/api/all-products`)
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

function getAllPaginatedProducts(
  page,
  orderBy,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/admin-all-paginated-products?page=${page}&orderby=${orderBy}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getAllProducts(
  orderBy,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/admin-all-products?orderby=${orderBy}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getProductByName(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/product/${id}`)
    .get(`/api/product-by-name/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getProductById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/product/${id}`)
    .get(`/api/product-by-id/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getProductForUpdateById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .get(`/product/${id}`)
    .get(`/api/product-for-update-by-id/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addOrUpdateProduct(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!id) {
    request = apiRest.post('/api/add-new-product', formData)
  } else {
    request = apiRest.put(`/api/update-product/${id}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateProductActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-product/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateProductPrincipal(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/principal-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function uploadProductImages(
  // id,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    // .post(`/api/upload-product-images/${id}/${uploadFolder}`, formData, {
    .post(`/api/upload-product-images/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateActivateCoverPhoto(
  id,
  coperta,
  eanCode,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-product-cover-photo/${id}/${eanCode}`, coperta)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function dleleteProductImage(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/delete-product-image/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function deleteProduct(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/product/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// units of mesurement
function getUM(successCallback, errorCallback = (err) => console.log(err)) {
  apiRest
    .get(`/api/um`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getUMByID(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/um-by-id/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addOrUpdateUM(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!id) {
    request = apiRest.post('/api/add-new-facility', formData)
  } else {
    request = apiRest.put(`/api/update-facility/${id}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateUMActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function deleteUM(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/facility/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// ----- currency -----
function getCurrency(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-currencys`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getCurrencyById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/facility/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addOrUpdateCurrency(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!id) {
    request = apiRest.post('/api/add-new-facility', formData)
  } else {
    request = apiRest.put(`/api/update-facility/${id}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateCurrencyActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateCurrencyPrincipal(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/principal-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateCurrencyIcon(
  id,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/update-facility-icon/${id}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function deleteCurrency(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/facility/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// selectOption
// export function getSelectOption(
//   selectedAPI,
//   successCallback,
//   errorCallback = (err) => console.error(err)
// ) {
//   let request

//   if (selectedAPI === 'category') {
//     request = apiRest.get('/api/categories')
//   } else if (selectedAPI === 'currency') {
//     request = apiRest.get('/api/all-currencies')
//   } else if (selectedAPI === 'weightUnits') {
//     request = apiRest.get('/api/um')
//   } else {
//     return errorCallback(new Error('Invalid selectedAPI value'))
//   }

//   request
//     .then(({ data }) => successCallback(data))
//     .catch((err) => errorCallback(err))
// }

function getSelectOption(
  selectedAPI,
  successCallback,
  errorCallback = (err) => console.error(err)
) {
  let request

  if (selectedAPI === 'product_category') {
    request = apiRest.get('/api/categories')
  } else if (selectedAPI === 'currency') {
    request = apiRest.get('/api/all-currencys')
  } else if (selectedAPI === 'units') {
    request = apiRest.get('/api/um')
  } else {
    return errorCallback(new Error('Invalid selectedAPI value'))
  }

  request
    .then(({ data }) => successCallback(data))
    .catch((err) => errorCallback(err))
}

export {
  // categorys
  getProductCategorys,
  // products
  getProducts,
  getAllProducts,
  getAllPaginatedProducts,
  getProductByName,
  getProductById,
  getProductForUpdateById,
  getProductsByCategory,
  getProductCategorysByID,
  addOrUpdateProduct,
  updateProductActive,
  updateProductPrincipal,
  uploadProductImages,
  updateActivateCoverPhoto,
  dleleteProductImage,
  deleteProduct,
  // UM
  getUM,
  getUMByID,
  addOrUpdateUM,
  updateUMActive,
  deleteUM,
  // currency
  getCurrency,
  getCurrencyById,
  addOrUpdateCurrency,
  updateCurrencyActive,
  updateCurrencyPrincipal,
  updateCurrencyIcon,
  deleteCurrency,
  // select option
  getSelectOption,
}
