import styled from 'styled-components'
import { popUpFomsColors } from '../../styles/setings/colors'
import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens'

export const PopUpLevel2Styled = styled.div`
  // margin: auto;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow-y: scroll; */
  z-index: 101;
  background-color: ${popUpFomsColors.popUpFormContainerBgColor};

  .popUpLevel2Container {
    width: 100%;
    max-width: 800px;
    margin: 0px 0 15px 0;
    /* background-color: grey; */
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and other WebKit-based browsers */
    }
  }

  .cancelButtonContainer {
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 0 5px 0;

    .closeButton {
      width: 100%;
      max-width: 800px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      margin-top: 25px;
      padding: 10px 25px 10px 25px;
      border-radius: 50px;
      border: solid #ccc 2px;
      background-color: #6c6c6c;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #474747;
        border: solid #474747 2px;
      }

      ${mobile`
          margin: 10px 0;
          width: 80%;
      `}
    }
  }
`
