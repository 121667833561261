import { apiRest } from './config'

// ----------- 1 ---------
function forgotAdminPassword(
  email,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post('/admin/forgot-password', { email })

    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}
// -------------------
function showResetAdminPasswordForm(
  token,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/admin/reset-password/${token}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}
// -------------------
function resetAdminPassword(
  formData,
  token,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post('/admin/reset-password', { ...formData, token })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}
// -------------
function updateAdminPassword(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/admin/update-password/${id}`, formData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

export {
  forgotAdminPassword,
  showResetAdminPasswordForm,
  resetAdminPassword,
  updateAdminPassword,
}
