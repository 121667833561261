import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  InfoContainer,
  CancelButton,
  UploadInfo,
  PreviewContainer,
  PreviewItem,
  PreviewImage,
  DeleteButton,
  SelectButton,
  UploadButton,
  ErrorMessage,
  ImagesUploaderStyled,
} from './ImagesUploaderStyled'
import SuccesAlert from '../alert/SuccesAlert'
import ErrorAlert from '../alert/ErrorAlert'
import { uploadProductImages } from '../../api/shopAPI'

const apiFunctions = {
  uploadProductImages,
}

const ImageUploader = ({
  apiName,
  id,
  formTitle,
  oldImage,
  uploadFolder,
  fileType,
  cancel,
  backUrl,
  productName,
  eanCode,
}) => {
  const [images, setImages] = useState([])
  const [error, setError] = useState(null)
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingImages, setIsLoadingImages] = useState(false)
  const navigate = useNavigate()

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files)
    handleFiles(files)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const files = Array.from(e.dataTransfer.files)
    handleFiles(files)
  }

  // const handleFiles = (files) => {
  //   const fileSizeLimit = 15 * 1024 * 1024 // 15MB in bytes
  //   const maxResolution = 1024 // Maximum resolution for width or height
  //   const resizedFiles = []

  //   files.forEach((file) => {
  //     if (file.size > fileSizeLimit) {
  //       setError('Fișierul depășește limita de 15MB.')
  //       return
  //     }

  //     const fileType = file.type.split('/')[1]
  //     if (fileType === 'png' && file.type !== 'image/png') {
  //       setError('Accept doar fisiere png.')
  //       return
  //     } else if (
  //       fileType !== 'png' &&
  //       !['image/png', 'image/jpeg'].includes(file.type)
  //     ) {
  //       setError('Accept doar fisiere png sau jpg.')
  //       return
  //     }

  //     const reader = new FileReader()
  //     reader.onloadend = () => {
  //       const img = new Image()
  //       img.src = reader.result

  //       img.onload = () => {
  //         // Determine the scaling factor based on both resolution and size
  //         let scaleFactor = 1

  //         // Scale down if image width or height exceeds maxResolution
  //         if (img.width > maxResolution || img.height > maxResolution) {
  //           const resolutionScale =
  //             maxResolution / Math.max(img.width, img.height)
  //           scaleFactor = Math.min(scaleFactor, resolutionScale)
  //         }

  //         // Additional scale down if file size is above 5MB
  //         if (file.size > 5 * 1024 * 1024) {
  //           const sizeScale = Math.sqrt((5 * 1024 * 1024) / file.size)
  //           scaleFactor = Math.min(scaleFactor, sizeScale)
  //         }

  //         // Apply scaling only if needed
  //         if (scaleFactor < 1) {
  //           const canvas = document.createElement('canvas')
  //           const ctx = canvas.getContext('2d')

  //           canvas.width = img.width * scaleFactor
  //           canvas.height = img.height * scaleFactor
  //           ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

  //           canvas.toBlob(
  //             (blob) => {
  //               if (blob.size > 5 * 1024 * 1024) {
  //                 setError('Nu am reușit sa reduc imaginea sub 5MB.')
  //                 return
  //               }

  //               const resizedFile = new File([blob], file.name, {
  //                 type: file.type,
  //                 lastModified: Date.now(),
  //               })

  //               resizedFiles.push({
  //                 image: resizedFile,
  //                 previewUrl: canvas.toDataURL(file.type),
  //               })

  //               setImages((prevImages) => [...prevImages, ...resizedFiles])
  //               setError(null)
  //             },
  //             file.type,
  //             0.8
  //           )
  //         } else {
  //           // No resizing needed, add file as is
  //           resizedFiles.push({
  //             image: file,
  //             previewUrl: reader.result,
  //           })

  //           setImages((prevImages) => [...prevImages, ...resizedFiles])
  //           setError(null)
  //         }
  //       }
  //     }

  //     reader.readAsDataURL(file)
  //   })
  // }

  const handleFiles = (files) => {
    setIsLoadingImages(true) // Start loading state
    const fileSizeLimit = 15 * 1024 * 1024 // 15MB in bytes
    const maxResolution = 1024 // Maximum resolution for width or height

    // Map files to promises that resolve when processing each image completes
    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        if (file.size > fileSizeLimit) {
          setError('Fișierul depășește limita de 15MB.')
          return reject('Size limit exceeded')
        }

        const fileType = file.type.split('/')[1]
        if (fileType === 'png' && file.type !== 'image/png') {
          setError('Accept doar fisiere png.')
          return reject('Invalid file type')
        } else if (
          fileType !== 'png' &&
          !['image/png', 'image/jpeg'].includes(file.type)
        ) {
          setError('Accept doar fisiere png sau jpg.')
          return reject('Invalid file type')
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          const img = new Image()
          img.src = reader.result

          img.onload = () => {
            let scaleFactor = 1

            if (img.width > maxResolution || img.height > maxResolution) {
              const resolutionScale =
                maxResolution / Math.max(img.width, img.height)
              scaleFactor = Math.min(scaleFactor, resolutionScale)
            }

            if (file.size > 5 * 1024 * 1024) {
              const sizeScale = Math.sqrt((5 * 1024 * 1024) / file.size)
              scaleFactor = Math.min(scaleFactor, sizeScale)
            }

            if (scaleFactor < 1) {
              const canvas = document.createElement('canvas')
              const ctx = canvas.getContext('2d')
              canvas.width = img.width * scaleFactor
              canvas.height = img.height * scaleFactor
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

              canvas.toBlob(
                (blob) => {
                  if (blob.size > 5 * 1024 * 1024) {
                    setError('Nu am reușit sa reduc imaginea sub 5MB.')
                    return reject('Failed to resize image')
                  }

                  const resizedFile = new File([blob], file.name, {
                    type: file.type,
                    lastModified: Date.now(),
                  })

                  resolve({
                    image: resizedFile,
                    previewUrl: canvas.toDataURL(file.type),
                  })
                },
                file.type,
                0.8
              )
            } else {
              resolve({
                image: file,
                previewUrl: reader.result,
              })
            }
          }
        }

        reader.readAsDataURL(file)
      })
    })

    // Wait for all images to be processed, then update the state
    Promise.all(filePromises)
      .then((resizedFiles) => {
        setImages((prevImages) => [...prevImages, ...resizedFiles])
        setError(null)
      })
      .catch(() => {
        // Handle errors if any file processing fails
        setError('Failed to process one or more images')
      })

      .finally(() => {
        setIsLoadingImages(false) // End loading state
      })
  }

  const handleDeleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!images.length) {
      setError('Please select at least one image')
      return
    }

    const apiFunction = apiFunctions[apiName]
    if (!apiFunction) {
      setError(`Invalid API function: ${apiName}`)
      return
    }

    setIsLoading(true)
    try {
      await Promise.all(
        images.map(async ({ image }) => {
          const formData = new FormData()
          formData.append('image', image)
          formData.append('imageName', image.name)
          formData.append('productName', productName)
          formData.append('eanCode', eanCode)
          await apiFunction(
            // id,
            uploadFolder,
            formData,
            handleSuccess,
            handleError
          )
        })
      )
    } catch (error) {
      handleError(error)
    }
  }

  const handleError = (error) => {
    const errorResponse = error.response?.data || {}
    const parsedErrors = Object.values(errorResponse).flat()
    setResponseErrors(parsedErrors)
    setIsLoading(false)
  }

  const handleSuccess = () => {
    setAlertMessage('Images uploaded successfully!')
    setShowAlert(true)
    setImages([])
    setIsLoading(false)
    const timer = setTimeout(() => {
      navigate(`/${backUrl}`, { state: { id } })
      // window.location.href = `/${backUrl}`
      cancel()
      document.body.style.overflow = ''
    }, 2100)
    return () => clearTimeout(timer)
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}
      <ImagesUploaderStyled>
        <Container onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
          <InfoContainer>
            <h2>{formTitle}</h2>
            <CancelButton onClick={cancel}>Renunta</CancelButton>
            <UploadInfo>Support fisiere JPG sau PNG</UploadInfo>
          </InfoContainer>
          <div className='divSelect'>
            {/* <SelectButton> */}
            <SelectButton disabled={isLoadingImages}>
              {isLoadingImages
                ? 'Te rog asteapta procesez imaginile ...'
                : 'Alege imagini sau trage aici'}
              {isLoadingImages ? (
                ''
              ) : (
                <input
                  type='file'
                  accept='image/*'
                  multiple
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
              )}
            </SelectButton>
          </div>
          {images.length > 0 && (
            <PreviewContainer>
              {images.map((img, index) => (
                <PreviewItem key={index}>
                  <PreviewImage src={img.previewUrl} alt='Preview' />
                  <DeleteButton onClick={() => handleDeleteImage(index)}>
                    X
                  </DeleteButton>
                </PreviewItem>
              ))}
            </PreviewContainer>
          )}
          {images.length > 0 && !isLoading && (
            <UploadButton onClick={handleSubmit}>
              {isLoading ? 'Asteapta! Se incarca...' : 'Incarca imaginile!'}
            </UploadButton>
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Container>
      </ImagesUploaderStyled>
    </>
  )
}

export default ImageUploader
