import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens'
import { COLORS } from '../../styles/setings/colors'

export const CategoriesMenuStyled = styled.div`
  /* border: solid #fff 1px; */
  height: auto;
  width: 100%;
  /* margin: auto; */
  position: sticky;
  top: 120px;
  z-index: 1;
  ${desktop`
       display:none;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
     `}
  ${tablet`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
     `}
  ${mobile`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 180px;
     `}


  .catTitle {
    width: 100%;
    display: flex;
    /* border: solid #fff 1px; */
    padding: 10px 0;
    background-color: #2c2c2c;

    h2 {
      display: inline-flex;
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};
      ${desktop`
       font-size: 1.5em;
       align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
     `}
      ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
      `}
    ${mobile`
      margin-left: 0;
      font-size: 1.3em;
    `}
    }
  }

  .categoryList {
    width: 100%;
    margin: 20px 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    position: relative;
    width: 200px;
    height: 120px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #0b0b0b;
    border: none;
    cursor: pointer;
    ${mobile`
      height: 40px;
      padding: 10px 25px;

    `}

    .imageContainer {
      height: 100%;
      border-radius: 15px;
      overflow: hidden; /* Ensure the image doesn't spill out */
      position: absolute;
      /* Allows stacking with text */
      opacity: 0.5; /* Apply opacity to the image container */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:hover {
        opacity: 0.2;
      }
      ${mobile`
        display: none;
      `}

      img {
        width: 200px;
        height: 125px;
        object-fit: cover;
      }
    }

    .categoryName {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 1; /* Ensures text is above the image */

      h2 {
        font-size: 1.5em;
        font-weight: bold;
        color: #fff;
        /* text-shadow: #000 5px 0 10px; */
        text-shadow: 3px 3px 0 #0b0b0b, -3px 3px 0 #0b0b0b, -3px -3px 0 #0b0b0b,
          3px -3px 0 #0b0b0b;
      }
    }
  }
`
