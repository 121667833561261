import { apiRest } from './config'

function getAllDataJudete(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-data-judete`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getJudeteNames(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/judete-names`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getLocalitati(
  judetId,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/localitati/${judetId}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getLocalitatiNamesByJudet(
  selectedJudet,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/localitati-names-by-judet/${selectedJudet}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}
export {
  getAllDataJudete,
  getJudeteNames,
  getLocalitati,
  getLocalitatiNamesByJudet,
}
