import React, { useEffect, useState } from 'react'
import capitalizeFirstLetter from '../../functions/capinalizeFirstLetter'
import { OrderCustomerDataStyled } from './OrderCustomerDataStyled'

function OrderCustomerData({ customerData }) {
  // const [customerData, setCustomerData] = useState([])

  // useEffect(() => {
  //   const customerData = JSON.parse(localStorage.getItem('userData'))
  //   if (customerData) {
  //     setCustomerData(customerData)
  //   }
  // }, [])

  return (
    <>
      <OrderCustomerDataStyled>
        <div className='col-12 artTitle'>
          <h2>Date de livrare</h2>
        </div>

        <div className='customerDetailsList'>
          <div className='customerImage'>
            <img
              src='https://www.aroma-pasiunii.intorsura.ro/media/icons/userAvatar.png'
              alt='Aroma Pasiunii'
            />
          </div>
          <div className='customerData'>
            <div className='customerName'>
              <span>Nume: </span>
              <h3>
                {capitalizeFirstLetter(customerData.first_name)}{' '}
                {capitalizeFirstLetter(customerData.last_name)}
              </h3>
            </div>
            <div className='customerDetails'>
              <span>Email:</span> {customerData.email}
            </div>
            <div className='customerDetails'>
              <span>Telefon:</span> {customerData.phone}
            </div>
            <div className='customerDetails'>
              <span>Adresa:</span> {customerData.city}
              {customerData.street && ', Strada: ' + customerData.street}
              {customerData.street_nr && ', Nr.: ' + customerData.street_nr}
              {customerData.building && ', Bloc: ' + customerData.building}
              {customerData.staircase && ', Sc.: ' + customerData.staircase}
              {customerData.apartment && ', Ap.: ' + customerData.apartment}
            </div>

            {customerData.intercom && (
              <div>
                <span>Nr. interfon.:</span>
                {customerData.intercom}
              </div>
            )}
          </div>
        </div>
      </OrderCustomerDataStyled>
    </>
  )
}

export default OrderCustomerData
