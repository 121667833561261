import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens'
import { COLORS, popUpFomsColors } from '../../styles/setings/colors'

export const IconUploaderStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* opacity: 0.8; */
  background-color: ${popUpFomsColors.popUpFormContainerBgColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .formContainer {
    max-width: 800px;
    opacity: 1;
    padding: 15px 10px;
    border-radius: 15px;
    box-shadow: 5px 5px 5px ${popUpFomsColors.popUpFormShadowColor};

    background-color: ${popUpFomsColors.popUpFormBackgroundColor};
    .infoContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h2 {
        display: flex;
        text-decoration: underline;
        text-decoration-thickness: 4px;
        text-decoration-color: ${COLORS.colorTextUnderlineDefault};
        text-align: center;
        padding: 5px 0;

        ${desktop`
        align-items: flex-end;
        justify-content: flex-start;    
        font-size: 1.5rem;
        font-weight: bold;
      `}
        ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
      `}
      ${mobile`
          align-items: center;
          justify-content: center;    
          width: 100%;
          font-size: 1.5rem;
          font-weight: bold;
      `}
      }

      .cancelButtonContainer {
        margin-bottom: 25px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 0 5px 0;

        .closeButton {
          width: 600px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5em;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          margin-top: 25px;
          padding: 10px 25px 10px 25px;
          border-radius: 50px;
          border: solid #ccc 2px;
          background-color: #6c6c6c;
          color: #fff;
          cursor: pointer;

          &:hover {
            background-color: #474747;
            border: solid #474747 2px;
          }

          ${mobile`
          margin: 10px 0;
          width: 80%;
      `}
        }
      }
      .uploadInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        span {
          min-width: 300px;
        }
      }
    }
    .imageUploadFormContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      /* flex-direction: column; */
      ${desktop`
        // flex-direction: column;
        `}
      ${tablet`
        // flex-direction: column;
        `}
        ${mobile`
        flex-direction: column;
        `} /*
        div {
    width: 50%;
    margin-right: 5px;
    background-color: grey;
  } */
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .previewContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #5c1914;
        width: 220px;
        height: 220px;
        margin: 10px;
      }
      .imagePreview {
        width: 100%;
        max-height: 90px;
        object-fit: contain;
        margin: 10px 0;
      }
      /* img {
    width: 150px;
    height: auto;
    margin-top: 10px;
    background-color: #4caf50;
  } */
      input {
        padding: 10px 15px;
        border: transparent;
        margin: 10px;
        background-color: #fff;
        font-size: 1.2em;
        font-weight: bold;
      }

      /* button {
    padding: 10px 15px;
    border-radius: 5px;
    border: transparent;
    margin: 10px;
    background-color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    width: 220px;
    cursor: pointer;
  } */
      .previewText {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2em;
        font-weight: bold;
        background-color: #b93329;
        color: #fff;
        width: 100%;
        padding: 10px 20px;
        margin-bottom: 10px;
      }
      .selectButton {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: transparent;
        margin: 10px;
        border-radius: 5px;
        font-size: 1.2em;
        font-weight: bold;
        width: 200px;
        padding: 10px 20px;
        background-color: #235425;
        cursor: pointer;
        transition: background-color 0.4s;
        &:hover {
          background-color: #4caf50;
        }
      }

      .uploadButton {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: transparent;
        margin: 10px;
        border-radius: 5px;
        font-size: 1.2em;
        font-weight: bold;
        width: 220px;
        height: 220px;
        padding: 10px 20px;
        background-color: #235425;
        cursor: pointer;
        transition: background-color 0.4s;
        ${mobile`
      height: auto;
    `}
        &:hover {
          background-color: #4caf50;
        }
      }

      .deleteButton {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: transparent;
        margin: 10px;
        border-radius: 5px;
        font-size: 1.2em;
        font-weight: bold;
        width: 200px;
        padding: 10px 20px;
        background-color: #a12820;
        border: solid #f44336 2px;
        cursor: pointer;
        transition: background-color 0.4s;
        &:hover {
          background-color: #f44336;
        }
      }
      .replaceButton {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: transparent;
        margin: 10px;
        border-radius: 5px;
        font-size: 1.2em;
        font-weight: bold;
        width: 220px;
        height: 220px;
        padding: 10px 20px;
        background-color: #0c3f68;
        cursor: pointer;
        transition: background-color 0.4s;
        ${mobile`
      height: auto;
    `}
        &:hover {
          background-color: #2196f3;
        }
      }
      .hiddenInput {
        display: none;
      }

      .error {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2em;
        font-weight: bold;
        background-color: #b93329;
        color: #fff;
        width: 100%;
        padding: 10px 20px;
        margin-bottom: 10px;
      }
    }
  }
`

// {
//   container: {
//     textAlign: 'center',
//     padding: '20px',
//     border: '1px solid #ddd',
//     borderRadius: '10px',
//     width: '300px',
//     margin: '0 auto',
//   },

//   buttonGroup: {
//     display: 'flex',
//     justifyContent: 'center',
//     gap: '10px',
//   },
//
// }
