import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getWorkingHours, updateWorkingHours } from '../../api/unitDataAPI'
import { WorkingTimesFormStyled } from './WorkingTimesStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../styles/elements/formsStyle'
import { SmallSubmitButton } from '../../styles/elements/buttons'

import SuccesAlert from '../alert/SuccesAlert'
import ErrorAlert from '../alert/ErrorAlert'
import BackToSetings from '../../views/buttons/backToSetings/BackToSetings'
import FadeLoading from '../loaders/fadeLoading/FadeLoading'
import TimeSelectField from '../timeSelectField/TimeSelectField'

// const validation = {
//   // open: (data) => data.open === '' || data.open === 'default',
//   open: (data) =>
//     data.open === '' || data.open > data.close || data.open === data.close,
//   // close: (data) => data.close === '' || data.close === 'default',
//   close: (data) =>
//     data.close === '' || data.close < data.open || data.close === data.open,
// }
// const validation = {
//   open: (data) =>
//     data.open === '' || (data.open !== 'default' && data.open >= data.close),
//   close: (data) =>
//     data.close === '' || (data.close !== 'default' && data.close <= data.open),
// }

// const validation = {
//   open: (data) =>
//     data.open !== 'default' &&
//     data.close !== 'default' &&
//     (data.open === '' || data.open >= data.close),
//   close: (data) =>
//     data.open !== 'default' &&
//     data.close !== 'default' &&
//     (data.close === '' || data.close <= data.open),
// }

const validation = {
  open: (data) =>
    !(
      (
        (data.open === 'default' && data.close !== 'default') || // Invalid if open is 'default' but close is not
        (data.close === 'default' && data.open !== 'default') || // Invalid if close is 'default' but open is not
        (data.open >= data.close && data.open !== 'default')
      ) // Invalid if open >= close (except when both are 'default')
    ),
  close: (data) =>
    !(
      (
        (data.open === 'default' && data.close !== 'default') || // Invalid if open is 'default' but close is not
        (data.close === 'default' && data.open !== 'default') || // Invalid if close is 'default' but open is not
        (data.close <= data.open && data.close !== 'default')
      ) // Invalid if close <= open (except when both are 'default')
    ),
}

function WorkingTimesForm() {
  const [formData, setFormData] = useState([])
  const [workingHours, setWorkingHours] = useState([])
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getWorkingHours((response) => {
      setWorkingHours(response)
      setFormData(
        response.map((day) => ({
          id: day.id,
          open: day.open || '',
          close: day.close || '',
        }))
      )
      setErrors(response.map(() => []))
    })
  }, [])

  // const handleChange = (index, name, value) => {
  //   const updatedFormData = [...formData]
  //   updatedFormData[index][name] = value
  //   setFormData(updatedFormData)

  //   // Update validation errors
  //   const updatedErrors = [...errors]
  //   if (!validation[name](updatedFormData[index])) {
  //     updatedErrors[index] = updatedErrors[index].filter((el) => el !== name)
  //   } else {
  //     if (!updatedErrors[index].includes(name)) {
  //       updatedErrors[index].push(name)
  //     }
  //   }
  //   setErrors(updatedErrors)
  // }

  // const handleChange = (index, name, value) => {
  //   const updatedFormData = [...formData]

  //   // If one field is set to 'default', set both to 'default'
  //   if (value === 'default') {
  //     updatedFormData[index] = {
  //       ...updatedFormData[index],
  //       open: 'default',
  //       close: 'default',
  //     }
  //   } else {
  //     // Otherwise, update only the specified field
  //     updatedFormData[index][name] = value
  //   }

  //   setFormData(updatedFormData)

  //   const updatedErrors = [...errors]
  //   const currentData = updatedFormData[index]

  //   // Skip validation if one field is 'default'
  //   if (currentData.open === 'default' || currentData.close === 'default') {
  //     updatedErrors[index] = []
  //   } else {
  //     // Validate `open`
  //     if (!validation.open(currentData)) {
  //       updatedErrors[index] =
  //         updatedErrors[index]?.filter((el) => el !== 'open') || []
  //     } else {
  //       if (!updatedErrors[index]?.includes('open')) {
  //         updatedErrors[index] = [...(updatedErrors[index] || []), 'open']
  //       }
  //     }

  //     // Validate `close`
  //     if (!validation.close(currentData)) {
  //       updatedErrors[index] =
  //         updatedErrors[index]?.filter((el) => el !== 'close') || []
  //     } else {
  //       if (!updatedErrors[index]?.includes('close')) {
  //         updatedErrors[index] = [...(updatedErrors[index] || []), 'close']
  //       }
  //     }
  //   }

  //   setErrors(updatedErrors)
  // }

  const handleChange = (index, name, value) => {
    const updatedFormData = [...formData]

    // If one field is set to 'default', ensure both are set to 'default'
    if (value === 'default') {
      updatedFormData[index] = { open: 'default', close: 'default' }
    } else {
      // Update only the specific field
      updatedFormData[index][name] = value
    }

    setFormData(updatedFormData)

    // Validate the current state
    const updatedErrors = [...errors]
    const currentData = updatedFormData[index]
    const newErrors = []

    if (!validation.open(currentData)) newErrors.push('open')
    if (!validation.close(currentData)) newErrors.push('close')

    updatedErrors[index] = newErrors
    setErrors(updatedErrors)
  }

  const handleSubmit = (event, index) => {
    event.preventDefault()
    setIsLoading(true)

    const currentData = formData[index]

    // Run validation for the current data
    const validationErrors = Object.keys(validation).filter(
      (key) => !validation[key](currentData) // Collect fields that fail validation
    )

    if (validationErrors.length > 0) {
      // Update errors for the current index
      const updatedErrors = [...errors]
      updatedErrors[index] = validationErrors // Add only the current field errors
      setErrors(updatedErrors)
      setIsLoading(false) // Stop loading as validation failed
      return // Prevent submission
    }

    // Proceed with submission if there are no validation errors
    updateWorkingHours(
      workingHours[index].id,
      currentData,
      (response) => handleSuccess(response, index),
      handleError
    )
  }

  const handleError = (error) => {
    console.error(error)
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()
    setResponseErrors(parsedErrors)
    setIsLoading(false)
  }

  const handleSuccess = (response, index) => {
    setAlertMessage('Datele au fost modificate cu succes!')
    setShowAlert(true)
    setIsLoading(false)
    resetShowAlert()

    const updatedWorkingHours = [...workingHours]
    updatedWorkingHours[index] = response
    setWorkingHours(updatedWorkingHours)
  }

  const resetShowAlert = () => {
    const timer = setTimeout(() => {
      setShowAlert(false)
    }, 2100)
    return () => clearTimeout(timer)
  }

  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}
      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      {workingHours.length > 0 ? (
        <WorkingTimesFormStyled>
          <div className='title'>
            <h2>Program de lucru</h2>
          </div>
          <div className='readMeList'>
            <div className='infoTitle'>
              <h4>Cum lucram cu formularul.</h4>
            </div>
            <ul>
              <li>
                <span>NU</span> confundati acest formular cu programul de
                livrari.
              </li>
              <li>
                <span>PROGRAMUL DE LIVRARI</span> se seteaza din meniul{' '}
                <span>Categorii produse</span>
              </li>
              <li>
                Selecteaza ora de <span>DESCHIDERE</span> si de{' '}
                <span>INHIDERE</span> a unitatii si apasa butonul{' '}
                <span>Salveaza</span>.
              </li>
              <li>Procesul se repeta pentru fiecare zi din saptamana.</li>
              <li>
                Pentru zilele in care unitatea este inchisa{' '}
                <span>selectati Inchis</span> in ambele casute sau lasati
                campurile necompletate.
              </li>
            </ul>
          </div>
          <GeneralFormStyled>
            {workingHours.map((el, index) => (
              <React.Fragment key={index}>
                <div className='formTitle'>{el.day}</div>
                <form
                  onSubmit={(e) => handleSubmit(e, index)}
                  className='weekDayForm'
                >
                  <div className='multipleFields'>
                    <div>
                      <FieldLabel>
                        Se deschide
                        {errors[index]?.includes('open') ? (
                          <ErrorLabel>Selectează o altă oră!</ErrorLabel>
                        ) : (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        )}
                      </FieldLabel>
                      <TimeSelectField
                        selectName='open'
                        selectedValue={formData[index]?.open}
                        defaultText='Închis'
                        onChange={(e) =>
                          handleChange(index, 'open', e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FieldLabel>
                        Se închide
                        {errors[index]?.includes('close') ? (
                          <ErrorLabel>Selectează o altă oră!</ErrorLabel>
                        ) : (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        )}
                      </FieldLabel>
                      <TimeSelectField
                        selectName='close'
                        selectedValue={formData[index]?.close}
                        defaultText='Închis'
                        onChange={(e) =>
                          handleChange(index, 'close', e.target.value)
                        }
                      />
                    </div>

                    <div className='smallSubmitContainer'>
                      <SmallSubmitButton disabled={isLoading}>
                        {/* {isLoading ? 'Se salveaza...' : 'Salveaza'} */}
                        Salveaza
                      </SmallSubmitButton>
                      {isLoading ? <FadeLoading /> : ''}
                    </div>
                  </div>
                </form>
              </React.Fragment>
            ))}
          </GeneralFormStyled>
        </WorkingTimesFormStyled>
      ) : (
        // <FadeLoading />
        <p>Incarc date ...</p>
      )}
    </>
  )
}

export default WorkingTimesForm
