import React, { useEffect, useState } from 'react'
import { getWorkingHours } from '../../../api/unitDataAPI'
import { PageWorkingTimesStyled } from './PageWorkingTimesStyled'
import { getCurrentTime } from '../../../functions/formatDate'

function PageWorkingTimes() {
  const [wTimes, setWTimes] = useState([])

  useEffect(() => {
    getWorkingHours((response) => {
      setWTimes(response)
    })
  }, [])

  return (
    <>
      {wTimes.length > 0 ? (
        <PageWorkingTimesStyled>
          <h2>Orar</h2>
          <ul>
            <div className='container'>
              <div className='row'>
                {wTimes.map((el, index) => {
                  return (
                    <div className='col-4 fac-li' key={index}>
                      <li>
                        {el.dayShort === 'S' || el.day_short === 'D' ? (
                          <span className='weekDays holydays'>
                            {el.day_short}
                          </span>
                        ) : (
                          <span className='weekDays '>{el.day_short}</span>
                        )}
                        {el.open && el.close
                          ? `${el.open} - ${el.close}`
                          : 'Inchis'}
                      </li>
                    </div>
                  )
                })}
              </div>
            </div>
          </ul>
        </PageWorkingTimesStyled>
      ) : (
        ''
      )}
    </>
  )
}
export default PageWorkingTimes
