import styled from 'styled-components'

export const TogleSwitchStyled = styled.label`
  display: flex;
  flex-direction: column; /* Stacks label and switch vertically */
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  .label {
    font-size: 0.7rem;
    margin-bottom: 1px; /* Adds space between the label and the switch */
  }

  .switch-wrapper {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ba2525;
    border: solid #ff2727 2px;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 2px;
    background-color: #fff;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #28a745;
    border: solid #218838 2px;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #28a745;
  }

  input:checked + .slider:before {
    transform: translateX(28px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`
