import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { getUnitData } from '../../api/unitDataAPI'

import { updateUnitData } from '../../api/unitDataAPI'
import { UnitDataFormStyled } from './UnitDataFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../styles/elements/formsStyle'

import SuccesAlert from '../../components/alert/SuccesAlert'
import ErrorAlert from '../../components/alert/ErrorAlert'
import Judete from '../judete/Judete'
import Localitati from '../localitati/Localitati'
import ImageUploader from '../logoUploader/LogoUploader'
import BackToSetings from '../../views/buttons/backToSetings/BackToSetings'

function validateComplexEmail(email) {
  const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return complexEmailRegex.test(email)
}

const cleanFormData = {
  unit_type: '',
  unit_name1: '',
  unit_name2: '',
  slogan: '',
  // logo: '',
  judet: '',
  localitate: '',
  street: '',
  street_nr: '',
  phone1: '',
  phone2: '',
  email: '',
  postal_code: '',
}

const validation = {
  unit_type: (data) => data.unit_type.length < 4,
  unit_name1: (data) => data.unit_name1.length < 3,
  unit_name2: (data) => data.unit_name2.length < 0,
  slogan: (data) => data.slogan.length < 3,
  // logo: (data) => data.logo.length < 3,
  judet: (data) => data.judet === '' || data.judet === 'default', // Adjusted
  localitate: (data) => data.localitate === '' || data.localitate === 'default',
  street: (data) => data.street.length < 3,
  street_nr: (data) => data.street_nr.length < 1,
  phone1: (data) => data.phone1.length < 10 || isNaN(data.phone1),
  phone2: (data) => data.phone2.length < 10 || isNaN(data.phone2),
  email: (data) => data.email.length < 6 || !validateComplexEmail(data.email),
  postal_code: (data) => data.postal_code.length < 6 || isNaN(data.postal_code),
}

function UnitDateForm() {
  const [formData, setFormData] = useState(cleanFormData)
  const [unitData, setUnitData] = useState({})
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    getUnitData((response) => {
      setUnitData(response)
    })
  }, [])

  useEffect(() => {
    if (unitData && Object.keys(unitData).length > 0) {
      setFormData({
        unit_type: unitData.unit_type || '',
        unit_name1: unitData.unit_name1 || '',
        unit_name2: unitData.unit_name2 || '',
        slogan: unitData.slogan || '',
        logo: unitData.logo || '',
        judet: unitData.judet || '',
        localitate: unitData.localitate || '',
        street: unitData.street || '',
        street_nr: unitData.street_nr || '',
        phone1: unitData.phone1 || '',
        phone2: unitData.phone2 || '',
        email: unitData.email || '',
        postal_code: unitData.postal_code || '',
      })
    }
  }, [unitData])

  // useEffect(() => {
  //   if (unitData && Object.keys(unitData).length > 0) {
  //     const newFormData = {
  //       unit_type: unitData.unit_type || '',
  //       unit_name1: unitData.unit_name1 || '',
  //       unit_name2: unitData.unit_name2 || '',
  //       slogan: unitData.slogan || '',
  //       // logo: unitData.logo || '',
  //       judet: unitData.judet || '',
  //       localitate: unitData.localitate || '',
  //       street: unitData.street || '',
  //       street_nr: unitData.street_nr || '',
  //       phone1: unitData.phone1 || '',
  //       phone2: unitData.phone2 || '',
  //       email: unitData.email || '',
  //       postal_code: unitData.postal_code || '',
  //     }

  //     setFormData(newFormData)

  //     // Trigger validation for each field after form is populated
  //     const validationErrors = Object.keys(validation).filter((key) =>
  //       validation[key](newFormData)
  //     )

  //     setErrors(validationErrors)
  //   }
  // }, [unitData])

  // const handleChange = (e) => {
  //   const { name, value } = e.target

  //   if (!validation[name]({ ...formData, [name]: value })) {
  //     setErrors(errors.filter((el) => el !== name))
  //   } else {
  //     if (!errors.includes(name)) setErrors([...errors, name])
  //   }

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   })
  // }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'judet') {
      // Reset localitate when judet is changed
      const updatedFormData = {
        ...formData,
        judet: value,
        localitate: 'default', // Reset localitate
      }

      // Validate judet and localitate after reset
      if (!validation['localitate'](updatedFormData)) {
        setErrors(errors.filter((el) => el !== 'localitate'))
      } else {
        if (!errors.includes('localitate')) setErrors([...errors, 'localitate'])
      }

      if (!validation[name](updatedFormData)) {
        setErrors(errors.filter((el) => el !== name))
      } else {
        if (!errors.includes(name)) setErrors([...errors, name])
      }

      setFormData(updatedFormData)
    } else {
      // Handle other fields
      if (!validation[name]({ ...formData, [name]: value })) {
        setErrors(errors.filter((el) => el !== name))
      } else {
        if (!errors.includes(name)) setErrors([...errors, name])
      }

      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      updateUnitData(unitData.id, formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Unitatea a fost modificata cu succes!')
    setShowAlert(true)
    setFormData(cleanFormData)
    // navigate('/login')
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = '/setari'
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      <UnitDataFormStyled>
        <div className='title'>
          <h2>Modifica datele unitatii</h2>
        </div>
        <GeneralFormStyled>
          <form onSubmit={handleSubmit}>
            <div className='multipleFields'>
              <div>
                <FieldLabel>
                  Tip unitate
                  {errors.includes('unit_type') === true ? (
                    <ErrorLabel>Tip unitate prea scurt!</ErrorLabel>
                  ) : formData.unit_type.length >= 4 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 4 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='unit_type'
                  value={formData.unit_type}
                  onChange={handleChange}
                  placeholder='Tip unitate'
                />
              </div>
              <div>
                <FieldLabel>
                  Nume unitate
                  {errors.includes('unit_name1') === true ? (
                    <ErrorLabel>Nume unitate prea scurt!</ErrorLabel>
                  ) : formData.unit_name1.length >= 3 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 3 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='unit_name1'
                  value={formData.unit_name1}
                  onChange={handleChange}
                  placeholder='Nume unitate'
                />
              </div>
            </div>
            <div className='oneField'>
              <div>
                <FieldLabel>Nume suplimentar</FieldLabel>
                <input
                  type='text'
                  name='unit_name2'
                  value={formData.unit_name2}
                  onChange={handleChange}
                  placeholder='Nume suplimentar'
                />
              </div>
            </div>
            <div className='oneField'>
              <div>
                <FieldLabel>Slogan</FieldLabel>
                <input
                  type='text'
                  name='slogan'
                  value={formData.slogan}
                  onChange={handleChange}
                  placeholder='Slogan'
                />
              </div>
            </div>
            {/* <div className='imageUploadFields'>
              <div>
                <FieldLabel>Logo</FieldLabel>
                <input
                  type='file'
                  name='logo'
                  // value={formData.logo}
                  onChange={handleChange}
                  placeholder='logo'
                />
              </div>
              <div>
                {formData.logo && (
                  <img
                    src={formData.logo}
                    alt='Logo Preview'
                    style={{ maxWidth: '100px', marginTop: '10px' }}
                  />
                )}
              </div>
            </div> */}
            {/* <div className='imageUploadFields'>
              <div>
              <ImageUploader unitID={unitData.id} uploadFolder={'baseImg'} />
              </div>
              <div>
                {formData.logo && (
                  <img
                    src={formData.logo}
                    alt='Logo Preview'
                    style={{ maxWidth: '100px', marginTop: '10px' }}
                  />
                )}
              </div>
            </div> */}
            <div className='oneField'>
              <div>
                <FieldLabel>
                  Judet
                  {errors.includes('judet') ? (
                    <ErrorLabel>Selectează un judet!</ErrorLabel>
                  ) : formData.judet !== '' && formData.judet !== 'default' ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Alege judetul</InfoLabel>
                  )}
                </FieldLabel>
                <Judete
                  selectedJudet={formData.judet}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='oneField'>
              <div>
                <FieldLabel>
                  Localitate
                  {errors.includes('localitate') ? (
                    <ErrorLabel>Selecteaza localitatea!</ErrorLabel>
                  ) : formData.localitate !== '' &&
                    formData.localitate !== 'default' ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Alege localitatea</InfoLabel>
                  )}
                </FieldLabel>
                <Localitati
                  selectedLocalitate={formData.localitate}
                  onChange={handleChange}
                  selectedJudet={formData.judet}
                />
              </div>
            </div>
            <div className='oneField'>
              <div>
                <FieldLabel>
                  Cod postal
                  {!isNaN(formData.postal_code) &&
                  errors.includes('postal_code') === true ? (
                    <ErrorLabel>Cod postal prea scurt!</ErrorLabel>
                  ) : errors.includes('postal_code') ? (
                    <ErrorLabel>Cod postal incorect</ErrorLabel>
                  ) : formData.postal_code.length >= 6 &&
                    !isNaN(formData.postal_code) ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 6 cifre</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='postal_code'
                  value={formData.postal_code}
                  onChange={handleChange}
                  placeholder='Cod postal'
                  maxLength={6}
                />
              </div>
            </div>
            <div className='multipleFields'>
              <div>
                <FieldLabel>
                  Strada
                  {errors.includes('street') === true ? (
                    <ErrorLabel>Strada prea scurta!</ErrorLabel>
                  ) : formData.street.length >= 3 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 3 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='street'
                  value={formData.street}
                  onChange={handleChange}
                  placeholder='Strada'
                />
              </div>
              <div>
                <FieldLabel>
                  Nr. strada
                  {errors.includes('street_nr') === true ? (
                    <ErrorLabel>Nr. strada prea scurt!</ErrorLabel>
                  ) : formData.street_nr.length >= 1 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 1 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='street_nr'
                  value={formData.street_nr}
                  onChange={handleChange}
                  placeholder='Nr. strada'
                />
              </div>
            </div>
            <div className='multipleFields'>
              <div>
                <FieldLabel>
                  Telefon
                  {!isNaN(formData.phone1) &&
                  errors.includes('phone1') === true ? (
                    <ErrorLabel>Telefon prea scurt!</ErrorLabel>
                  ) : errors.includes('phone1') ? (
                    <ErrorLabel>Numar incorect</ErrorLabel>
                  ) : formData.phone1.length >= 10 &&
                    !isNaN(formData.phone1) ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 10 cifre</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='phone1'
                  value={formData.phone1}
                  onChange={handleChange}
                  placeholder='Telefon'
                />
              </div>
              <div>
                <FieldLabel>
                  Telefon suplimentar
                  {!isNaN(formData.phone2) &&
                  errors.includes('phone2') === true ? (
                    <ErrorLabel>Telefon suplimentar prea scurt!</ErrorLabel>
                  ) : errors.includes('phone2') ? (
                    <ErrorLabel>Numar incorect</ErrorLabel>
                  ) : formData.phone2.length >= 10 &&
                    !isNaN(formData.phone1) ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 10 cifre</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='phone2'
                  value={formData.phone2}
                  onChange={handleChange}
                  placeholder='Telefon suplimentar'
                />
              </div>
              <div>
                <FieldLabel>
                  Email
                  {errors.includes('email') === true ? (
                    <ErrorLabel>Email invalid!</ErrorLabel>
                  ) : formData.email.length >= 6 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 6 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  placeholder='Email'
                />
              </div>
            </div>
            <div className='submitContainer'>
              <button className='submitButton'>Modifica datele</button>
            </div>
          </form>
        </GeneralFormStyled>
      </UnitDataFormStyled>
    </>
  )
}

export default UnitDateForm
