import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { addCustomer } from '../../api/customers'
import { RegisterAdminStyled } from './RegisterAdminStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
  ExtraLinksContainer,
} from '../../styles/elements/formsStyle'

import SuccesAlert from '../../components/alert/SuccesAlert'
import ErrorAlert from '../../components/alert/ErrorAlert'
import BackToSetings from '../buttons/backToSetings/BackToSetings'

function validateComplexEmail(email) {
  const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return complexEmailRegex.test(email)
}

const cleanFormData = {
  username: '',
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  city: '',
  street: '',
  street_number: '',
  building: '',
  staircase: '',
  apartment: '',
  intercom: '',
  password: '',
  password_confirmation: '',
}

const validation = {
  username: (data) => data.username.length < 4,
  first_name: (data) => data.first_name.length < 3,
  last_name: (data) => data.last_name.length < 3,
  phone: (data) => data.phone.length < 10 || isNaN(data.phone),
  email: (data) => data.email.length < 6 || !validateComplexEmail(data.email),
  city: (data) => data.city.length < 3,
  street: (data) => data.street.length < 3,
  street_number: (data) => data.street_number.length < 0,
  building: (data) => data.building.length < 0,
  staircase: (data) => data.staircase.length < 0,
  apartment: (data) => data.apartment.length < 0,
  intercom: (data) => data.intercom.length < 0,
  password: (data) => data.password.length < 8,
  password_confirmation: (data) => data.password_confirmation !== data.password,
}

function RegisterAdmin() {
  const [formData, setFormData] = useState(cleanFormData)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target

    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      addCustomer(formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Contul a fost creat cu succes!')
    setShowAlert(true)
    setFormData(cleanFormData)
    // navigate('/login')
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = '/login'
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      <RegisterAdminStyled>
        <div className='title'>
          <h2>Creeaza cont de administrator</h2>
        </div>
        <GeneralFormStyled>
          <form onSubmit={handleSubmit}>
            <div className='multipleFields'>
              <div>
                <FieldLabel>
                  Utilizator
                  {errors.includes('username') === true ? (
                    <ErrorLabel>Utilizator prea scurt!</ErrorLabel>
                  ) : formData.username.length >= 4 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 4 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='username'
                  value={formData.username}
                  onChange={handleChange}
                  placeholder='Utilizator'
                />
              </div>
              <div>
                <FieldLabel>
                  Nume
                  {errors.includes('first_name') === true ? (
                    <ErrorLabel>Nume prea scurt!</ErrorLabel>
                  ) : formData.first_name.length >= 3 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 3 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='first_name'
                  value={formData.first_name}
                  onChange={handleChange}
                  placeholder='Nume'
                />
              </div>
              <div>
                <FieldLabel>
                  Prenume
                  {errors.includes('last_name') === true ? (
                    <ErrorLabel>Prenume prea scurt!</ErrorLabel>
                  ) : formData.last_name.length >= 3 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 3 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='last_name'
                  value={formData.last_name}
                  onChange={handleChange}
                  placeholder='Prenume'
                />
              </div>
            </div>
            <div className='multipleFields'>
              <div>
                <FieldLabel>
                  Telefon
                  {!isNaN(formData.phone) &&
                  errors.includes('phone') === true ? (
                    <ErrorLabel>Telefon prea scurt!</ErrorLabel>
                  ) : errors.includes('phone') ? (
                    <ErrorLabel>Numar incorect</ErrorLabel>
                  ) : formData.phone.length >= 10 && !isNaN(formData.phone) ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 10 cifre</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='phone'
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder='Telefon'
                />
              </div>
              <div>
                <FieldLabel>
                  Email
                  {errors.includes('email') === true ? (
                    <ErrorLabel>Email invalid!</ErrorLabel>
                  ) : formData.email.length >= 6 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 6 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  placeholder='Email'
                />
              </div>
            </div>
            <div className='oneField'>
              <FieldLabel>
                Localitate
                {errors.includes('city') === true ? (
                  <ErrorLabel>Localitatea e prea scurta!</ErrorLabel>
                ) : formData.city.length >= 3 ? (
                  <SuccesLabel>&#10004;</SuccesLabel>
                ) : (
                  <InfoLabel>Minim 3 caractere</InfoLabel>
                )}
              </FieldLabel>
              <input
                type='text'
                name='city'
                value={formData.city}
                onChange={handleChange}
                placeholder='Localitate'
              />
            </div>
            <div className='multipleFields'>
              <div>
                <FieldLabel>
                  Strada
                  {errors.includes('street') === true ? (
                    <ErrorLabel>Strada e prea scurta!</ErrorLabel>
                  ) : formData.street.length >= 3 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 3 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='street'
                  value={formData.street}
                  onChange={handleChange}
                  placeholder='Strada'
                />
              </div>
              <div>
                <FieldLabel>Nr strada</FieldLabel>
                <input
                  type='text'
                  name='street_number'
                  value={formData.street_number}
                  onChange={handleChange}
                  placeholder='Nr strada'
                />
              </div>
            </div>
            <div className='multipleFields'>
              <div>
                <FieldLabel>Bloc</FieldLabel>
                <input
                  type='text'
                  name='building'
                  value={formData.building}
                  onChange={handleChange}
                  placeholder='Bloc'
                />
              </div>
              <div>
                <FieldLabel>Scara</FieldLabel>
                <input
                  type='text'
                  name='staircase'
                  value={formData.staircase}
                  onChange={handleChange}
                  placeholder='Scara'
                />
              </div>
              <div>
                <FieldLabel>Apartament</FieldLabel>
                <input
                  type='text'
                  name='apartment'
                  value={formData.apartment}
                  onChange={handleChange}
                  placeholder='Apartament'
                />
              </div>
            </div>
            <div className='oneField'>
              <FieldLabel>Nr. interfon</FieldLabel>
              <input
                type='text'
                name='intercom'
                value={formData.intercom}
                onChange={handleChange}
                placeholder='Nr. interfon'
              />
            </div>
            <div className='oneField'>
              <FieldLabel>
                Parola
                {errors.includes('password') === true ? (
                  <ErrorLabel>Parola e prea scurta!</ErrorLabel>
                ) : formData.password.length >= 8 ? (
                  <SuccesLabel>&#10004;</SuccesLabel>
                ) : (
                  <InfoLabel>Minim 8 caractere</InfoLabel>
                )}
              </FieldLabel>
              <input
                type='password'
                name='password'
                value={formData.password}
                onChange={handleChange}
                placeholder='Parola'
              />
            </div>
            <div className='oneField'>
              <FieldLabel>
                Repeta parola
                {errors.includes('password_confirmation') === true ? (
                  <ErrorLabel>Parolele nu se potrivesc!</ErrorLabel>
                ) : formData.password_confirmation.length >= 8 ? (
                  <SuccesLabel>&#10004;</SuccesLabel>
                ) : (
                  <InfoLabel>Minim 8 caractere</InfoLabel>
                )}
              </FieldLabel>
              <input
                type='password'
                // name='password_confirmation'
                name='password_confirmation'
                value={formData.password_confirmation}
                onChange={handleChange}
                placeholder='Repeta parola'
              />
            </div>
            <div className='submitContainer'>
              <button className='submitButton'>Creeaza contul</button>
            </div>
          </form>
        </GeneralFormStyled>
        <ExtraLinksContainer>
          <Link to='/login'>Conecteaza-te</Link>
          <Link to='/forgot-password'>Am uitat parola</Link>
        </ExtraLinksContainer>
      </RegisterAdminStyled>
    </>
  )
}

export default RegisterAdmin
