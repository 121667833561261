import React, { useState, useEffect, useRef } from 'react'

import { CarouselAutoSliderFullScreenStyled } from './CarouselAutoSliderFullScreenStyled'

function CarouselAutoSliderFullScreen({ children, elIndex }) {
  const [activeIndex, setActiveIndex] = useState(elIndex)
  const [timeID, setTimeID] = useState(null)
  const touchStartX = useRef(0)
  const touchEndX = useRef(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      slideNext()
    }, 5000)

    setTimeID(timer)
    return () => clearTimeout(timer) // Cleanup timeout
  }, [activeIndex])

  const slideNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % children.length)
  }

  const slidePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? children.length - 1 : prevIndex - 1
    )
  }

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX
  }

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX
  }

  const handleTouchEnd = () => {
    const deltaX = touchStartX.current - touchEndX.current
    if (Math.abs(deltaX) > 50) {
      // Swipe threshold
      if (deltaX > 0) {
        // Swipe left
        slideNext()
      } else {
        // Swipe right
        slidePrev()
      }
    }
  }

  return (
    <CarouselAutoSliderFullScreenStyled
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {children.map((item, index) => (
        <div
          key={index}
          className='slider'
          style={{
            transform: `translateX(${index - activeIndex * 101}%)`,
          }}
        >
          {item}
        </div>
      ))}

      <div className='container__slider__links'>
        {children.map((_, index) => (
          <button
            key={index}
            className={
              activeIndex === index
                ? 'container__slider__links-small container__slider__links-small-active'
                : 'container__slider__links-small'
            }
            onClick={() => setActiveIndex(index)}
          ></button>
        ))}
      </div>

      <button className='slider__btn-next' onClick={slideNext}>
        {'>'}
      </button>
      <button className='slider__btn-prev' onClick={slidePrev}>
        {'<'}
      </button>
    </CarouselAutoSliderFullScreenStyled>
  )
}

export default CarouselAutoSliderFullScreen

/*
parrent component
const [currentIndex, setCurrentIndex] = useState(0)

onClick={() => {
  setPhoto(el.image_link)
  setCurrentIndex(index)
}}

<CarouselAutoSliderFullScreen elIndex={currentIndex}>
                  {product.product_images.map((image, index) => {
                    return (
                      <img
                        key={index}
                        src={image.image_link}
                        alt={product.name}
                        onClick={() => openModal(currentIndex)}
                      />
                    )
                  })}
                </CarouselAutoSliderFullScreen> 
*/
