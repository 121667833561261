import React from 'react';
import { Link } from 'react-router-dom';
import { BackToSetingsStyled } from './BackToSetingsStyled';

function BackToSetings() {
  return (
    <>
      <BackToSetingsStyled>
        <Link to={'/setari'}>
          <span>&#11164;</span> Inapoi la setari
        </Link>
      </BackToSetingsStyled>
    </>
  );
}

export default BackToSetings;
