import styled from 'styled-components'

export const CarouselAutoSliderFullScreenStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: black;
  z-index: -1;

  button {
    /* background-color: transparent; */
    background-color: #000000bc;
    outline: none;
    border: 0;
    color: whitesmoke;
    font-size: 2rem;
    height: 100px;
    width: 3rem;
    cursor: pointer;
    &:hover {
      /* background-color: whitesmoke; */
      background-color: #000000;
      /* color: black; */
    }
    &:active {
      color: #fff;
    }
  }

  .slider__btn-next {
    position: absolute;
    right: 0;
  }

  .slider__btn-prev {
    position: absolute;
    left: 0;
  }

  .container__slider__links {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }

  .container__slider__links-small {
    position: relative;
    width: 15px;
    height: 15px;
    padding: 0;
    border-radius: 50%;
    outline: none;
    border: 0;
    background-color: white;
    border: 1px solid grey;
    transition: background-color 1s ease;
    margin: 0 2px;
  }

  .container__slider__links-small-active {
    background-color: black;
  }

  .slider {
    box-sizing: border-box;
    min-width: 100%;
    height: auto;
    overflow: hidden;
    /* object-fit: cover; */

    /* transform: translateX(100%); */
    /* transform: translateX(calc(var(--active-index, 0) * -100%)); */
    transition: transform 1s ease;
    /* flex: 300px 300px auto; */
    img {
      width: 100%;
      height: auto;
    }
  }
`
