import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { getFacilityById, addOrUpdateFacility } from '../../../api/unitDataAPI'
import { FacilityAddFormStyled } from './FacilityAddFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButton,
  GreenSubmitButton,
} from '../../../styles/elements/buttons'

import SuccesAlert from '../../alert/SuccesAlert'
import ErrorAlert from '../../alert/ErrorAlert'

import BackToSetings from '../../../views/buttons/backToSetings/BackToSetings'
import SingleImageUploader from '../../singleImageUploader/SingleImageUploader'
import FadeLoading from '../../loaders/fadeLoading/FadeLoading'
import IconUploader from '../../iconUploader/IconUploader'
import { PopUpLevel1Styled } from '../../popUpWindows/PopUpLevel1Styled'

const cleanFormData = {
  name: '',
}

const validation = {
  name: (data) => data.name.length < 3,
}

function FacilityAddForm({ elID, cancel }) {
  const [formData, setFormData] = useState(cleanFormData)
  const [elementId, setElementId] = useState(elID)
  const [facility, setFacility] = useState({})
  const [showChangeImage, setShowChangeImage] = useState(null)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const ActivateButtonComponent =
    facility.active === 1 ? RedSubmitButton : GreenSubmitButton

  // Fetch the special offer by ID whenever `elID` changes
  useEffect(() => {
    if (elementId) {
      getFacilityById(elementId, (response) => {
        setFacility(response)
      })
    }
  }, [elementId, showChangeImage]) // Trigger this effect when `elID` changes

  useEffect(() => {
    if (facility && Object.keys(facility).length > 0) {
      setFormData({
        name: facility.name || '',
      })
    }
    document.body.style.overflow = 'hidden'
  }, [facility])

  const handleChange = (e) => {
    const { name, value } = e.target

    // Handle other fields
    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    // Set loading state to true
    setIsLoading(true)

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      addOrUpdateFacility(facility.id, formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  console.log('arata imagine ' + showChangeImage)
  console.log('elId ' + elementId)

  function handleSuccess(response) {
    // console.log(response.id)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Datele au fost salvate cu succes!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/setari'
      // setShowChangeImage(showChangeImage === facility.id ? null : facility.id)
      setShowChangeImage(response.id)
      setElementId(response.id)
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      {/* <BackToSetings /> */}
      {facility ? (
        <>
          <PopUpLevel1Styled>
            <div className='popUpLevel1Container'>
              <FacilityAddFormStyled>
                <div className='specOffFormContainer'>
                  <div className='title'>
                    <h2>
                      {!elementId
                        ? 'Adauga o facilitate'
                        : 'Editeaza facilitatea'}
                    </h2>
                  </div>
                  <div className='cancelButtonContainer'>
                    <button
                      className='closeButton'
                      onClick={() => {
                        // setIsOpen(false)
                        // window.location.reload()
                        setTimeout(cancel, 300) // Delay cancel to allow fade-out
                        document.body.style.overflow = '' // Re-enable body scroll
                      }}
                    >
                      Renunta
                    </button>
                  </div>
                  {/* {facility.id && ( */}
                  {elementId && (
                    <div className='changeImageButtonFormContainer'>
                      <RedSubmitButton
                        onClick={() => {
                          setShowChangeImage(
                            showChangeImage === facility.id ? null : facility.id
                          )
                        }}
                      >
                        {facility.icon ? 'Schimba iconita' : 'Adauga iconita'}
                      </RedSubmitButton>
                      {showChangeImage === facility.id && (
                        <IconUploader
                          apiName={'updateFacilityIcon'}
                          id={facility.id}
                          formTitle={
                            facility.icon ? 'Schimba iconita' : 'Adauga iconita'
                          }
                          oldImage={facility.icon}
                          uploadFolder={'icons'}
                          fileType={'png'}
                          cancel={() => setShowChangeImage(null)}
                          backUrl={'facilitati'}
                        />
                      )}
                      {facility.icon ? (
                        ''
                      ) : (
                        <span>Nu este obilgatoriu sa incarcati o iconita.</span>
                      )}

                      <div className='icon'>
                        <img src={facility.icon} alt='' />
                      </div>
                    </div>
                  )}
                  <GeneralFormStyled>
                    <form onSubmit={handleSubmit}>
                      <div className='oneField'>
                        <div>
                          <FieldLabel>
                            Nume
                            {errors.includes('name') === true ? (
                              <ErrorLabel>Numele e prea scurt!</ErrorLabel>
                            ) : formData.name.length >= 3 ? (
                              <SuccesLabel>&#10004;</SuccesLabel>
                            ) : (
                              <InfoLabel>Minim 3 caractere</InfoLabel>
                            )}
                          </FieldLabel>
                          <input
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            placeholder='Nume'
                          />
                        </div>
                      </div>
                      <div className='submitContainer'>
                        <SubmitButton disabled={isLoading}>
                          {isLoading
                            ? 'Asteapta! Se incarca...'
                            : !facility.title
                            ? 'Salveaza'
                            : 'Modifica datele'}
                        </SubmitButton>
                      </div>
                    </form>
                  </GeneralFormStyled>
                </div>
              </FacilityAddFormStyled>
            </div>
          </PopUpLevel1Styled>
        </>
      ) : (
        <p>Incarc datele</p>
        // <FadeLoading />
      )}
    </>
  )
}

export default FacilityAddForm
