import React, { useEffect, useState } from 'react'
import { PageDeliveryStyled } from './PageDeliveryStyled'
import { getDeliveryConditions } from '../../../api/unitDataAPI'
import DeliveryPrices from './PageDeliveryPrices'

function PageDelivery() {
  const [deliveryCond, setDeliveryCond] = useState([])

  useEffect(() => {
    getDeliveryConditions((response) => {
      setDeliveryCond(response)
    })
  }, [])

  return (
    <>
      {deliveryCond.length > 0 ? (
        <PageDeliveryStyled id='delivery'>
          {deliveryCond.map((el, index) => {
            return (
              <article key={index}>
                <div className='col-12 title'>
                  <h2>{el.title}</h2>
                </div>
                <div className='col-12 artText'>
                  {el.description > 0 ? el.description : ''}
                  {el.description}
                  <DeliveryPrices />
                </div>
              </article>
            )
          })}
        </PageDeliveryStyled>
      ) : (
        ''
      )}
    </>
  )
}
export default PageDelivery
