import React, { useState, useEffect } from 'react'
import { getUnitDataHeader } from '../../api/unitDataAPI'
import { ChangeLogoFormStyled } from './ChangeLogoFormStyled'

import LogoUploader from '../logoUploader/LogoUploader'
import BackToSetings from '../../views/buttons/backToSetings/BackToSetings'

function ChangeLogoForm() {
  const [unitData, setUnitData] = useState({})

  useEffect(() => {
    getUnitDataHeader((response) => {
      setUnitData(response)
    })
  }, [])

  return (
    <>
      <BackToSetings />
      <ChangeLogoFormStyled>
        <div className='title'>
          <h2>Schimba logo-ul unitatii</h2>
        </div>
        <div className='info'>
          <span>
            Logo-ul trebuie sa fie un fisier png cu fundal transparent si cu o
            rezolutie maxima de 500px X 500px!
          </span>
        </div>
        <div className='oldLogo'>
          {unitData.logo && <img src={unitData.logo} alt='Logo Preview' />}
          <p>Logo-ul actual</p>
        </div>
        {/* <div className='imageUploadFields'>
          <div> */}
        <LogoUploader
          unitID={unitData.id}
          uploadFolder={'baseImg'}
          fileType={'png'}
        />
        {/* </div>
        </div> */}
      </ChangeLogoFormStyled>
    </>
  )
}

export default ChangeLogoForm
