import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { forgotAdminPassword } from '../../api/adminPasswordReset'
import { ForgotPasswordStyled } from './ForgotPasswordStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
  ExtraLinksContainer,
} from '../../styles/elements/formsStyle'
import SuccesAlert from '../../components/alert/SuccesAlert'
import ErrorAlert from '../../components/alert/ErrorAlert'

function validateComplexEmail(email) {
  const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return complexEmailRegex.test(email)
}

const cleanFormData = {
  email: '',
}

const validation = {
  email: (data) => data.email.length < 6 || !validateComplexEmail(data.email),
}

function ForgotPassword() {
  const [formData, setFormData] = useState(cleanFormData)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target

    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  // const handleSubmit = async (event) => {
  const handleSubmit = (event) => {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      forgotAdminPassword(formData.email, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    // console.log(response)
    setAlertMessage(
      'Link-ul pentru resetarea parolei a fost trimis pe email-ul tau!'
    )
    setShowAlert(true)
    // alert('Link-ul pentru resetarea parolei a fost trimis pe email-ul tau!')
    setFormData(cleanFormData)
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = '/'
    }, 3000)
    return () => clearTimeout(timer)
    // navigate('/login')
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <ForgotPasswordStyled>
        <div className='title'>
          <h2>Am uitat parola</h2>
        </div>
        <GeneralFormStyled>
          <form onSubmit={handleSubmit}>
            <FieldLabel>
              Email
              {errors.includes('email') === true ? (
                <ErrorLabel>Email invalid!</ErrorLabel>
              ) : formData.email.length >= 6 ? (
                <SuccesLabel>&#10004;</SuccesLabel>
              ) : (
                <InfoLabel>Minim 6 caractere</InfoLabel>
              )}
            </FieldLabel>
            <input
              type='text'
              name='email'
              placeholder='E-Mail'
              value={formData.email}
              onChange={handleChange}
            />
            <div className='submitContainer'>
              <button className='submitButton'>Trimite</button>
            </div>
          </form>
        </GeneralFormStyled>
        <ExtraLinksContainer>
          <Link to='/'>Conecteaza-te</Link>
          {/* <Link to='/register'>Creeaza cont</Link> */}
        </ExtraLinksContainer>
      </ForgotPasswordStyled>
    </>
  )
}

export default ForgotPassword
