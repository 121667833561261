import React, { useState } from 'react';
import { logoUpload } from '../../api/imageHandler';
import { LogoUploaderStyled } from './LogoUploaderStyled';
import { ErrorDysplayStyled } from '../alert/AlertStyled';
import SuccesAlert from '../alert/SuccesAlert';
import ErrorAlert from '../alert/ErrorAlert';

const LogoUploader = ({ unitID, uploadFolder, fileType }) => {
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [error, setError] = useState(null);
  const [responseErrors, setResponseErrors] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // console.log(uploadFolder);
  // Handle image change and preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      if (fileType === 'png' && file.type !== 'image/png') {
        setError('Accept doar fisiere png.');
        return;
      } else if (
        fileType !== 'png' &&
        !['image/png', 'image/jpeg'].includes(file.type)
      ) {
        setError('Accept doar fisiere png sau jpg.');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          // Validate resolution based on file type
          if (fileType === 'png' && (img.width > 500 || img.height > 500)) {
            setError('Imaginea este mai mare de 500x500 pixeli.');
            return;
          } else if (
            fileType !== 'png' &&
            (img.width < 800 || img.height < 800)
          ) {
            setError('Imaginea este mai mica de 800x800 pixeli.');
            return;
          }

          setImage(file); // Store the file object
          setImagePreviewUrl(reader.result); // Display preview
          setError(null); // Clear error if everything is valid
        };
      };

      reader.readAsDataURL(file); // Convert file to base64 string for preview
    }
  };

  // Delete image preview and reset
  const handleDeleteImage = () => {
    setImage(null);
    setImagePreviewUrl(null);
    setError(null); // Clear error when resetting
  };

  // Replace image by selecting a new one
  const handleReplaceImage = (e) => {
    handleImageChange(e);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!image) {
      alert('Te rog selecteaza o imagine');
      return;
    }

    // Set loading state to true
    setIsLoading(true);

    // Extract the file name with extension
    const imageName = image.name;

    // Create a FormData object to append the file and the image name
    const formData = new FormData();
    formData.append('logo', image); // Ensure the file is sent under 'logo'
    formData.append('imageName', imageName); // Optionally include the image name

    // Use the `logoUpload` function from the API file
    logoUpload(
      unitID, // Unit ID for the API request
      uploadFolder, // Upload folder specified in the request
      formData, // Pass form data including the image and its name
      // (data) => {
      //   alert('Imaginea a fost incarcata cu succes!');
      //   console.log('Upload response:', data);
      // },
      // (error) => {
      //   console.error('EROARE:', error);
      //   alert('Am o eroare la incarcare.');
      // }
      handleSuccess,
      handleError
    );
  };

  function handleError(error) {
    console.error(error);

    // Parse the error response
    const errorResponse = error.response.data;
    const parsedErrors = Object.values(errorResponse).flat();

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors);

    // Reset loading state
    setIsLoading(false);
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Logo-ul a fos incarcat cu succes!');
    setShowAlert(true);
    setImage(null);
    // navigate('/login')
    // Reset loading state
    setIsLoading(false);
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = '/setari';
    }, 2100);
    return () => clearTimeout(timer);
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index));
  };

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      {error && (
        <ErrorDysplayStyled>
          <span className='errorMsg'>{error}</span>
        </ErrorDysplayStyled>
      )}
      <LogoUploaderStyled>
        {imagePreviewUrl ? (
          <>
            <div className='previewContainer'>
              <span className='previewText'>Previzualizare</span>
              <img
                src={imagePreviewUrl}
                alt='Uploaded Preview'
                className='imagePreview'
              />

              <button className='deleteButton' onClick={handleDeleteImage}>
                Sterge
              </button>
            </div>
            <div>
              <label className='replaceButton'>
                Schimba
                <input
                  className='hiddenInput'
                  type='file'
                  accept='image/*'
                  onChange={handleReplaceImage}
                />
              </label>
            </div>
          </>
        ) : (
          <div>
            <label className='selectButton'>
              Alege logo
              <input
                className='hiddenInput'
                type='file'
                accept='image/*'
                onChange={handleImageChange}
              />
            </label>
          </div>
        )}
        {imagePreviewUrl && (
          <div>
            <button
              className='uploadButton'
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? 'Asteapta! Se incarca...' : 'Incarca logo!'}
            </button>
          </div>
        )}
      </LogoUploaderStyled>
    </>
  );
};

export default LogoUploader;
