import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens'

export const HorizontalScrollingImagesStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 0;

  .scrolling-menu {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    gap: 15px;
    padding: 10px 0; /* Ensures spacing around items */
    margin: 0 5px;

    &::-webkit-scrollbar {
      /* display: none; */
      /* background-color: #ccc; */
      background-color: #121212;
      height: 8px;
      width: 8px;
    }
    &::-moz-scrollbar {
      background-color: #121212;
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #121212;
    }
    &::-moz-scrollbar-track {
      background-color: #121212;
    }
    &::-webkit-scrollbar-thumb {
      /* background: #1d1d1d; */
      background: #474747;
      width: 30px;
      border-radius: 10px; /* Optional: Make the thumb rounded */
      height: 30px; /* Max height for horizontal scrollbar thumb */
      width: 30px; /* Max width for vertical scrollbar thumb */
    }
    &::-moz-scrollbar-thumb {
      background: #474747;
      border-radius: 10px; /* Optional: Make the thumb rounded */
      height: 30px; /* Max height for horizontal scrollbar thumb */
      width: 30px; /* Max width for vertical scrollbar thumb */
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #474747;
    }
    &::-moz-scrollbar-thumb:hover {
      background: #474747;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-moz-scrollbar {
    }
  }

  .menu-item {
    position: relative;
    flex: 0 0 auto;
    width: 100px;
    height: 60px;
    background-color: #1d1212;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: #0a0606;
      /* transform: scale(1.05); Slight zoom effect */
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      position: absolute;
    }
  }

  .itemName {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;

    h2 {
      font-size: 0.9rem;
      font-weight: bold;
      color: #fff;
      letter-spacing: 2px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }
  }

  .scroll-button {
    position: absolute;
    background-color: #666;
    color: #fff;
    border: none;
    width: 30px;
    height: 50px;
    top: 50%;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    transform: translateY(-50%);
    display: none;
  }

  .scroll-button.left {
    left: 10px;
  }

  .scroll-button.right {
    right: 0;
  }

  .scroll-button:hover {
    background-color: #444;
  }
  /* .scroll-button {
    position: absolute;
    background-color: #444;
    color: #fff;
    border: none;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.2rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: #666;
      /* transform: scale(1.1); */
  /* }
  } */

  .scroll-button.left {
    left: 10px;
  }

  .scroll-button.right {
    right: 10px;
  }
`

// import styled from 'styled-components'
// import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens'

// export const HorizontalScrollingMenuStyled = styled.div`
//   /* .menu-container { */
//   display: flex;
//   align-items: center;
//   position: relative;
//   width: 100%;
//   overflow: hidden;
//   /* } */

//   .scrolling-menu {
//     display: flex;
//     overflow-x: auto;
//     scroll-behavior: smooth;
//     gap: 10px;
//     padding: 10px 0;
//     margin: 0 70px;
//     /* overflow: hidden; */
//   }

//   .menu-item {
//     position: relative;
//     flex: 0 0 auto;
//     min-width: 100px;
//     height: 35px;
//     /* background: #1d1212; */
//     background-color: #1d1212;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border: 1px solid #ccc;
//     border-radius: 8px;
//     /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
//     overflow: hidden;
//     &:hover {
//       background-color: #3c2525;
//     }

//     img {
//       width: 100%;
//       /* height: 200px; */
//       object-fit: cover;

//       opacity: 0.5;
//       /* border-radius: 8px; */

//       position: absolute;
//       /* Allows stacking with text */
//     }
//   }

//   .itemName {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     text-align: center;
//     z-index: 1; /* Ensures text is above the image */

//     h2 {
//       font-size: 0.8em;
//       font-weight: bold;
//       color: #fff;
//       letter-spacing: 2px;
//       /* text-shadow: #000 5px 0 10px; */
//       text-shadow: 1px 1px 0 #0b0b0b, -1px 1px 0 #0b0b0b, -1px -1px 0 #0b0b0b,
//         1px -1px 0 #0b0b0b;
//     }
//   }

//   .scroll-button {
//     position: absolute;
//     background-color: #666;
//     color: #fff;
//     border: none;
//     width: 30px;
//     height: 100%;
//     top: 0;
//     cursor: pointer;
//     z-index: 1;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 1.5rem;
//   }

//   .scroll-button.left {
//     left: 10px;
//   }

//   .scroll-button.right {
//     right: 0;
//   }

//   .scroll-button:hover {
//     background-color: #666;
//   }
// `
