// Pagination.js
import React from 'react'
import { PaginationContainer } from './PaginationStyled'
import { useNavigate } from 'react-router-dom'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const navigate = useNavigate()
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page)
    }
  }

  return (
    <>
      <PaginationContainer>
        <button
          className='PageButton'
          disabled={currentPage === 1}
          onClick={() => handlePageChange(1)}
        >
          &laquo; &laquo;
        </button>
        <button
          disabled={currentPage === 1}
          className='PageButton'
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &laquo;
        </button>
        {currentPage > 2 && <span className='dots'>...</span>}
        {Array.from({ length: Math.min(totalPages, 3) }, (_, i) => {
          const page = Math.max(1, currentPage - 1) + i
          if (page <= totalPages) {
            return (
              <button
                key={page}
                // active={page === currentPage}
                className={page === currentPage ? 'ActiveButton' : 'PageButton'}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            )
          }
          return null
        })}
        {currentPage < totalPages - 1 && <span className='dots'>...</span>}
        <button
          className='PageButton'
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          &raquo;
        </button>
        <button
          className='PageButton'
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          &raquo; &raquo;
        </button>
      </PaginationContainer>
      {/* <div>
        <div>ordoneaza dupa</div>
        <div>rezultate pe pagina</div>
      </div> */}
    </>
  )
}

export default Pagination

// how to use in parent
// const [currentPage, setCurrentPage] = useState(1)
// const [orderBy, setOrderBy] = useState(1)
// const [fetchAllItems, setFetchAllItems] = useState(false) // Track whether to fetch all orders or paginated orders

// add to useEfect currentPage

// useEffect(() => {
//   getAllPaginatedProducts(currentPage, orderBy, (response) => {
//     setProductList(response)
//     fetchItems()
//   })
// }, [currentPage, fetchAllItems, orderBy])

// const fetchItems = () => {
//   if (fetchAllItems) {
//     // Fetch all products (unpaginated)
//     getAllProducts(
//       orderBy,
//       (response) => setProductList(response),
//       (error) => console.error('Failed to retrieve all products:', error)
//     )
//   } else {
//     // Fetch paginated products
//     getAllPaginatedProducts(
//       currentPage,
//       orderBy,
//       (response) => setProductList(response),
//       (error) =>
//         console.error('Failed to retrieve paginated products:', error)
//     )
//   }
// }

// Function to handle pagination page changes
// const handlePageChange = (page) => {
//   setCurrentPage(page) // Update the current page state
// }

// display pagination
// {
//   itemList.last_page > 1 && (
//     <Pagination
//       currentPage={itemList.current_page}
//       totalPages={itemList.last_page}
//       onPageChange={handlePageChange}
//     />
//   )
// }
