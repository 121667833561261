import styled from 'styled-components'
import { COLORS, addUserColors, formColors } from '../../styles/setings/colors'
import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens'

export const FacilitysListStyled = styled.section`
  // margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 30px auto; */
  /* padding-bottom: 20px; */
  // padding: 20px 0 40px 0;
  background-color: ${COLORS.colorAboutSectionBg};
  /* background-color: grey; */
  ${desktop`
    margin-top: 20px;
  `}
  ${tablet`
    margin-top: 20px;
  `}
  ${mobile`
    margin-top: 10px;
  `}
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
  background-color: grey; */

  .title {
    width: 100%;
    display: flex;
    /* border: solid #fff 1px; */
    padding: 10px 0;
    background-color: #2c2c2c;
    border-radius: 15px 15px 0 0;

    h2 {
      display: inline-flex;
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};

      ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
      `}
      ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
      `}
    ${mobile`
        align-items: center;
        justify-content: center;    
        width: 100%;
      font-size: 1.3em;
    `}
    }
  }

  .readMeList {
    width: 100%;
    max-width: 1300px;
    margin: 10px 0;
    padding: 10px;
    background-color: #2b2b4b;
    .infoTitle {
      margin: 5px 0;
      h4 {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
    li {
      margin: 5px 0;
      line-height: 1.2;
    }
    span {
      font-size: 0.9rem;
      font-weight: bold;
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
    .listButtons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 150px;
      margin-top: 10px;
    }
  }

  .container .row {
    justify-content: center;
    .facility {
      height: auto;
      justify-self: center;
      align-items: center;
      padding: 10px;
      margin: 0px 0px 10px 10px;
      background-color: #34345c;
      border: solid #61618d 2px;
      border-radius: 10px;
      ${desktop` 
        // width: calc(33% - 15px);
        width: 300px;
        `}

      ${tablet`
        // width: calc(33% - 15px);
        width: 300px;
        `}

      ${mobile` 
        // width: calc(100% - 20px);
        width: calc(100% - 20px);
        margin: 5px 0;
      `}

    .divElement {
        /* text-align: center; */
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: center; */
        /* align-items: flex-start; */
        /* text-align: left; */

        ${desktop`
        font-size: 1.2em;
        // text-align: left;
        `}
        ${tablet`
          font-size: 1.2em;
          // text-align: left;
        `}
        ${mobile`
          font-size: 1.2em;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
        `}
      }
      .elementData {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: grey;
        padding: 3px 0;
        height: 43px;
        img {
          width: 35px;
          margin-right: 5px;
        }
      }
      .actionButtons {
        display: inline-flex;
        align-items: center;
        /* flex-direction: row; */
        /* justify-content: flex-start; */
        /* width: 150px; */
        margin: 10px 0 5px 0;
        /* padding: 0 10px; */

        ${tablet`
            // flex-direction: row;
            padding: 0;
          `}
        ${mobile`
            flex-direction: row;
            padding: 0;
          `}
          div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 5px;
        }
      }
    }
  }

  .changeStatusFormContainer {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 15px 0;
    border-radius: 15px;
    /* border-bottom: solid #fff 3px; */
    /* background-color: #420000; */
    color: #fff;
    ${mobile`
         padding: 15px 0 10px 0;
      `}
  }

  .aboutImage {
    max-width: 800px;
    img {
      width: 100%;
    }
  }

  .changeImageButtonFormContainer {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 40px 0;
    border-radius: 15px;
    /* border-bottom: solid #fff 3px; */
    background-color: #420000;
    color: #fff;
    ${mobile`
         padding: 15px 0 10px 0;
      `}
  }
`
