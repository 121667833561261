import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  showResetAdminPasswordForm,
  resetAdminPassword,
} from '../../api/adminPasswordReset'
import { ResetPasswordStyled } from './ResetPasswordStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../styles/elements/formsStyle'
import SuccesAlert from '../../components/alert/SuccesAlert'
import ErrorAlert from '../../components/alert/ErrorAlert'

function validateComplexEmail(email) {
  const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return complexEmailRegex.test(email)
}

const cleanFormData = {
  email: '',
  password: '',
  password_confirmation: '',
}

const validation = {
  email: (data) => data.email.length < 5 || !validateComplexEmail(data.email),
  password: (data) => data.password.length < 8,
  password_confirmation: (data) => data.password_confirmation !== data.password,
}

function ResetPassword() {
  const [formData, setFormData] = useState(cleanFormData)
  const { token } = useParams()

  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [tokenValid, setTokenValid] = useState(false)
  const [showTokenError, setShowTokenError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    // Check if the token is valid when the component is mounted
    showResetAdminPasswordForm(
      token,
      () => setTokenValid(true),
      (err) => {
        setTokenValid(false)
        setAlertMessage('Link-ul de resetare este invalid sau expirat')
        setShowTokenError(true) // Set to true to show the error message

        // Hide the error message after 2 seconds
        const tokenTimer = setTimeout(() => {
          setShowTokenError(false)
          window.location.href = '/'
        }, 2100)

        return () => clearTimeout(tokenTimer)
      }
    )
  }, [token])

  const handleChange = (e) => {
    const { name, value } = e.target

    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      resetAdminPassword(formData, token, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    console.log(response)
    setAlertMessage('Parola a fost schimbata cu succes!')
    setShowAlert(true)
    setFormData(cleanFormData)
    const timer = setTimeout(() => {
      window.location.href = '/'
    }, 2100)
    return () => clearTimeout(timer)
  }

  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  if (showTokenError) {
    return (
      <div>
        <ErrorAlert message={alertMessage} />
      </div>
    )
  }

  if (!tokenValid) {
    return null
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}
      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <ResetPasswordStyled>
        <div className='title'>
          <h2>Reseteaza parola</h2>
        </div>
        <GeneralFormStyled>
          <form onSubmit={handleSubmit}>
            <div className='oneField'>
              <div>
                <FieldLabel>
                  Email
                  {errors.includes('email') === true ? (
                    <ErrorLabel>Email invalid!</ErrorLabel>
                  ) : formData.email.length >= 6 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 6 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  placeholder='Email'
                />
              </div>
            </div>
            <div className='oneField'>
              <FieldLabel>
                Parola
                {errors.includes('password') === true ? (
                  <ErrorLabel>Parola e prea scurta!</ErrorLabel>
                ) : formData.password.length >= 8 ? (
                  <SuccesLabel>&#10004;</SuccesLabel>
                ) : (
                  <InfoLabel>Minim 8 caractere</InfoLabel>
                )}
              </FieldLabel>
              <input
                type='password'
                name='password'
                value={formData.password}
                onChange={handleChange}
                placeholder='Parola'
              />
            </div>
            <div className='oneField'>
              <FieldLabel>
                Repeta parola
                {errors.includes('password_confirmation') === true ? (
                  <ErrorLabel>Parolele nu se potrivesc!</ErrorLabel>
                ) : formData.password_confirmation.length >= 8 ? (
                  <SuccesLabel>&#10004;</SuccesLabel>
                ) : (
                  <InfoLabel>Minim 8 caractere</InfoLabel>
                )}
              </FieldLabel>
              <input
                type='password'
                name='password_confirmation'
                value={formData.password_confirmation}
                onChange={handleChange}
                placeholder='Repeta parola'
              />
            </div>
            <div className='submitContainer'>
              <button className='submitButton'>Trimite</button>
            </div>
          </form>
        </GeneralFormStyled>
      </ResetPasswordStyled>
    </>
  )
}

export default ResetPassword
