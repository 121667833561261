// import React, { useEffect, useState } from 'react'
// import { getSelectOption } from '../../api/shopAPI'

// function SelectOption({
//   selectedID,
//   onChange,
//   selectName,
//   defaultText = 'Selecteaza o optiune',
//   optionKey,
//   optionValue,
// }) {
//   const [options, setOptions] = useState([])

//   useEffect(() => {
//     getSelectOption(selectName, (response) => setOptions(response))
//   }, [selectName])

//   const handleSelect = (e) => {
//     const value = e.target.value
//     if (onChange) {
//       onChange(value) // Pass only the selected value
//     }
//   }

//   return (
//     <select
//       name={selectName}
//       id={selectName}
//       value={selectedID || 'default'}
//       onChange={handleSelect}
//     >
//       <option value='default'>{defaultText}</option>
//       {options.map((el, index) => (
//         <option key={index} value={el[optionKey]}>
//           {el[optionValue]}
//         </option>
//       ))}
//     </select>
//   )
// }

// export default SelectOption

import React, { useEffect, useState } from 'react'
import { getSelectOption } from '../../api/shopAPI'

function SelectOption({
  selectedID,
  onChange,
  selectName,
  defaultText = 'Selecteaza o optiune',
  optionKey,
  optionValue,
}) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    getSelectOption(selectName, (response) => setOptions(response))
  }, [selectName])

  const handleSelect = (e) => {
    const value = e.target.value
    if (onChange) {
      onChange({ target: { name: selectName, value } }) // This should update `selectedID` in the parent component
    }
  }

  return (
    <select
      name={selectName}
      id={selectName}
      value={selectedID || 'default'} // Directly use `selectedID`
      onChange={handleSelect}
    >
      <option value='default'>{defaultText}</option>
      {options.map((el, index) => (
        <option key={index} value={el[optionKey]}>
          {el[optionValue]}
        </option>
      ))}
    </select>
  )
}

export default SelectOption

// import React, { useEffect, useState } from 'react'
// import { getSelectOption } from '../../api/shopAPI'

// function SelectOption({
//   selectedID,
//   onChange,
//   selectName,
//   defaultText = 'Selecteaza o optiune',
//   optionKey,
//   optionValue,
// }) {
//   const [options, setOptions] = useState([])
//   const [selectedValue, setSelectedValue] = useState(selectedID || 'default')

//   useEffect(() => {
//     getSelectOption(selectName, (response) => setOptions(response))
//   }, [selectName])

//   useEffect(() => {
//     setSelectedValue(selectedID || 'default')
//   }, [selectedID])

//   const handleSelect = (e) => {
//     const value = e.target.value
//     setSelectedValue(value)
//     if (onChange) {
//       onChange({ target: { name: selectName, value } })
//     }
//   }

//   return (
//     <select
//       name={selectName}
//       id={selectName}
//       value={selectedValue}
//       onChange={handleSelect}
//     >
//       <option value='default'>{defaultText}</option>
//       {options.map((el, index) => (
//         <option key={index} value={el[optionKey]}>
//           {el[optionValue]}
//         </option>
//       ))}
//     </select>
//   )
// }

// export default SelectOption
