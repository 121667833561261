import React, { useEffect, useState } from 'react'
import { getJudeteNames } from '../../api/jutete-localitati'

function Judete({ selectedJudet, onChange }) {
  const [judete, setJudete] = useState([])
  const [selectedValue, setSelectedValue] = useState(selectedJudet || 'default')

  // Fetch judete data
  useEffect(() => {
    getJudeteNames((response) => {
      setJudete(response)
    })
  }, [])

  // Update selectedValue if selectedJudet changes
  useEffect(() => {
    setSelectedValue(selectedJudet || 'default')
  }, [selectedJudet])

  const handleSelect = (e) => {
    const value = e.target.value
    setSelectedValue(value)
    if (onChange && typeof onChange === 'function') {
      // Pass the event object to handleChange if needed
      onChange({ target: { name: 'judet', value } })
    } else {
      console.error('onChange is not a function or is undefined')
    }
  }

  return (
    <>
      <select
        name='judet' // Ensure name is present
        id='judet'
        value={selectedValue}
        onChange={handleSelect} // Use handleSelect for onChange
      >
        <option value='default'>Judetul</option>
        {judete.map((el, index) => (
          <option key={index} value={el.judet}>
            {el.judet}
          </option>
        ))}
      </select>
    </>
  )
}

export default Judete

// import React, { useEffect, useState } from 'react'
// import { getJudeteNames } from '../../api/jutete-localitati'

// function Judete(selectedJudet) {
//   const [judete, setJudete] = useState([])

//   useEffect(() => {
//     getJudeteNames((response) => {
//       setJudete(response)
//     })
//   }, [])

//   return (
//     <>
//       <select name='judet' id='judet'>
//         <option value='default'>Judetul</option>
//         {judete.map((el, index) => (
//           <option key={index} value={el.judet}>
//             {el.judet}
//           </option>
//         ))}
//       </select>
//     </>
//   )
// }

// export default Judete
