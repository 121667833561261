import React, { useState, useEffect } from 'react'
import capitalizeFirstLetter from '../../../functions/capinalizeFirstLetter'
import { AdminGreetingStyled } from './AdminGreetingStyled'

function AdminGreeting() {
  const [adminData, setAdminData] = useState([])
  const [storedCart, setstoredCart] = useState([])
  const totalQuantity = storedCart.reduce((sum, el) => sum + el.quantity, 0)

  useEffect(() => {
    const adminData = JSON.parse(localStorage.getItem('adminData'))

    if (adminData) {
      setAdminData(adminData)
    }

    const cartItems = JSON.parse(localStorage.getItem('cart'))
    if (cartItems) {
      setstoredCart(cartItems)
    }
  }, [])
  return (
    <>
      {adminData.last_name && (
        <AdminGreetingStyled>
          <div className='greetingContainer'>
            <div className='adminName'>
              <span>Bine ai revenit</span>
              <h2>{capitalizeFirstLetter(adminData.last_name)}</h2>
            </div>
            {totalQuantity !== 0 ? (
              <div className='adminCart'>
                <h2>NU UITA</h2> ca ai
                <h2>{totalQuantity}</h2>
                {totalQuantity === 1 ? 'produs' : 'produse'} in cos
              </div>
            ) : (
              ''
            )}
          </div>
        </AdminGreetingStyled>
      )}
    </>
  )
}

export default AdminGreeting
