import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  getShopCategorys,
  updateShopCategoryActive,
  updateFacilityPrincipal,
  deleteShopCategory,
} from '../../api/unitDataAPI'

import { CategoriesListStyled } from './CategoriesListStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButtonSmall,
  GreenSubmitButtonSmall,
  SubmitButtonSmall,
} from '../../styles/elements/buttons'

import SuccesAlert from '../alert/SuccesAlert'
import ErrorAlert from '../alert/ErrorAlert'

import BackToSetings from '../../views/buttons/backToSetings/BackToSetings'
import SingleImageUploader from '../singleImageUploader/SingleImageUploader'
import CategoryAddForm from './categoryAddForm/CategoryAddForm'
import TogleSwitch from '../buttons/togleSwitch/TogleSwitch'
import EditButton from '../buttons/edit/EditButton'
import DeleteButton from '../buttons/delete/DeleteButton'

const validation = {
  title: (data) => data.title.length < 10,
  description: (data) => data.description.length < 50,
}

function CategoriesList() {
  const [categories, setCategories] = useState([])
  const [showAddForm, setShowAddForm] = useState(null)
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getShopCategorys((response) => {
      setCategories(response)
    })
  }, [showAddForm, isLoading])

  function handleAddNew() {
    setShowAddForm(true)
  }
  function handleEdit(elID) {
    setShowAddForm(elID)
  }

  function handleDelete(elID) {
    // setShowAddForm(elID)
    setIsLoading(true)
    deleteShopCategory(elID, handleDeletedSuccess, handleError)
  }

  function handleDeletedSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Categoria a fost stearsa!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/setari'
    }, 2100)
    return () => clearTimeout(timer)
  }

  const handleActivateCategory = (id, active) => {
    // event.preventDefault()
    const newActive = active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateShopCategoryActive(id, newActive, handleActivateSuccess, handleError)
  }

  // const handlePrincipalCategory = (id, active) => {
  //   // event.preventDefault()
  //   const newActive = active === 1 ? { active: 0 } : { active: 1 }

  //   // Set loading state to true
  //   setIsLoading(true)

  //   updateCategoryPrincipal(id, newActive, handleActivateSuccess, handleError)
  // }

  function handleActivateSuccess(response) {
    // Update the category's active state directly in the categories array
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === response.id
          ? { ...category, active: response.active, short: response.short } // Update the active status
          : category
      )
    )

    // Reset loading state
    setIsLoading(false)
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      {categories ? (
        <>
          <CategoriesListStyled>
            <div className='title'>
              <h2>Categorii produse</h2>
            </div>
            <div className='readMeList'>
              <div className='infoTitle'>
                <h4>Cum lucram cu formularul.</h4>
              </div>
              <ul>
                <li>
                  <span>EDITEAZA</span> - deschide formular pentru editearae.
                </li>
                <li>
                  <span>STERGE</span> - Sterge definitiv.
                </li>
                <li>
                  <span>ACTIVEAZA - DEZACTIVEAZA</span> - Seteaza elementul sa
                  fie vizibil sau nu in pagina.
                </li>
                {/* <li>
                  <span>PRINCIPAL</span> - Seteaza facilitatea sa fie afisata in
                  partea de sus a pagini. Este recomandat sa <span>NU</span>{' '}
                  afisati mai mult de 4 facilitati principale. Activati cele mai
                  importane 4 facilitati ca principale.
                </li> */}
                <li>
                  - Daca butonul este <span>VERDE</span> elementul{' '}
                  <span>ESTE</span> activ pe site. <span>APASA</span> butonul
                  pentru activare.
                </li>
                <li>
                  - Daca butonul este <span>ROSU</span> elementul{' '}
                  <span>NU este activ</span> pe site. <span>APASA</span> butonul
                  pentru activare.
                </li>
                <li>
                  <span>
                    Mai jos aveti reprezentarea pe culori a butoanelor
                  </span>
                  <div className='listButtons'>
                    <TogleSwitch
                      checked={true}
                      label={'Activat'}
                      onChange={() => {}}
                      // readOnly
                    />
                    <TogleSwitch
                      checked={false}
                      label={'Dezactivat'}
                      onChange={() => {}}
                      // readOnly
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className='changeStatusFormContainer'>
              <SubmitButton onClick={() => handleAddNew()}>Adauga</SubmitButton>
            </div>
            <div className='container'>
              <div className='row'>
                {categories.map((el, index) => {
                  return (
                    <div className='col-6 category' key={index}>
                      <div className='divElement'>
                        <div className='elementData'>
                          {el.icon && (
                            <img src={el.icon} alt='' className='icon' />
                          )}
                          <span>{el.name}</span>
                        </div>
                        <div className='actionButtons'>
                          <EditButton onClick={() => handleEdit(el.id)} />
                          {/* <SubmitButtonSmall onClick={() => handleEdit(el.id)}>
                            Editează
                          </SubmitButtonSmall> */}
                          <DeleteButton
                            onClick={() => handleDelete(el.id)}
                            disabled={isLoading}
                          />
                          {/* <RedSubmitButtonSmall
                            // onClick={() => handleDelete(el.id)}
                            disabled={isLoading}
                          >
                            Sterge
                          </RedSubmitButtonSmall> */}
                          <div>
                            <TogleSwitch
                              checked={el.active === 1}
                              onChange={() =>
                                handleActivateCategory(el.id, el.active)
                              }
                              label={
                                el.active === 1 ? 'Dezactiveaza' : 'Activeaza'
                              }
                            />
                          </div>
                          {/* {el.active ? (
                            <RedSubmitButtonSmall
                              onClick={() =>
                                handleActivateCategory(el.id, el.active)
                              }
                              disabled={isLoading}
                            >
                              {isLoading ? 'Asteapta! ' : 'Dezactiveaza'}
                            </RedSubmitButtonSmall>
                          ) : (
                            <GreenSubmitButtonSmall
                              onClick={() => handleActivateCategory(el.id)}
                              disabled={isLoading}
                            >
                              {isLoading ? 'Asteapta! ' : 'Activeaza'}
                            </GreenSubmitButtonSmall>
                          )} */}
                          {/* <div>
                            <TogleSwitch
                              checked={el.short === 1}
                              onChange={() =>
                                handlePrincipalCategory(el.id, el.short)
                              }
                              label={'Principal'}
                            />
                          </div> */}
                          {/* {el.short ? (
                            <RedSubmitButtonSmall>
                              Principal
                            </RedSubmitButtonSmall>
                          ) : (
                            <GreenSubmitButtonSmall>
                              Principal
                            </GreenSubmitButtonSmall>
                          )} */}
                        </div>
                        <div className='deliveryHours'>
                          <h6>Ore livrare:</h6>
                          <span>
                            {el.delivery_from_time && el.delivery_to_time
                              ? `${el.delivery_from_time} - ${el.delivery_to_time}`
                              : 'Seteaza pentru a se putea comanda'}
                          </span>
                        </div>
                        <div className='sort'>Ordine afisare: {el.sort}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {showAddForm !== null && (
              <CategoryAddForm
                elID={showAddForm !== true ? showAddForm : null}
                cancel={() => setShowAddForm(null)}
              />
            )}
          </CategoriesListStyled>
        </>
      ) : (
        <p>Incarc date ...</p>
      )}
    </>
  )
}

export default CategoriesList
