import { apiRest } from './config';

// function logoUpload(
//   unitID,
//   uploadFolder,
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     .put(`/api/logo-upload/${unitID}/${uploadFolder}`)
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

const logoUpload = (
  unitID,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) => {
  apiRest
    .post(`/api/logo-upload/${unitID}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Set the header to handle file uploads
      }
    })
    .then(({ data }) => {
      successCallback(data);
    })
    .catch((err) => errorCallback(err));
};

export { logoUpload };
