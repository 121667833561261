import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  getCategories,
  getCategoriesByID,
  getAllProducts,
  getAllPaginatedProducts,
  getProductsByCategory,
  getProductById,
  updateProductActive,
  updateProductPrincipal,
  deleteProduct,
} from '../../api/shopAPI'
import { ProductsListStyled } from './ProductsListStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButtonSmall,
  GreenSubmitButtonSmall,
  SubmitButtonSmall,
} from '../../styles/elements/buttons'

import SuccesAlert from '../alert/SuccesAlert'
import ErrorAlert from '../alert/ErrorAlert'

import BackToSetings from '../../views/buttons/backToSetings/BackToSetings'
import SingleImageUploader from '../singleImageUploader/SingleImageUploader'
import ProductAddForm from '../productsForm/productAddForm/ProductAddForm'
import TogleSwitch from '../buttons/togleSwitch/TogleSwitch'
import EditButton from '../buttons/edit/EditButton'
import DeleteButton from '../buttons/delete/DeleteButton'
import Pagination from '../pagination/Pagination'

function ProductsList() {
  const [productList, setProductList] = useState({ products: [] })
  const [showChangeAboutImage, setShowChangeAboutImage] = useState(null)
  const [showAddForm, setShowAddForm] = useState(null)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1) // Track the current page for pagination
  const [orderBy, setOrderBy] = useState(1)
  const [fetchAllItems, setFetchAllItems] = useState(false) // Track whether to fetch all orders or paginated orders
  const navigate = useNavigate()
  // const ActivateButtonComponent =
  //   productList.active === 1 ? RedSubmitButtonSmall : GreenSubmitButtonSmall

  useEffect(() => {
    getAllPaginatedProducts(currentPage, orderBy, (response) => {
      setProductList(response)
      fetchItems()
    })
  }, [currentPage, fetchAllItems, orderBy, showAddForm, isLoading])

  const location = useLocation()
  const elID = location.state?.elID // Retrieve elID from the navigation state

  useEffect(() => {
    if (elID) {
      // Set the new state with elID when available
      setShowAddForm(elID)
    }
  }, [elID, setShowAddForm])

  function handleAddNew() {
    setShowAddForm(true)
  }
  function handleEdit(elID) {
    setShowAddForm(elID)
  }
  function handleDelete(elID) {
    // setShowAddForm(elID)
    setIsLoading(true)
    deleteProduct(elID, handleDeletedSuccess, handleError)
  }

  function handleDeletedSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Produsul a fost stears!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/setari'
    }, 2100)
    return () => clearTimeout(timer)
  }

  const handleActivateProduct = (id, active) => {
    // event.preventDefault()
    const newActive = active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateProductActive(id, newActive, handleActivateSuccess, handleError)
  }

  function handleActivateSuccess(response) {
    // Update the active status locally
    setProductList((prevActiveProduct) => ({
      products: prevActiveProduct.products.map((product) =>
        product.id === response.id
          ? { ...product, active: response.active }
          : product
      ),
    }))

    // Refetch paginated data
    fetchItems()

    // Reset loading state
    setIsLoading(false)
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  const fetchItems = () => {
    if (fetchAllItems) {
      // Fetch all products (unpaginated)
      getAllProducts(
        orderBy,
        (response) => setProductList(response),
        (error) => console.error('Failed to retrieve all products:', error)
      )
    } else {
      // Fetch paginated products
      getAllPaginatedProducts(
        currentPage,
        orderBy,
        (response) => setProductList(response),
        (error) =>
          console.error('Failed to retrieve paginated products:', error)
      )
    }
  }

  // Function to toggle between fetching all orders and paginated orders
  const toggleFetchAllItems = () => {
    setFetchAllItems((prev) => !prev) // Toggle the fetchAllItems state
    setCurrentPage(1) // Reset to the first page when switching modes
  }

  // Function to handle pagination page changes
  const handlePageChange = (page) => {
    setCurrentPage(page) // Update the current page state
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      {productList ? (
        <>
          <ProductsListStyled>
            <div className='title'>
              <h2>Produse</h2>
            </div>
            <div className='readMeList'>
              <div className='infoTitle'>
                <h4>Cum lucram cu formularul.</h4>
              </div>
              <ul>
                <li>
                  <span>EDITEAZA</span> - deschide formular pentru editearae
                  facilitatii.
                </li>
                <li>
                  <span>STERGE</span> - Sterge definitiv facilitatea.
                </li>
                <li>
                  <span>ACTIVEAZA - DEZACTIVEAZA</span> - Seteaza facilitatea sa
                  fie vizibila sau nu in pagina la sectiunea facilitati.
                </li>
                <li>
                  <span>PRINCIPAL</span> - Seteaza facilitatea sa fie afisata in
                  partea de sus a pagini. Este recomandat sa <span>NU</span>{' '}
                  afisati mai mult de 4 facilitati principale. Activati cele mai
                  importane 4 facilitati ca principale.
                </li>
                <li>
                  - Daca butonul este <span>VERDE</span> facilitatea{' '}
                  <span>ESTE</span> activa pe site. <span>APASA</span> butonul
                  pentru activare.
                </li>
                <li>
                  - Daca butonul este <span>ROSU</span> facilitatea{' '}
                  <span>NU este activa</span> pe site. <span>APASA</span>{' '}
                  butonul pentru activare.
                </li>
                <li>
                  <span>
                    Mai jos aveti reprezentarea pe culori a butoanelor
                  </span>
                  <div className='listButtons'>
                    <TogleSwitch
                      checked={true}
                      label={'Activat'}
                      onChange={() => {}}
                      // readOnly
                    />
                    <TogleSwitch
                      checked={false}
                      label={'Dezactivat'}
                      onChange={() => {}}
                      // readOnly
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className='addNewButtonContainer'>
              <SubmitButton onClick={() => handleAddNew()}>Adauga</SubmitButton>
            </div>
            {!fetchAllItems && productList.last_page > 1 && (
              <Pagination
                currentPage={productList.current_page}
                totalPages={productList.last_page}
                onPageChange={handlePageChange}
              />
            )}

            <div className='optionsview'>
              <div>{productList.total} de produse adaugate.</div>
              <div className='pagesView'>
                {/* Button to toggle between "All" and "Paginated" views */}
                Vezi{' '}
                <button onClick={toggleFetchAllItems}>
                  {fetchAllItems ? '10 produse / pag' : 'Toate produsele'}{' '}
                  {/* Change button text based on the state */}
                </button>
              </div>

              <div className='orderByView'>
                Ordoneaza dupa{' '}
                <button onClick={() => setOrderBy()}>Categorie</button>
                <button onClick={() => setOrderBy(1)}>Active</button>
                <button onClick={() => setOrderBy(2)}>Inactive</button>
                <button onClick={() => setOrderBy(3)}>Pret</button>
              </div>
            </div>
            <div>search</div>
            <div className='container'>
              <div className='row'>
                {productList.products.map((el, index) => {
                  return (
                    <div className='col-6 article' key={index}>
                      <div className='divElement'>
                        <div className='actionButtons'>
                          <EditButton onClick={() => handleEdit(el.id)} />
                          <DeleteButton
                            onClick={() => handleDelete(el.id)}
                            disabled={isLoading}
                          />
                          <div>
                            <TogleSwitch
                              checked={el.active === 1}
                              onChange={() =>
                                handleActivateProduct(el.id, el.active)
                              }
                              label={
                                el.active === 1 ? 'Dezactiveaza' : 'Activeaza'
                              }
                            />
                          </div>
                        </div>
                        <div className='categoryName'>
                          <h3>Categorie: {el.product_category.name}</h3>
                        </div>
                        <div className='productName'>
                          <h2>{el.name}</h2>
                        </div>
                        <div className='productEan'>
                          Cod produs:<h3>{el.ean_code}</h3>
                        </div>
                        <div className='tumbnailImg'>
                          {el.cover_image !== null ? (
                            <img
                              src={el.cover_image.image_link}
                              alt={el.name}
                              className='coverImg'
                            />
                          ) : (
                            <img
                              // src={
                              //   'https://us.123rf.com/450wm/koblizeek/koblizeek2208/koblizeek220800027/189908666-no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-placeholder.jpg'
                              // }
                              src={
                                'https://www.aroma-pasiunii.intorsura.ro/media/aroma-pasiunii-no-photo.png'
                              }
                              alt={el.name}
                              className='coverImg'
                            />
                          )}
                        </div>
                        <div className='prodWeight'>
                          Portie: {el.weight} {el.weight_classes.name_short}
                        </div>
                        <div className='price'>
                          {el.price} {el.currency.currency_short}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {showAddForm !== null && (
              <ProductAddForm
                elID={showAddForm !== true ? showAddForm : null}
                cancel={() => setShowAddForm(null)}
              />
            )}
            {!fetchAllItems && productList.last_page > 1 && (
              <Pagination
                currentPage={productList.current_page}
                totalPages={productList.last_page}
                onPageChange={handlePageChange}
              />
            )}
          </ProductsListStyled>
        </>
      ) : (
        <p>Incarc date ...</p>
      )}
    </>
  )
}

export default ProductsList
