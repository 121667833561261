import { apiRest } from './config'

function loginCustomer(
  username,
  password,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post('/customer/login', { username, password })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getCustomerByID(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/customer/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addCustomer(
  customerData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/customers`, customerData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// function updateCustomer(
//   id,
//   customerData,
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     .post(`/api/customers/${id}`, customerData)
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

function updateCustomerData(
  id,
  customerData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/customers/${id}`, customerData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateCustomerUsername(
  id,
  customerData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/customers/username/${id}`, customerData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateCustomerEmail(
  id,
  customerData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/customers/email/${id}`, customerData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

export {
  loginCustomer,
  getCustomerByID,
  addCustomer,
  // updateCustomer,
  updateCustomerData,
  updateCustomerUsername,
  updateCustomerEmail,
}
