import styled from 'styled-components'
import {
  desktop,
  tablet,
  mobile,
} from '../../../../styles/setings/mediaScreens'
import { articleTitleH2 } from '../../../../styles/setings/variables'
import { COLORS } from '../../../../styles/setings/colors'

export const TodayOrdersHistoryStyled = styled.section`
  width: 100%;
  /* display: flex;
  justify-content: center;
  flex-direction: row; */
  /* flex-wrap: nowrap; */
  border: solid ${COLORS.colorAboutBorder} 1px;
  border-radius: 10px;
  padding: 15px;
  ${desktop`
    // padding: 5px 10px;
    // margin-top: 25px;
  `}
  ${tablet`
    // margin-top: 25px;
  `}
  ${mobile`
    // flex-direction: row;
    // flex-wrap: wrap;
    // margin-top: 25px;
  `}
  .artTitle {
    margin: 5px 0;
    h2 {
      /* display: inline-flex; */
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};
      /* background-color: grey; */
      width: 100%;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      height: 2.5rem;

      ${desktop`
      `}
      ${tablet`
        `}
    ${mobile`
    `}
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .orderList {
      display: flex;
      flex-direction: row;
      /* background-color: grey; */
      width: auto;
      ${desktop`
        justify-content: space-between;
        // width: auto;
      `}
      ${tablet`
        width: auto;
        justify-content: center;
        // justify-content: space-between;
        width: 100%;
      `}
      ${mobile`
        // flex-direction: column;
        // flex-wrap: wrap;
        // margin-top: 25px;
        justify-content: center;
        // width: 100%;
      `}

      .order {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 5px;
        width: 200px;
        border-radius: 15px;
        cursor: pointer;
        ${desktop`
          `}
        ${tablet`
        // width: 100%;
        `}
        ${mobile`
          // width: 100%;
        `}

        .orderNumber {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          ${desktop`
            padding: 5px 0 5px 0; 
          `}
          ${tablet`
            padding: 10px 20px;
          `}
          ${mobile`
            width: 100%;
            // border-radius: 55px;
            // margin-top: 10px;
            // margin-left: 10px;
            // margin: 10px;
            // padding: 10px 20px;
          `}
          span {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: bold;
            h4 {
              margin-left: 5px;
              font-size: 2rem;
              font-weight: bold;
            }
          }
        }
      }
      .orderStatus {
        margin: 5px 0;
        font-size: 1em;
        /* font-weight: bold; */
        text-transform: uppercase;
      }
      .orderDate {
        margin-top: 5px;
        font-size: 0.8em;
      }
      .orderTime {
        margin-top: 5px;
        font-size: 0.8em;
        margin-bottom: 10px;
      }
      .timeDiffrence {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        padding: 5px 0;
        background-color: #e32222;
        margin-top: 5px;
        font-size: 0.8em;
        span {
          font-size: 1rem;
          font-weight: bold;
        }
      }

      .finishedTimeDiffrence {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        padding: 5px 0;
        background-color: #00370e;
        margin-top: 5px;
        font-size: 0.8em;
        span {
          font-size: 1rem;
          font-weight: bold;
        }
      }

      .orderPrice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: solid ${COLORS.colorAboutBorder} 1px; */
        width: 100%;
        height: auto;
        padding: 5px 15px;

        /* background-color: grey; */
        /* ${mobile`
        justify-content: center;
        align-items: center;
        height: auto;
        `} */

        .orderValue {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          /*font-size: 1.3rem;
        font-weight: bold;
        /* margin-top: 10px;
        margin-left: 10px; */
          /* background-color: grey; */
          width: 100%;
          height: 1.5rem;
          ${mobile`
          justify-content: center;
          `}
          span {
            font-size: 1em;
            font-weight: bold;
            margin: 0 5px;
          }

          h3 {
            font-size: 1.3em;
            font-weight: bold;

            ${desktop`
        // align-items: flex-end;
        // justify-content: flex-start;    
        `}
            ${tablet`
        // align-items: center;
        // justify-content: center; 
        `}
        ${mobile`
        // align-items: center;
        // justify-content: center;
        `}
          }
        }
      }
      .recived {
        background-color: #0f2e5d;
        border: solid #0f2e5d 2px;
        color: #fff;
        &:hover {
          background-color: #003a92;
          border: solid #fff 2px;
          color: #fff;
        }
      }
      .inPreparation {
        background-color: #d2521b;
        border: solid #9a2e00 2px;
        color: #fff;
        &:hover {
          background-color: #ff4d00;
          border: solid #fff 2px;
          color: #fff;
        }
      }
      .finished {
        background-color: #005816;
        border: solid #00370e 2px;
        color: #fff;
        &:hover {
          background-color: #008c23;
          border: solid #fff 2px;
          color: #fff;
        }
      }
      .canceld {
        /* position: relative; */
        /* z-index: 0; */
        background-color: #bb0101;
        border: solid #9a0000 2px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        /* &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 5px;
          background-color: #fff;
        }

        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        } */

        &:hover {
          background-color: #972929;
          border: solid #fff 2px;
        }
      }
    }
  }
`
