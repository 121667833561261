import React from 'react'
import { DeleteButtonStyled } from '../delete/DeleteButtonStyled'

function DeleteButton({ onClick }) {
  return (
    <>
      <DeleteButtonStyled onClick={onClick}>
        <img
          src='https://www.admin.aroma-pasiunii.intorsura.ro/media/icons/delete-red.png'
          alt='Sterge'
        />
      </DeleteButtonStyled>
    </>
  )
}

export default DeleteButton
