import React from 'react'
import { PageStartInfoStyled } from './PageStartInfoStyled'
import PageWorkingTimes from '../pageWorkingTimes/PageWorkingTimes'
import PageFacilitys from '../pageFacilitys/PageFacilitys'
import PageDelivery from '../pageDelivery/PageDelivery'

function PageStartInfo() {
  return (
    <>
      <PageStartInfoStyled id='startInfo'>
        <div className='startInfoContainer'>
          <PageFacilitys />
          <PageWorkingTimes />
        </div>
      </PageStartInfoStyled>
      <PageDelivery />
    </>
  )
}

export default PageStartInfo
