import React, { useEffect, useState } from 'react'
import { ChangeOrderStatusStyled } from './ChangeOrderStatusStyled'
import { changeOrderStatusByOrderID } from '../../api/orders'
import SuccesAlert from '../alert/SuccesAlert'
import ErrorAlert from '../alert/ErrorAlert'

function ChangeOrderStatus({ orderStatus, orderId }) {
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  // console.log(orderStatus)

  // useEffect(() => {})
  const inPreparationStatus = 2
  const finishOrderstatus = 3
  const cancelOrderStatus = 5
  const [loading, setLoading] = useState(false)

  function inPreparation() {
    setAlertMessage(
      'Te rog sa astepti. Pun comanda ' + orderId + ' in preparare!'
    )
    changeOrderStatusByOrderID(
      orderId,
      inPreparationStatus,
      handleSuccess,
      handleError
    )
  }
  function finishOrder() {
    setAlertMessage('Te rog sa astepti. Finalizez comanda ' + orderId)
    changeOrderStatusByOrderID(
      orderId,
      finishOrderstatus,
      handleSuccess,
      handleError
    )
  }
  function cancelOrder() {
    setAlertMessage('Te rog sa astepti. Anulez comanda ' + orderId)
    changeOrderStatusByOrderID(
      orderId,
      cancelOrderStatus,
      handleSuccess,
      handleError
    )
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
    setLoading(false)
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    // setAlertMessage('Statusul a fost schimbat!')
    setLoading(true)
    setShowAlert(true)

    // setFormData(cleanFormData)
    // navigate('/login')
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = '/'
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}
      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <ChangeOrderStatusStyled>
        <div className='title'>
          <h4>Schimba statusul comenzii</h4>
        </div>
        <div className='buttons'>
          {orderStatus === 1 && (
            <button
              className='prepareOrder'
              onClick={() => {
                inPreparation()
              }}
              disabled={loading}
            >
              In preparare
            </button>
          )}
          {orderStatus === 2 && (
            <button
              className='finishOrder'
              onClick={() => {
                finishOrder()
              }}
            >
              Finalizeaza
            </button>
          )}
          {orderStatus === 1 && (
            <button
              className='cancelOrder'
              onClick={() => {
                cancelOrder()
              }}
            >
              Anuleaza
            </button>
          )}
          {orderStatus === 3 && (
            <div>Comanda este FINALIZATA si nu se mai poate modifica!</div>
          )}
          {orderStatus === 5 && (
            <div>Comanda este ANULATA si nu se mai poate modifica!</div>
          )}
        </div>
      </ChangeOrderStatusStyled>
    </>
  )
}

export default ChangeOrderStatus
