import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { loginAdmin } from '../../api/admins'
import { LoginStyled } from './LoginStyled'

import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
  ExtraLinksContainer,
} from '../../styles/elements/formsStyle'

import ErrorAlert from '../../components/alert/ErrorAlert'

const cleanFormData = {
  username: '',
  password: '',
}

const validation = {
  username: (data) => data.username.length < 4,
  password: (data) => data.password.length < 8,
}

function Login({ onLogin }) {
  const [formData, setformData] = useState(cleanFormData)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target

    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setformData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      // loginCustomer(
      loginAdmin(
        formData.username,
        formData.password,
        handleSuccess,
        handleError
      )
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    onLogin(true)
    // localStorage.setItem('userData', JSON.stringify(response))
    localStorage.setItem('adminData', JSON.stringify(response))
    navigate('/')
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <LoginStyled>
        <div className='title'>
          <h2>Intra in cont</h2>
        </div>
        <GeneralFormStyled>
          <form onSubmit={handleSubmit}>
            <div className='oneField'>
              <FieldLabel>
                Utilizator
                {errors.includes('username') === true ? (
                  <ErrorLabel>Utilizator prea scurt!</ErrorLabel>
                ) : formData.username.length >= 4 ? (
                  <SuccesLabel>&#10004;</SuccesLabel>
                ) : (
                  <InfoLabel>Minim 4 caractere</InfoLabel>
                )}
              </FieldLabel>
              <input
                type='text'
                name='username'
                placeholder='username'
                value={formData.username}
                onChange={handleChange}
              />
            </div>
            <div className='oneField'>
              <FieldLabel>
                Parola
                {errors.includes('password') === true ? (
                  <ErrorLabel>Parola e prea scurta!</ErrorLabel>
                ) : formData.password.length >= 8 ? (
                  <SuccesLabel>&#10004;</SuccesLabel>
                ) : (
                  <InfoLabel>Minim 8 caractere</InfoLabel>
                )}
              </FieldLabel>
              <input
                type='password'
                name='password'
                placeholder='Parola'
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <div className='submitContainer'>
              <button className='submitButton'>Intra</button>
            </div>
          </form>
        </GeneralFormStyled>
        <ExtraLinksContainer>
          {/* <Link to='/register'>Creeaza cont</Link> */}
          <Link to='/forgot-password'>Am uitat parola</Link>
        </ExtraLinksContainer>
      </LoginStyled>
    </>
  )
}

export default Login
