function displayPhoneNumber(phoneNumber) {
  if (phoneNumber.length !== 10) {
    return ''
  }

  const formatNumber = `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(
    4,
    7
  )}-${phoneNumber.slice(7)}`
  return formatNumber
}

export default displayPhoneNumber
