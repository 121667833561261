import styled from 'styled-components'
import { mobile } from '../../styles/setings/mediaScreens'

export const SliderContainer = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .navigationButtons {
    padding: 10px 0;
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1.3rem;
    font-weight: bold;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 12;
    /* top: 25%; */
    border-radius: 50%;
    ${mobile`
    // display: none;
    `}

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .left {
    left: 15px;
  }

  .right {
    right: 15px;
  }
`

export const SliderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: solid #ccc 1px;
  width: 100%;
  transition: transform 0.5s ease;
  transform: translateX(${(props) => props.$translate}px); // Using $translate
`

export const Slide = styled.div`
  flex: 0 0 ${(props) => props.$slideWidth}px; // Using $slideWidth
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: ${(props) =>
    props.$isSelected ? '2px solid blue' : 'none'}; // Using $isSelected
  transition: border 0.3s ease;
  /* width: calc(100%-65px); */

  &:hover {
    border: 2px solid lightblue;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 10;
  transform: translateY(-300%);
  ${mobile`
  transform: translateY(-150%);
  `}

  .navigationButtons {
    padding: 10px 0;
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1.3rem;
    font-weight: bold;
    width: 30px;
    height: 50px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .navigationButtons:first-child {
    left: 0;
  }

  .navigationButtons:last-child {
    right: 0;
  }
`
