import React, { useEffect, useState, useRef } from 'react'
import { TodayOrdersHistoryStyled } from './TodayOrdersHistoryStyled'
import { getTodayOrders } from '../../../../api/orders'
import OrderdProducts from '../../orderdProducts/OrderdProducts'
import {
  formatDate,
  formatHours,
  storedTimeDifference,
} from '../../../../functions/formatDate'
import ErrorDisplay from '../../../../components/alert/ErrorDisplay'
import CurrentDate from '../../../../components/curentDate/CurrentDate'
import TimeDifference from '../../../../components/timeDifrence/TimeDifference'

function TodayOrdersHistory() {
  const [orderList, setOrderList] = useState([])
  const [visibleOrderId, setVisibleOrderId] = useState(null)
  const [responseErrors, setResponseErrors] = useState([])
  const [refreshCountdown, setRefreshCountdown] = useState(30) // 30 seconds countdown
  const previousOrderListRef = useRef(orderList)
  const alarmSound =
    'https://admin.aroma-pasiunii.intorsura.ro/media/alarms/order-recived-alarm.wav'

  useEffect(() => {
    const fetchOrders = () => {
      getTodayOrders(
        (response) => {
          // Compare previous order list with the new one
          if (response.length > previousOrderListRef.current.length) {
            playNewOrderSound()
          }
          previousOrderListRef.current = response // Update the reference
          setOrderList(response)
          setRefreshCountdown(30) // Reset countdown after fetching
          setResponseErrors([])
        },
        (error) => {
          handleError(error)
          setOrderList([])
        }
      )
    }

    // Fetch orders immediately on component mount
    fetchOrders()

    // Set up the interval to refresh the orders every 30 seconds
    const refreshIntervalId = setInterval(fetchOrders, 30000) // 30000ms = 1 minute

    // Set up the countdown timer
    const countdownIntervalId = setInterval(() => {
      setRefreshCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1
        } else {
          return 30 // Reset countdown after it reaches 0
        }
      })
    }, 1000) // Update every second

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(refreshIntervalId)
      clearInterval(countdownIntervalId)
    }
  }, [])

  const playNewOrderSound = () => {
    // const audio = new Audio('/path-to-sound-file.mp3') // Replace with the path to your sound file
    const audio = new Audio(alarmSound) // Replace with the path to your sound file
    audio.play().catch((err) => console.error('Failed to play sound:', err))
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {responseErrors.map((message, index) => (
        <ErrorDisplay
          key={index}
          message={message}
          onRemove={() => removeError(index)}
        />
      ))}
      {orderList.length > 0 ? (
        <TodayOrdersHistoryStyled>
          <div className='col-12 artTitle'>
            <h2>
              Comezile de azi <CurrentDate />
            </h2>
          </div>

          <div className='container' id='orders'>
            <div className='row orderList'>
              {orderList.map((el, index) => (
                <React.Fragment key={index}>
                  <div
                    className={
                      el.active === 1
                        ? 'col-4 order recived'
                        : el.active === 2
                        ? 'col-4 order inPreparation'
                        : el.active === 5
                        ? 'col-4 order canceld'
                        : el.active === 3
                        ? 'col-4 order finished'
                        : ''
                    }
                    onClick={() => {
                      setVisibleOrderId(
                        visibleOrderId === el.order_id ? null : el.order_id
                      )
                    }}
                  >
                    <div className='orderNumber'>
                      <span>
                        <h4>{el.order_id}</h4>
                      </span>
                    </div>
                    <div className='orderStatus'>
                      {el.active === 1
                        ? 'Comanda noua'
                        : el.active === 2
                        ? 'In preparare'
                        : el.active === 5
                        ? 'Anulata'
                        : el.active === 3
                        ? 'Finalizata'
                        : ''}
                    </div>
                    <div className='orderPrice'>
                      <div className='orderValue'>
                        <span>Total: </span>
                        <h3>{Number(el.total_price).toFixed(2)}</h3>{' '}
                        <span>{el.currency}</span>
                      </div>
                    </div>
                    <div className='orderTime'>
                      Primita la ora: {formatHours(el.created_at)}
                    </div>
                    {el.active === 3 ? (
                      <div className='finishedTimeDiffrence'>
                        <div>Finalizata in:</div>
                        <span>
                          {storedTimeDifference(
                            formatHours(el.created_at),
                            formatHours(el.updated_at)
                          )}
                        </span>
                      </div>
                    ) : (
                      ''
                    )}

                    {(el.active === 1 || el.active === 2) && (
                      <div className='timeDiffrence'>
                        <div>Au trecut:</div>
                        <TimeDifference
                          storedTime={formatHours(el.created_at)}
                        />
                      </div>
                    )}
                  </div>

                  {visibleOrderId === el.order_id && (
                    <OrderdProducts
                      cancel={() => setVisibleOrderId(null)}
                      orderId={el.order_id}
                      totalOrderPrice={el.total_price}
                      currency={el.currency}
                      orderStatus={el.active}
                      customerID={el.user_id}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </TodayOrdersHistoryStyled>
      ) : (
        ''
      )}
    </>
  )
}

export default TodayOrdersHistory

// import React, { useEffect, useState } from 'react'
// import { TodayOrdersHistoryStyled } from './TodayOrdersHistoryStyled'
// import { getTodayOrders } from '../../../../api/orders'
// import OrderdProducts from '../../orderdProducts/OrderdProducts'
// import {
//   formatDate,
//   formatHours,
//   storedTimeDifference,
// } from '../../../../functions/formatDate'
// import ErrorDisplay from '../../../../components/alert/ErrorDisplay'
// import CurrentDate from '../../../../components/curentDate/CurrentDate'
// import TimeDifference from '../../../../components/timeDifrence/TimeDifference'

// function TodayOrdersHistory() {
//   const [customerData, setCustomerData] = useState({})
//   const [orderList, setOrderList] = useState([])
//   const [visibleOrderId, setVisibleOrderId] = useState(null)
//   const [responseErrors, setResponseErrors] = useState([])
//   const [refreshCountdown, setRefreshCountdown] = useState(30) // 30 seconds countdown

//   useEffect(() => {
//     const fetchOrders = () => {
//       // const storedCustomerData = JSON.parse(localStorage.getItem('adminData'))
//       // if (storedCustomerData) {
//       // setCustomerData(storedCustomerData)
//       getTodayOrders(
//         // storedCustomerData.id,
//         (response) => {
//           setOrderList(response)
//           setRefreshCountdown(30) // Reset countdown after fetching
//           setResponseErrors([])
//         },
//         (error) => {
//           handleError(error)
//           setOrderList([])
//         }
//       )
//       // }
//     }

//     // Fetch orders immediately on component mount
//     fetchOrders()

//     // Set up the interval to refresh the orders every 30 seconds
//     const refreshIntervalId = setInterval(fetchOrders, 30000) // 30000ms = 1 minute

//     // Set up the countdown timer
//     const countdownIntervalId = setInterval(() => {
//       setRefreshCountdown((prevCountdown) => {
//         if (prevCountdown > 0) {
//           return prevCountdown - 1
//         } else {
//           return 30 // Reset countdown after it reaches 0
//         }
//       })
//     }, 1000) // Update every second

//     // Cleanup intervals on component unmount
//     return () => {
//       clearInterval(refreshIntervalId)
//       clearInterval(countdownIntervalId)
//     }
//   }, [])

//   function handleError(error) {
//     console.error(error)

//     // Parse the error response
//     const errorResponse = error.response.data
//     const parsedErrors = Object.values(errorResponse).flat()

//     // Update the state with the parsed errors
//     setResponseErrors(parsedErrors)
//   }

//   // Function to remove an error message from the state
//   const removeError = (index) => {
//     setResponseErrors(responseErrors.filter((_, i) => i !== index))
//   }

//   return (
//     <>
//       {responseErrors.map((message, index) => (
//         <ErrorDisplay
//           key={index}
//           message={message}
//           onRemove={() => removeError(index)}
//         />
//       ))}
//       {/* <div className='refresh-timer'>
//         <p>Next refresh in: {refreshCountdown} seconds</p>
//       </div> */}
//       {orderList.length > 0 ? (
//         <TodayOrdersHistoryStyled>
//           <div className='col-12 artTitle'>
//             <h2>
//               Comezile de azi <CurrentDate />
//             </h2>
//           </div>

//           <div className='container' id='orders'>
//             <div className='row orderList'>
//               {orderList.map((el, index) => (
//                 <React.Fragment key={index}>
//                   <div
//                     className={
//                       el.active === 1
//                         ? 'col-4 order recived'
//                         : el.active === 2
//                         ? 'col-4 order inPreparation'
//                         : el.active === 5
//                         ? 'col-4 order canceld'
//                         : el.active === 3
//                         ? 'col-4 order finished'
//                         : ''
//                     }
//                     onClick={() => {
//                       setVisibleOrderId(
//                         visibleOrderId === el.order_id ? null : el.order_id
//                       )
//                     }}
//                   >
//                     <div className='orderNumber'>
//                       <span>
//                         <h4>{el.order_id}</h4>
//                       </span>
//                     </div>
//                     <div className='orderStatus'>
//                       {el.active === 1
//                         ? 'Comanda noua'
//                         : el.active === 2
//                         ? 'In preparare'
//                         : el.active === 5
//                         ? 'Anulata'
//                         : el.active === 3
//                         ? 'Finalizata'
//                         : ''}
//                     </div>
//                     <div className='orderPrice'>
//                       <div className='orderValue'>
//                         <span>Total: </span>
//                         <h3>{Number(el.total_price).toFixed(2)}</h3>{' '}
//                         <span>{el.currency}</span>
//                       </div>
//                     </div>
//                     {/* <div className='orderDate'>
//                       Data: {formatDate(el.created_at)}
//                     </div> */}
//                     <div className='orderTime'>
//                       Primita la ora: {formatHours(el.created_at)}
//                     </div>
//                     {/* <div className='timeDiffrence'>
//                       <div>Au trecut: </div>
//                       <TimeDifference storedTime={formatHours(el.created_at)} />
//                     </div> */}
//                     {el.active === 3 ? (
//                       // <div className='orderTime'>
//                       //   Finalizata la ora: {formatHours(el.updated_at)}
//                       // </div>
//                       <div className='finishedTimeDiffrence'>
//                         <div>Finalizata in:</div>
//                         <span>
//                           {storedTimeDifference(
//                             formatHours(el.created_at),
//                             formatHours(el.updated_at)
//                           )}
//                         </span>
//                       </div>
//                     ) : (
//                       ''
//                     )}

//                     {(el.active === 1 || el.active === 2) && (
//                       <div className='timeDiffrence'>
//                         <div>Au trecut:</div>
//                         <TimeDifference
//                           storedTime={formatHours(el.created_at)}
//                         />
//                       </div>
//                     )}
//                   </div>

//                   {visibleOrderId === el.order_id && (
//                     <OrderdProducts
//                       cancel={() => setVisibleOrderId(null)}
//                       orderId={el.order_id}
//                       totalOrderPrice={el.total_price}
//                       currency={el.currency}
//                       orderStatus={el.active}
//                       customerID={el.user_id}
//                     />
//                   )}
//                 </React.Fragment>
//               ))}
//             </div>
//           </div>
//         </TodayOrdersHistoryStyled>
//       ) : (
//         ''
//       )}
//     </>
//   )
// }

// export default TodayOrdersHistory
