import styled from 'styled-components'
import { COLORS, addUserColors, formColors } from '../../styles/setings/colors'
import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens'

export const ProductsListStyled = styled.section`
  // margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 30px auto; */
  /* padding-bottom: 20px; */
  // padding: 20px 0 40px 0;
  background-color: ${COLORS.colorAboutSectionBg};
  /* background-color: grey; */
  ${desktop`
    margin-top: 20px;
  `}
  ${tablet`
    margin-top: 20px;
  `}
  ${mobile`
    margin-top: 10px;
  `}

    .optionsview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.1em;
    color: #fff;
    width: 100%;
    max-width: 1300px;
    ${mobile`
      flex-direction: column;
      justify-content: none;
      align-items: center;
    `}
    .pagesView {
      /* display: flex; */
      flex-direction: row;
      button {
        background-color: transparent;
        border: none;
        margin: 0 5px;
        font-size: 1.1em;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .orderByView {
      /* display: flex; */
      flex-direction: row;
      button {
        background-color: transparent;
        border: none;
        margin: 0 5px;
        font-size: 1.1em;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .title {
    margin: 15px 0;

    h2 {
      display: inline-flex;
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};

      ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
      `}
      ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
      `}
    ${mobile`
        align-items: center;
        justify-content: center;    
        width: 100%;
      font-size: 1.3em;
    `}
    }
  }

  .container .row {
    justify-content: center;
    .article {
      height: auto;
      justify-self: center;
      align-items: center;
      padding: 10px;
      margin: 0px 0px 10px 10px;
      background-color: #34345c;
      border: solid #61618d 2px;
      border-radius: 10px;
      ${desktop` 
        // width: calc(33% - 15px);
        width: 300px;
        `}

      ${tablet`
        // width: calc(33% - 15px);
        width: 300px;
        `}

      ${mobile` 
        // width: calc(100% - 20px);
        width: calc(100% - 20px);
        margin: 5px 0;
      `}
    }
  }

  .readMeList {
    width: 100%;
    max-width: 1300px;
    margin: 10px 0;
    padding: 10px;
    background-color: #2b2b4b;
    .infoTitle {
      margin: 5px 0;
      h4 {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
    li {
      margin: 5px 0;
      line-height: 1.2;
    }
    span {
      font-size: 0.9rem;
      font-weight: bold;
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
    .listButtons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 150px;
      margin-top: 10px;
    }
  }

  .divElement {
    /* text-align: center; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: flex-start; */
    /* text-align: left; */

    ${desktop`
        font-size: 1.2em;
        // text-align: left;
        `}
    ${tablet`
          font-size: 1.2em;
          // text-align: left;
        `}
        ${mobile`
          font-size: 1.2em;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
        `}
  }

  .elementData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: grey;
    padding: 3px 0;
    height: 43px;
    img {
      width: 35px;
      margin-right: 5px;
    }
  }

  .actionButtons {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin: 5px 0 10px 0;
    padding: 10px 5px;
    background-color: #20203a;
    border-radius: 10px;

    ${tablet`

    `}
    ${mobile`
        flex-direction: row;

    `} div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
    }
  }

  .tumbnailImg {
    width: 100%;
    display: flex;
    justify-content: center;

    .coverImg {
      width: 85%;
      max-height: 150px;
      border-radius: 15px;
      margin: 10px 0;
    }
  }

  .categoryName {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1em;
    margin: 5px 0 2px 0;
    padding-left: 5px;
    /* background-color: grey; */
    h3 {
      font-size: 0.9rem;
      font-weight: bold;
      color: #fff;
    }

    /* ${mobile`
      width: 100%;
      `} */
  }

  .productName {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.8em;
    margin: 5px 0;
    background-color: grey;
    h2 {
      font-size: 1em;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }

    /* ${mobile`
      width: 100%;
      `} */
  }

  .productEan {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1em;
    margin: 5px 0 2px 0;
    padding-left: 5px;
    font-size: 0.9rem;
    /* background-color: grey; */
    h3 {
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
    }
  }

  .prodWeight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    color: white;
    font-size: 1em;
    font-weight: bold;

    ${mobile`
          justify-content: center;
          padding: 10px 0;
          `}
  }

  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: grey;
    margin-top: 10px;
    padding: 10px 0;
    font-weight: bold;
    /* border-radius: 0 0 15px 15px; */
  }

  .addNewButtonContainer {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 15px 0;
    border-radius: 15px;
    /* border-bottom: solid #fff 3px; */
    /* background-color: #420000; */
    color: #fff;
    ${mobile`
         padding: 15px 0 10px 0;
      `}
  }
`
