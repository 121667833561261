import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  getFacilitys,
  updateAboutUnit,
  updateFacilityActive,
  updateFacilityPrincipal,
  deleteFacility,
} from '../../api/unitDataAPI'
import { FacilitysListStyled } from './FacilitysListStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButtonSmall,
  GreenSubmitButtonSmall,
  SubmitButtonSmall,
} from '../../styles/elements/buttons'

import SuccesAlert from '../alert/SuccesAlert'
import ErrorAlert from '../alert/ErrorAlert'

import BackToSetings from '../../views/buttons/backToSetings/BackToSetings'
import SingleImageUploader from '../singleImageUploader/SingleImageUploader'
import FacilityAddForm from '../facilityForm/facilityAddForm/FacilityAddForm'
import TogleSwitch from '../buttons/togleSwitch/TogleSwitch'
import EditButton from '../buttons/edit/EditButton'
import DeleteButton from '../buttons/delete/DeleteButton'

const validation = {
  title: (data) => data.title.length < 10,
  description: (data) => data.description.length < 50,
}

function FacilityForm() {
  const [unitFacilitys, setUnitFacilitys] = useState([])
  const [showAddForm, setShowAddForm] = useState(null)
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getFacilitys((response) => {
      setUnitFacilitys(response)
    })
  }, [showAddForm, isLoading])

  function handleAddNew() {
    setShowAddForm(true)
  }

  function handleEdit(elID) {
    setShowAddForm(elID)
  }

  function handleDelete(elID) {
    // setShowAddForm(elID)
    setIsLoading(true)
    deleteFacility(elID, handleDeletedSuccess, handleError)
  }

  function handleDeletedSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Facilitatea a fost stearsa!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/setari'
    }, 2100)
    return () => clearTimeout(timer)
  }

  const handleActivateFacility = (id, active) => {
    // event.preventDefault()
    const newActive = active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateFacilityActive(id, newActive, handleActivateSuccess, handleError)
  }

  const handlePrincipalFacility = (id, active) => {
    // event.preventDefault()
    const newActive = active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateFacilityPrincipal(id, newActive, handleActivateSuccess, handleError)
  }

  function handleActivateSuccess(response) {
    // Update the facility's active state directly in the unitFacilitys array
    setUnitFacilitys((prevFacilities) =>
      prevFacilities.map((facility) =>
        facility.id === response.id
          ? { ...facility, active: response.active, short: response.short } // Update the active status
          : facility
      )
    )

    // Reset loading state
    setIsLoading(false)
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      {unitFacilitys ? (
        <>
          <FacilitysListStyled>
            <div className='title'>
              <h2>Facilitatile unitatii</h2>
            </div>
            <div className='readMeList'>
              <div className='infoTitle'>
                <h4>Cum lucram cu formularul.</h4>
              </div>
              <ul>
                <li>
                  <span>EDITEAZA</span> - deschide formular pentru editearae
                  facilitatii.
                </li>
                <li>
                  <span>STERGE</span> - Sterge definitiv facilitatea.
                </li>
                <li>
                  <span>ACTIVEAZA - DEZACTIVEAZA</span> - Seteaza facilitatea sa
                  fie vizibila sau nu in pagina la sectiunea facilitati.
                </li>
                <li>
                  <span>PRINCIPAL</span> - Seteaza facilitatea sa fie afisata in
                  partea de sus a pagini. Este recomandat sa <span>NU</span>{' '}
                  afisati mai mult de 4 facilitati principale. Activati cele mai
                  importane 4 facilitati ca principale.
                </li>
                <li>
                  - Daca butonul este <span>VERDE</span> facilitatea{' '}
                  <span>ESTE</span> activa pe site. <span>APASA</span> butonul
                  pentru activare.
                </li>
                <li>
                  - Daca butonul este <span>ROSU</span> facilitatea{' '}
                  <span>NU este activa</span> pe site. <span>APASA</span>{' '}
                  butonul pentru activare.
                </li>
                <li>
                  <span>
                    Mai jos aveti reprezentarea pe culori a butoanelor
                  </span>
                  <div className='listButtons'>
                    <TogleSwitch
                      checked={true}
                      label={'Activat'}
                      onChange={() => {}}
                      // readOnly
                    />
                    <TogleSwitch
                      checked={false}
                      label={'Dezactivat'}
                      onChange={() => {}}
                      // readOnly
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className='changeStatusFormContainer'>
              <SubmitButton onClick={() => handleAddNew()}>Adauga</SubmitButton>
            </div>
            <div className='container'>
              <div className='row'>
                {unitFacilitys.map((el, index) => {
                  return (
                    <div className='col-6 facility' key={index}>
                      <div className='divElement'>
                        <div className='elementData'>
                          {el.icon && (
                            <img src={el.icon} alt='' className='icon' />
                          )}
                          <span>{el.name}</span>
                        </div>
                        <div className='actionButtons'>
                          <EditButton onClick={() => handleEdit(el.id)} />
                          {/* <SubmitButtonSmall onClick={() => handleEdit(el.id)}>
                            Editează
                          </SubmitButtonSmall> */}
                          <DeleteButton
                            onClick={() => handleDelete(el.id)}
                            disabled={isLoading}
                          />
                          {/* <RedSubmitButtonSmall
                            // onClick={() => handleDelete(el.id)}
                            disabled={isLoading}
                          >
                            Sterge
                          </RedSubmitButtonSmall> */}
                          <div>
                            <TogleSwitch
                              checked={el.active === 1}
                              onChange={() =>
                                handleActivateFacility(el.id, el.active)
                              }
                              label={
                                el.active === 1 ? 'Dezactiveaza' : 'Activeaza'
                              }
                            />
                          </div>
                          {/* {el.active ? (
                            <RedSubmitButtonSmall
                              onClick={() =>
                                handleActivateFacility(el.id, el.active)
                              }
                              disabled={isLoading}
                            >
                              {isLoading ? 'Asteapta! ' : 'Dezactiveaza'}
                            </RedSubmitButtonSmall>
                          ) : (
                            <GreenSubmitButtonSmall
                              onClick={() => handleActivateFacility(el.id)}
                              disabled={isLoading}
                            >
                              {isLoading ? 'Asteapta! ' : 'Activeaza'}
                            </GreenSubmitButtonSmall>
                          )} */}
                          <div>
                            <TogleSwitch
                              checked={el.short === 1}
                              onChange={() =>
                                handlePrincipalFacility(el.id, el.short)
                              }
                              label={'Principal'}
                            />
                          </div>
                          {/* {el.short ? (
                            <RedSubmitButtonSmall>
                              Principal
                            </RedSubmitButtonSmall>
                          ) : (
                            <GreenSubmitButtonSmall>
                              Principal
                            </GreenSubmitButtonSmall>
                          )} */}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {showAddForm !== null && (
              <FacilityAddForm
                elID={showAddForm !== true ? showAddForm : null}
                cancel={() => setShowAddForm(null)}
              />
            )}
          </FacilitysListStyled>
        </>
      ) : (
        <p>Incarc date ...</p>
      )}

      {/* {unitFacilitys ? (
        <FacilitysListStyled>
          <div className='title'>
            <h2>Facilitatile unitatii</h2>
          </div>
          <div className='changeStatusFormContainer'>
            <ActivateButtonComponent
              onClick={handleActivateSection}
              disabled={isLoading}
            >
              {unitFacilitys.active === 1
                ? isLoading
                  ? 'Asteapta! Se incarca...'
                  : 'Dezactiveaza sectiunea'
                : isLoading
                ? 'Asteapta! Se incarca...'
                : 'Activeaza sectiunea'}
            </ActivateButtonComponent>
          </div>
          <GeneralFormStyled>
            <form onSubmit={handleSubmit}>
              <div className='oneField'>
                <div>
                  <FieldLabel>
                    Titlu
                    {errors.includes('title') === true ? (
                      <ErrorLabel>Titlu prea scurt!</ErrorLabel>
                    ) : formData.title.length >= 10 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 10 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='title'
                    value={formData.title}
                    onChange={handleChange}
                    placeholder='Titlu'
                  />
                </div>
              </div>
              <div className='oneField'>
                <div>
                  <FieldLabel>
                    Descriere unitate
                    {errors.includes('description') === true ? (
                      <ErrorLabel>Descrierea este prea scurta!</ErrorLabel>
                    ) : formData.description.length >= 50 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <textarea
                    type='text'
                    name='description'
                    value={formData.description}
                    onChange={handleChange}
                    placeholder='Descriere unitate'
                  />
                </div>
              </div>
              <div className='aboutImage'>
                <img src={unitFacilitys.image} alt='' />
              </div>
              <div className='submitContainer'>
                <SubmitButton disabled={isLoading}>
                  {isLoading
                    ? 'Asteapta! Se incarca...'
                    : !unitFacilitys.title
                    ? 'Salveaza'
                    : 'Modifica datele'}
                </SubmitButton>
              </div>
            </form>
          </GeneralFormStyled>
          {unitFacilitys.id && (
            <div className='changeImageButtonFormContainer'>
              Nu este obilgatoriu sa incarcati o imagine dar poate avea un
              impact placut pentru utilizatori.
              <RedSubmitButton
                onClick={() => {
                  setShowChangeAboutImage(
                    showChangeAboutImage === unitFacilitys.id
                      ? null
                      : unitFacilitys.id
                  )
                }}
              >
                {unitFacilitys.image ? 'Schimba imaginea' : 'Adauga imagine'}
              </RedSubmitButton>
              {showChangeAboutImage === unitFacilitys.id ? (
                <SingleImageUploader
                  apiName={'updateAboutUnitPhoto'}
                  id={unitFacilitys.id}
                  formTitle={
                    unitFacilitys.image ? 'Schimba imaginea' : 'Adauga imagine'
                  }
                  oldImage={unitFacilitys.image}
                  uploadFolder={'about'}
                  fileType={''}
                  cancel={() => setShowChangeAboutImage(null)}
                  backUrl={'setari'}
                />
              ) : (
                (document.body.style.overflow = '') // Re-enable body scroll
              )}
            </div>
          )}
        </FacilitysListStyled>
      ) : (
        // <FadeLoading />
        <p>Incarc datele</p>
      )} */}
    </>
  )
}

export default FacilityForm
