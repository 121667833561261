import styled, { keyframes } from 'styled-components'

// Define the keyframes for the animation
const smokeOut = keyframes`
  100% {
    opacity: 0.08;
    filter: blur(5px);
    letter-spacing: 4px;
  }
`

// Create a styled div with the styles and animation applied
export const FadeLoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Changed from relative to fixed */
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #00000090;

  font-size: 48px;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  animation: ${smokeOut} 1s ease-in-out infinite alternate;
  text-shadow: 0 0 1px white;

  &::before {
    content: 'Se incarca';
  }
`
