import React, { useState, useEffect } from 'react'
import { AdminDashboardStyled } from './AdminDashboardStyled'
import AdminData from '../../views/adminDashboard/adminData/AdminData'
import AllOrdersHistory from '../../views/adminDashboard/orderHistory/AllOrdersHistory'
import capitalizeFirstLetter from '../../functions/capinalizeFirstLetter'
import AdminGreeting from '../../views/adminDashboard/adminGreeting/AdminGreeting'
import TodayOrdersHistory from '../../views/adminDashboard/orderHistory/todayOrders/TodayOrdersHistory'
import AdminDashboardMenu from '../../views/adminDashboard/adminDasboardMenu/AdminDashboardMenu'

function AdminDashboard() {
  return (
    <>
      <AdminDashboardStyled>
        <AdminGreeting />
        <AdminDashboardMenu />
        {/* <AdminData /> */}
        {/* <AllOrdersHistory /> */}
        {/* <TodayOrdersHistory /> */}
      </AdminDashboardStyled>
    </>
  )
}

export default AdminDashboard
