import React, { useRef } from 'react'
import { HorizontalScrollingImagesStyled } from './HorizontalScrollingImagesStyled'

const HorizontalScrollingImages = ({ items, onElementClick }) => {
  const scrollContainerRef = useRef(null)

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current
    const scrollAmount = direction === 'left' ? -230 : 230
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
  }

  const handleItemClick = (index, link) => {
    const container = scrollContainerRef.current
    const nextIndex = (index + 1) % items.length // Get the next index, looping back to the first

    // Calculate the next item's position and scroll horizontally
    // const nextItem = container.children[nextIndex]
    // const itemWidth = nextItem.offsetWidth
    // const scrollLeft = nextItem.offsetLeft - container.scrollLeft

    // container.scrollBy({ left: scrollLeft, behavior: 'smooth' })

    // Calculate the next item's position and scroll horizontally
    const nextItem = container.children[nextIndex]
    const scrollLeft = nextItem.offsetLeft - (container.scrollLeft + 75)

    container.scrollBy({ left: scrollLeft, behavior: 'smooth' })

    // Trigger the external callback function
    if (onElementClick) {
      onElementClick(index, link)
    }
  }

  return (
    <HorizontalScrollingImagesStyled>
      <button
        className='scroll-button left'
        onClick={() => handleScroll('left')}
      >
        {'<'}
      </button>
      <div className='scrolling-menu' ref={scrollContainerRef}>
        {items.map((el, index) => (
          <div
            className='menu-item'
            key={index}
            onClick={() => handleItemClick(index, el.image_link)}
            style={{ cursor: 'pointer' }}
          >
            {el.image_link && <img src={el.image_link} alt='' />}
          </div>
        ))}
      </div>
      <button
        className='scroll-button right'
        onClick={() => handleScroll('right')}
      >
        {'>'}
      </button>
    </HorizontalScrollingImagesStyled>
  )
}

export default HorizontalScrollingImages

// usage in parent
// import { useNavigate } from 'react-router-dom'
// const [selectedElement, setSelectedElement] = useState(0) // Default to the first category
// const navigate = useNavigate()
// const [photo, setPhoto] = useState('')
// const [isModalOpen, setIsModalOpen] = useState(false)
// const [currentIndex, setCurrentIndex] = useState(0)

//  const handleElementClick = (index, elementLink) => {
//    setPhoto(elementLink)
//    setCurrentIndex(index)
//  }

{
  /* <img
    src={photo}
    alt='Main product'
    className='bigImg'
    onClick={() => openModal(currentIndex)}
  />
  <HorizontalScrollingImages
    items={product.product_images} // the array of objects
    onElementClick={handleElementClick}
  /> */
}
