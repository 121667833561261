import React, { useState, useEffect } from 'react'
import { TimeDifferenceStyled } from './TimeDifferenceStyled'

function TimeDifference({ storedTime }) {
  const [timeDifference, setTimeDifference] = useState('')

  useEffect(() => {
    const calculateTimeDifference = () => {
      const [storedHours, storedMinutes] = storedTime.split(':').map(Number)
      const storedDate = new Date()
      storedDate.setHours(storedHours, storedMinutes, 0, 0)

      const currentDate = new Date()
      const diffMs = currentDate - storedDate // Difference in milliseconds

      const diffHours = Math.floor(diffMs / (1000 * 60 * 60))
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60))

      // Handle negative difference by resetting to 0
      const formattedHours = Math.max(diffHours, 0)
      const formattedMinutes = Math.max(diffMinutes, 0)

      setTimeDifference(
        `${
          formattedHours > 1
            ? formattedHours + ' ore si '
            : formattedHours === 1
            ? formattedHours + ' ora si '
            : ''
        } ${
          formattedMinutes < 2
            ? formattedMinutes + ' minut'
            : formattedMinutes + ' minute'
        }`
      )
    }

    // Initial calculation
    calculateTimeDifference()

    // Update every second
    const intervalId = setInterval(calculateTimeDifference, 1000)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId)
  }, [storedTime])

  return <TimeDifferenceStyled>{timeDifference}</TimeDifferenceStyled>
}

export default TimeDifference
