import styled from 'styled-components'

export const TogleCheckBoxYNStyled = styled.label`
  --white: #fff;
  --blue: #0d7eff;
  --red: #f00;
  --g08: #e1e5eb;
  --g04: #848ea1;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 5px;
  }
  .tgl {
    display: none;
  }

  .tgl,
  .tgl:after,
  .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
    box-sizing: border-box;
  }

  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 57px;
    height: 27px;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background: var(--red);
    border-radius: 20rem;
    padding: 2px;
    transition: all 0.4s ease;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    z-index: 0;
  }

  .tgl-btn:after,
  .tgl-btn:before {
    position: relative;
    display: block;
    content: '';
    width: 44%;
    height: 100%;
  }

  .tgl-btn:after {
    left: 0;
    border-radius: 2em;
    background: #fff;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .tgl-btn:before {
    display: inline;
    position: absolute;
    top: 9px;
    content: 'NU';
    left: 28px;
    color: var(--white);
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .tgl-ios:checked + .tgl-btn:after {
    left: 56.5%;
  }

  .tgl-ios:checked + .tgl-btn {
    background: var(--blue);
  }

  .tgl-ios:checked + .tgl-btn:before {
    content: 'DA';
    left: 8px;
    color: #fff;
  }

  .tgl-ios + .tgl-btn:active {
    box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
  }

  .tgl-ios:checked + .tgl-btn:active {
    box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
  }
`
