import React from 'react'
import { EditButtonStyled } from './EditButtonStyled'

function EditButton({ onClick }) {
  return (
    <>
      <EditButtonStyled onClick={onClick}>
        <img
          src='https://www.admin.aroma-pasiunii.intorsura.ro/media/icons/edit-blue.png'
          alt='Editeaza'
        />
      </EditButtonStyled>
    </>
  )
}

export default EditButton
