import React, { useState, useEffect } from 'react'
import { ErrorDysplayStyled } from './AlertStyled'

const ErrorDisplay = ({ message }) => {
  return (
    <ErrorDysplayStyled>
      <div className='errorMsg'>{message}</div>
    </ErrorDysplayStyled>
  )
}

export default ErrorDisplay
