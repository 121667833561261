import React, { useEffect, useState } from 'react'
import { TogleCheckBoxYNStyled } from './TogleCheckBoxYNStyled'

function TogleCheckBoxYN({
  label,
  inputId,
  inputName,
  checked: initialCheck,
  onChange,
}) {
  const [checked, setChecked] = useState(initialCheck)

  useEffect(() => {
    setChecked(initialCheck) // Update state when initialCheck changes
  }, [initialCheck])

  const handleChange = () => {
    const newChecked = !checked
    setChecked(newChecked)
    onChange(newChecked ? 1 : 0) // Pass 1 if checked, else 0
  }

  return (
    <TogleCheckBoxYNStyled>
      <span>{label}</span>
      <input
        className='tgl tgl-ios'
        id={inputId}
        type='checkbox'
        checked={checked}
        onChange={handleChange}
        name={inputName}
      />
      <label className='tgl-btn' htmlFor={inputId}></label>
    </TogleCheckBoxYNStyled>
  )
}

export default TogleCheckBoxYN

// --- example of usage ---
// <TogleCheckBoxYN
//   label={'Produse congelate'}
//   inputId={'frozen_products'}
//   inputName={'frozen_products'}
//   checked={formData.frozen_products === 1}
//   onChange={(value) =>
//     setFormData({ ...formData, frozen_products: value })
//   }
// />

// -------

// import React, { useEffect, useState } from 'react'
// import { TogleCheckBoxYNStyled } from './TogleCheckBoxYNStyled'

// function TogleCheckBoxYN({ label, inputId, inputName, checked: initialCheck }) {
//   const [checked, setChecked] = useState(initialCheck)

//   useEffect(() => {
//     setChecked(initialCheck) // Update state when initialCheck changes
//   }, [initialCheck])

//   const handleChange = () => {
//     setChecked(!checked) // Toggle the checked value
//   }

//   return (
//     <TogleCheckBoxYNStyled>
//       <span>{label}</span>
//       <input
//         className='tgl tgl-ios'
//         id={inputId}
//         type='checkbox'
//         checked={checked}
//         onChange={handleChange}
//         name={inputName}
//       />
//       <label className='tgl-btn' htmlFor={inputId}></label>
//     </TogleCheckBoxYNStyled>
//   )
// }

// export default TogleCheckBoxYN
