import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { getProduct } from '../../api/shop'
import { ProductStyled } from './ProductStyled'
import AddToCartLocalStorage from '../../components/addToCartLocalStorage/AddToCartLocalStorage'
import CarouselAutoSliderFullScreen from '../../components/carouselAutoSliderFullScreen/CarouselAutoSliderFullScreen'
import HorizontalScrollingImages from '../../components/horizontalScrollingImages/HorizontalScrollingImages'

function Product({ inCartProducts }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [product, setProduct] = useState(null)
  const [photo, setPhoto] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  const [selectedElement, setSelectedElement] = useState(0) // Default to the first category

  useEffect(() => {
    getProduct(id, (response) => {
      setProduct(response)
      setPhoto(response.product_images[0].image_link)
    })
  }, [id])

  // Open modal and set current index for the image clicked
  const openModal = (index) => {
    setCurrentIndex(index)
    setIsModalOpen(true)
  }

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false)
  }

  // // Handle next and previous buttons
  // const showNextImage = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex === product.product_images.length - 1 ? 0 : prevIndex + 1
  //   )
  // }

  // const showPrevImage = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex === 0 ? product.product_images.length - 1 : prevIndex - 1
  //   )
  // }

  const handleElementClick = (index, elementLink) => {
    setPhoto(elementLink)
    setCurrentIndex(index)
  }

  return (
    <>
      {product && (
        <>
          <ProductStyled>
            <div className='shortNavContainer'>
              <Link to='/' className='shortNav'>
                HOME
              </Link>{' '}
              /{' '}
              <Link to='/meniu' className='shortNav'>
                Meniu
              </Link>{' '}
              /{' '}
              <Link
                to={`/categories/${product.product_category_link}`}
                className='shortNav'
              >
                {product.product_category_link}
              </Link>
            </div>
            <div className='container'>
              <div className='row productContainer'>
                <div className='col-12'>
                  <div className='row productDetailsImg'>
                    <div className='productName'>
                      <h1>{product.name}</h1>
                    </div>

                    {product.product_images.length === 0 ? (
                      <div className='col-6 foto'>
                        <img
                          src='https://www.aroma-pasiunii.intorsura.ro/media/aroma-pasiunii-no-photo.png'
                          alt=''
                          className='bigImg'
                        />
                      </div>
                    ) : (
                      <div className='col-6 foto'>
                        <img
                          src={photo}
                          alt={product.name}
                          className='bigImg'
                          onClick={() => openModal(currentIndex)}
                        />
                        <HorizontalScrollingImages
                          items={product.product_images}
                          onElementClick={handleElementClick}
                        />
                        <div className='thumbnails'>
                          {/* {product.product_images.map((el, index) => (
                            <img
                              key={index}
                              src={el.image_link}
                              alt='Thumbnail'
                              className='thumbnailImg'
                              onClick={() => {
                                setPhoto(el.image_link)
                                setCurrentIndex(index)
                              }}
                              style={{
                                transform: `translateX(+100%)`,
                              }}
                            />
                          ))} */}
                        </div>
                      </div>
                    )}

                    <div className='col-6 productData'>
                      <div className='price'>
                        {product.price} {product.currency.currency_short}
                      </div>
                      <AddToCartLocalStorage
                        id={product.id}
                        eanCode={product.ean_code}
                        productName={product.name}
                        addToCartStyle='singleProduct'
                        inCartProducts={inCartProducts}
                      />
                      <div>extra</div>
                    </div>
                  </div>
                  <div className='row productDetails'>
                    <div className='col-12'>
                      <div className='prodWeight'>
                        Portie: {product.weight}
                        {product.weight_classes.name_short}
                      </div>
                      {product.ingredients && (
                        <div className='ingredients'>
                          <span>Ingrediente:</span> {product.ingredients}
                        </div>
                      )}
                      {product.allergens && (
                        <div className='allergens'>
                          <span>Allergeni:</span> {product.allergens}
                        </div>
                      )}
                      {product.nutritional_declaration && (
                        <div className='nutritionalDeclaration'>
                          <span>Declaratie nutritionala:</span>{' '}
                          {product.nutritional_declaration}
                        </div>
                      )}
                      {product.energetic_value && (
                        <div className='energeticValue'>
                          <span>Valoare energetica:</span>{' '}
                          {product.energetic_value} kcal / 100 {product.units}
                        </div>
                      )}
                      {product.frozen_products === 1 && (
                        <div className='frozenProduct'>
                          Contine produse congelata
                        </div>
                      )}
                      {product.aditivi === 1 && (
                        <div className='frozenProduct'>Contine aditivi</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Modal for Fullscreen Image Carousel */}
            {/* {isModalOpen && (
              <div className='carouselModal'>
                <button className='closeButton' onClick={closeModal}>
                  ×
                </button>
                <button className='prevButton' onClick={showPrevImage}>
                  ‹
                </button>
                <img
                  src={product.product_images[currentIndex].image_link}
                  alt='Fullscreen'
                  className='bigImgFullScreen'
                />
                <button className='nextButton' onClick={showNextImage}>
                  ›
                </button>
              </div>
            )} */}
            {isModalOpen && (
              <div className='carouselModal'>
                <button className='closeButton' onClick={closeModal}>
                  ×
                </button>
                <CarouselAutoSliderFullScreen elIndex={currentIndex}>
                  {product.product_images.map((image, index) => {
                    return (
                      <img
                        key={index}
                        src={image.image_link}
                        alt={product.name}
                        onClick={() => openModal(currentIndex)}
                      />
                    )
                  })}
                </CarouselAutoSliderFullScreen>
              </div>
            )}
          </ProductStyled>
        </>
      )}
    </>
  )
}

export default Product

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { useParams, useNavigate } from 'react-router';
// import { getProduct } from '../../api/shop';
// import { ProductStyled } from './ProductStyled';
// import AddToCartLocalStorage from '../../components/addToCartLocalStorage/AddToCartLocalStorage';

// function BigImg({ fotoUrl }) {
//   return <img src={fotoUrl} alt='photo' className='bigImg' />;
// }

// function handleOnClickBigImage({ fotoUrl }) {
//   return <img src={fotoUrl} alt='photo' className='bigImgFullScreen' />;
// }

// function Product({ inCartProducts }) {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [product, setProduct] = useState(null);
//   const [photo, setPhoto] = useState('');
//   const addToCartStyle = 'singleProduct';

//   useEffect(() => {
//     getProduct(id, (response) => {
//       setProduct(response);
//       setPhoto(response.product_images[0].image_link);
//     });
//   }, [id]);

//   return (
//     <>
//       {product && (
//         <>
//           <ProductStyled>
//             <Link to='/' className='shortNav'>
//               HOME
//             </Link>{' '}
//             /
//             <Link to='/meniu' className='shortNav'>
//               Meniu
//             </Link>{' '}
//             /
//             <Link
//               to={`/categories/${product.product_category_link}`}
//               className='shortNav'
//             >
//               {product.product_category_link}
//             </Link>
//             <div className='container'>
//               <div className='row productContainer'>
//                 <div className='col-12'>
//                   <div className='row productDetailsImg'>
//                     {product.product_images[0].image_link && (
//                       <div className='col-6 foto'>
//                         <BigImg
//                           fotoUrl={photo}
//                           onClick={handleOnClickBigImage}
//                         />
//                         <div className='tumbnails'>
//                           {product.product_images.map((el, index) => (
//                             <img
//                               key={index}
//                               src={el.image_link}
//                               alt='img'
//                               className='tumbnailImg'
//                               onClick={() => setPhoto(el.image_link)}
//                             />
//                           ))}
//                         </div>
//                       </div>
//                     )}
//                     <div className='col-6 productData'>
//                       <div className='productName'>
//                         <h1>{product.name}</h1>
//                       </div>
//                       <div className='prodWeight'>
//                         Portie: {product.weight}
//                         {product.weight_classes.name_short}
//                       </div>
//                       <div className='price'>
//                         {product.price} {product.currency.currency_short}
//                       </div>
//                       <AddToCartLocalStorage
//                         id={product.id}
//                         eanCode={product.ean_code}
//                         productName={product.name}
//                         addToCartStyle={addToCartStyle}
//                         inCartProducts={inCartProducts}
//                       />
//                     </div>
//                   </div>
//                   <div className='row productDetails'>
//                     <div className='col-12 '>
//                       {product.ingredients && (
//                         <div className='ingredients'>
//                           <span>Ingrediente: </span> {product.ingredients}
//                         </div>
//                       )}
//                       {product.allergens && (
//                         <div className='allergens'>
//                           <span>Alergeni: </span> {product.allergens}
//                         </div>
//                       )}
//                       {product.nutritional_declaration && (
//                         <div className='nutritionalDeclaration'>
//                           <span>Declaratie nutritionala: </span>
//                           {product.nutritional_declaration}
//                         </div>
//                       )}
//                       {product.energetic_value && (
//                         <div className='energeticValue'>
//                           <span>Valoare energetica: </span>
//                           {product.energetic_value} kcal / 100 {product.units}
//                         </div>
//                       )}
//                       {product.frozen_products === 1 && (
//                         <div className='frozenProduct'>
//                           Contine produse congelate
//                         </div>
//                       )}
//                       {product.aditivi === 1 && (
//                         <div className='frozenProduct'>Contine aditivi</div>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </ProductStyled>
//         </>
//       )}
//     </>
//   );
// }

// export default Product;
