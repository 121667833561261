import React from 'react'
import { ViewButtonStyled } from './ViewButtonStyled'

function ViewButton({ onClick }) {
  return (
    <>
      <ViewButtonStyled onClick={onClick}>
        <img
          src='https://www.admin.aroma-pasiunii.intorsura.ro/media/icons/view-blue.png'
          alt='Vizualizeaza'
        />
      </ViewButtonStyled>
    </>
  )
}

export default ViewButton
