import React, { useEffect, useState } from 'react'
import capitalizeFirstLetter from '../../../functions/capinalizeFirstLetter'
import { CustomerDataStyled } from './CustomerDataStyled'
import ModifyCustomerData from '../modifyCustomerData/ModifyCustomerData'
import ModifyUsername from '../modifyUsername/ModifyUsername'
import ModifyEmail from '../modifyEmail/ModifyEmail'
import ChangePassword from '../changePassword/ChangePassword'
import { getSCustomerById } from '../../../api/unitDataAPI'

function CustomerData({ elID, cancel }) {
  const [customerData, setCustomerData] = useState({})
  const [showModifyCustomerDataForm, setShowModifyCustomerDataForm] =
    useState(false)
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false)
  const [showChangeUsernameForm, setShowChangeUsernameForm] = useState(false)
  const [showChangeEmailForm, setShowChangeEmailForm] = useState(false)

  useEffect(() => {
    getSCustomerById(elID, (response) => {
      setCustomerData(response)
    })
    // const customerData = JSON.parse(localStorage.getItem('userData'))
    // if (customerData) {
    //   setCustomerData(customerData)
    // }
  }, [showModifyCustomerDataForm])

  return (
    <>
      <CustomerDataStyled>
        <div className='col-12 artTitle'>
          <h2>Datele clientului</h2>
        </div>

        <div className='customerDetailsList'>
          <div className='customerImage'>
            <img
              src='https://www.aroma-pasiunii.intorsura.ro/media/icons/userAvatar.png'
              alt='Aroma Pasiunii'
            />
          </div>
          <div className='customerData'>
            <div className='customerName'>
              <span>Nume: </span>
              <h3>
                {capitalizeFirstLetter(customerData.first_name)}{' '}
                {capitalizeFirstLetter(customerData.last_name)}
              </h3>
            </div>
            <div className='customerDetails'>
              <span>Utilizator:</span> {customerData.username}
            </div>
            <div className='customerDetails'>
              <span>Email:</span> {customerData.email}
            </div>
            <div className='customerDetails'>
              <span>Telefon:</span> {customerData.phone}
            </div>
            <div className='customerDetails'>
              <span>Adresa:</span> {customerData.city}
              {customerData.street && ', Strada: ' + customerData.street}
              {customerData.street_number &&
                ', Nr.: ' + customerData.street_number}
              {customerData.building && ', Bloc: ' + customerData.building}
              {customerData.staircase && ', Sc.: ' + customerData.staircase}
              {customerData.apartment && ', Ap.: ' + customerData.apartment}
            </div>

            {customerData.intercom && (
              <div>
                <span>Nr. interfon.:</span>
                {customerData.intercom}
              </div>
            )}
          </div>
          <div className='customerButtons'>
            <button
              className='modifyData'
              onClick={() => {
                setShowModifyCustomerDataForm(true)
                setShowChangePasswordForm(false)
                setShowChangeUsernameForm(false)
                setShowChangeEmailForm(false)
              }}
            >
              Modifica date
            </button>
            <button
              className='modifyData'
              onClick={() => {
                setShowChangeUsernameForm(true)
                setShowModifyCustomerDataForm(false)
                setShowChangePasswordForm(false)
                setShowChangeEmailForm(false)
              }}
            >
              Modifica nume utilizator
            </button>
            <button
              className='modifyData'
              onClick={() => {
                setShowChangeEmailForm(true)
                setShowModifyCustomerDataForm(false)
                setShowChangePasswordForm(false)
                setShowChangeUsernameForm(false)
              }}
            >
              Modifica E-Mail
            </button>
            <button
              className='passwordReset'
              onClick={() => {
                setShowChangePasswordForm(true)
                setShowModifyCustomerDataForm(false)
                setShowChangeUsernameForm(false)
                setShowChangeEmailForm(false)
              }}
            >
              Schimba parola
            </button>
          </div>
        </div>
        {showModifyCustomerDataForm && (
          <ModifyCustomerData
            cancel={() => setShowModifyCustomerDataForm(false)}
            elID={elID}
          />
        )}
        {showChangeUsernameForm && (
          <ModifyUsername
            cancel={() => setShowChangeUsernameForm(false)}
            elID={elID}
          />
        )}
        {showChangeEmailForm && (
          <ModifyEmail
            cancel={() => setShowChangeEmailForm(false)}
            elID={elID}
          />
        )}
        {showChangePasswordForm && (
          <ChangePassword
            cancel={() => setShowChangePasswordForm(false)}
            elID={elID}
          />
        )}
      </CustomerDataStyled>
    </>
  )
}

export default CustomerData
