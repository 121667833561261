import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CategoriesMenuStyled } from './CategoriesMenuStyled'
import { getCategories } from '../../api/shop'
import Carousel from '../../components/carousel/Carousel'
import HorizontalScrollingMenu from '../../components/horizontalScrollingMenu/HorizontalScrollingMenu'

function CategoriesMenu() {
  const [categories, setCategories] = useState([])
  const [selectedElement, setSelectedElement] = useState(0) // Default to the first category
  const navigate = useNavigate()

  useEffect(() => {
    getCategories((response) => {
      setCategories(response)
    })
  }, [])

  const handleElementClick = (index, categoryLink) => {
    setSelectedElement(index)
    navigate(`/categories/${categoryLink}`)
  }

  return (
    <CategoriesMenuStyled>
      <div className='catTitle'>
        <h2>Categories</h2>
      </div>
      <HorizontalScrollingMenu
        items={categories}
        onElementClick={handleElementClick}
      />
      {/* Carousel with data and click handler */}
      {/* <Carousel
        elements={categories}
        selectedElementIndex={selectedElement}
        itemsPerView={2}
        slideWidth={100} // Adjust as per your design
        onElementClick={handleElementClick}
      /> */}
    </CategoriesMenuStyled>
  )
}

export default CategoriesMenu

// import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { CategoriesMenuStyled } from './CategoriesMenuStyled'
// import { getCategories } from '../../api/shop'
// import Carousel from '../../components/carousel/Carousel'

// function CategoriesMenu() {
//   const [categories, setCategories] = useState([])
//   const [selectedCategory, setSelectedCategory] = useState(null)
//   const navigate = useNavigate()

//   useEffect(() => {
//     getCategories((response) => {
//       setCategories(response)
//     })
//   }, [])

//   return (
//     <>
//       <CategoriesMenuStyled>
//         <div className='catTitle'>
//           <h2>Categories</h2>
//         </div>

//         <div className='categoryList'>
// <Carousel/>

//           {categories.map((el, index) => (
//             <button
//               key={index}
//               onClick={(e) => {
//                 e.preventDefault()
//                 setSelectedCategory(index) // Set the selected category
//                 navigate(`/categories/${el.category_link}`)
//               }}
//             >
//               <div className='categoryName'>
//                 <h2>{el.name}</h2>
//               </div>
//               {el.image && (
//                 <div className='imageContainer'>
//                   <img src={el.image} alt={el.name} />
//                 </div>
//               )}
//             </button>
//           ))}
//         </div>

//         {/* {selectedCategory !== null && ( */}
//         {/* <Carousel
//           elements={categories}
//           selectedCategoryIndex={selectedCategory}
//           itemsPerView={4}
//           slideWidth={120}
//         /> */}
//         {/* )} */}
//       </CategoriesMenuStyled>
//     </>
//   )
// }

// export default CategoriesMenu

// ------------------------------

// import React, { useEffect, useState } from 'react'
// import { Link, useNavigate } from 'react-router-dom'
// import { CategoriesMenuStyled } from './CategoriesMenuStyled'
// import { getCategories } from '../../api/shop'

// function CategoriesMenu() {
//   const [categories, setCategories] = useState([])
//   const navigate = useNavigate()

//   useEffect(() => {
//     getCategories((response) => {
//       setCategories(response)
//     })
//   }, [])

//   return (
//     <>
//       <CategoriesMenuStyled>
//         <div className='catTitle'>
//           <h2>Categories</h2>
//         </div>

//         <div className='categoryList'>
//           {categories.map((el, index) => {
//             return (
//               <React.Fragment key={index}>
//                 <button
//                   href='#'
//                   onClick={(e) => {
//                     e.preventDefault()
//                     navigate(`/categories/${el.category_link}`)
//                   }}
//                 >
//                   <div className='categoryName'>
//                     <h2>{el.name}</h2>
//                   </div>
//                   {el.image && (
//                     <div className='imageContainer'>
//                       <img src={el.image} alt={el.name} />
//                     </div>
//                   )}
//                 </button>
//               </React.Fragment>
//               // <div className='tumbnailCategory' key={index}>
//               //   <a
//               //     href='#'
//               //     onClick={(e) => {
//               //       e.preventDefault()
//               //       navigate(`/categories/${el.category_link}`)
//               //     }}
//               //   >
//               //     <div className='categoryName'>
//               //       <h2>{el.name}</h2>
//               //     </div>
//               //     {el.image && (
//               //       <div className='tumbnailImg'>
//               //         <img src={el.image} alt={el.name} />
//               //       </div>
//               //     )}
//               //   </a>
//               // </div>
//             )
//           })}
//         </div>
//       </CategoriesMenuStyled>
//     </>
//   )
// }
// export default CategoriesMenu
