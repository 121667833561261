import styled, { keyframes } from 'styled-components'

const animloader = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`

export const FillWithColorLoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: 0; */
  /* left: 0; */
  height: 100%;
  width: 100%;
  background-color: #00000090;
  position: fixed;

  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #f00;
  letter-spacing: 2px;
  /* position: relative; */

  &::after {
    content: 'Loading';
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    animation: ${animloader} 2s ease-in infinite;
  }
`
