// import React, { useRef } from 'react'
// import { HorizontalScrollingMenuStyled } from './HorizontalScrollingMenuStyled'

// const HorizontalScrollingMenu = ({ items, onElementClick }) => {
//   const scrollContainerRef = useRef(null)

//   const handleScroll = (direction) => {
//     const container = scrollContainerRef.current
//     const scrollAmount = direction === 'left' ? -160 : 160
//     container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
//   }

//   const handleItemClick = (index, link) => {
//     const container = scrollContainerRef.current
//     const nextIndex = (index + 1) % items.length // Get the next index, looping back to the first
//     const nextItem = container.children[nextIndex]
//     nextItem.scrollIntoView({ behavior: 'smooth', inline: 'center' })

//     // Trigger the external callback function
//     if (onElementClick) {
//       onElementClick(index, link)
//     }
//   }

//   return (
//     <HorizontalScrollingMenuStyled>
//       <button
//         className='scroll-button left'
//         onClick={() => handleScroll('left')}
//       >
//         {'<'}
//       </button>
//       <div className='scrolling-menu' ref={scrollContainerRef}>
//         {items.map((el, index) => (
//           <div
//             className='menu-item'
//             key={index}
//             onClick={() => handleItemClick(index, el.category_link)}
//             style={{ cursor: 'pointer' }}
//           >
//             {el.name}
//           </div>
//         ))}
//       </div>
//       <button
//         className='scroll-button right'
//         onClick={() => handleScroll('right')}
//       >
//         {'>'}
//       </button>
//     </HorizontalScrollingMenuStyled>
//   )
// }

// export default HorizontalScrollingMenu

// ------------------
import React, { useRef } from 'react'
import { HorizontalScrollingMenuStyled } from './HorizontalScrollingMenuStyled'

const HorizontalScrollingMenu = ({ items, onElementClick }) => {
  const scrollContainerRef = useRef(null)

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current
    const scrollAmount = direction === 'left' ? -135 : 135
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
  }

  const handleItemClick = (index, link) => {
    const container = scrollContainerRef.current
    const nextIndex = (index + 1) % items.length // Get the next index, looping back to the first

    // Calculate the next item's position and scroll horizontally
    // const nextItem = container.children[nextIndex]
    // const itemWidth = nextItem.offsetWidth
    // const scrollLeft = nextItem.offsetLeft - container.scrollLeft

    // container.scrollBy({ left: scrollLeft, behavior: 'smooth' })

    // Calculate the next item's position and scroll horizontally
    const nextItem = container.children[nextIndex]
    const scrollLeft = nextItem.offsetLeft - (container.scrollLeft + 75)

    container.scrollBy({ left: scrollLeft, behavior: 'smooth' })

    // Trigger the external callback function
    if (onElementClick) {
      onElementClick(index, link)
    }
  }

  return (
    <HorizontalScrollingMenuStyled>
      <button
        className='scroll-button left'
        onClick={() => handleScroll('left')}
      >
        {'<'}
      </button>
      <div className='scrolling-menu' ref={scrollContainerRef}>
        {items.map((el, index) => (
          <div
            className='menu-item'
            key={index}
            onClick={() => handleItemClick(index, el.category_link)}
            style={{ cursor: 'pointer' }}
          >
            {/* {el.name}
            {el.image && (
              <img src={el.image} alt={el.name} style={{ maxWidth: '100%' }} />
            )} */}
            <div className='itemName'>
              <h2>{el.name}</h2>
            </div>
            {el.image && <img src={el.image} alt={el.name} />}
          </div>
        ))}
      </div>
      <button
        className='scroll-button right'
        onClick={() => handleScroll('right')}
      >
        {'>'}
      </button>
    </HorizontalScrollingMenuStyled>
  )
}

export default HorizontalScrollingMenu

// usage in parent
// import { useNavigate } from 'react-router-dom'
// const [selectedElement, setSelectedElement] = useState(0) // Default to the first category
// const navigate = useNavigate()

// const handleElementClick = (index, categoryLink) => {
//   setSelectedElement(index)
//   navigate(`/categories/${categoryLink}`)
// }

// <HorizontalScrollingMenu
//   items={categories}
//   onElementClick={handleElementClick}
// />
