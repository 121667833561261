import styled from 'styled-components'

export const ViewButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #e9e9e9;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background-color: #fff;

    /* opacity: 0.8;
    filter: alpha(opacity=80); */
  }
  img {
    width: 38px;
    height: 38px;
    margin-bottom: 1px;
    margin-left: 1px;
    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }
`
