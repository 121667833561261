import React, { useState, useEffect } from 'react'
import { CustomerDashboardStyled } from './CustomerDashboardStyled'
import CustomerData from './customerData/CustomerData'
import OrderHistory from './orderHistory/OrderHistory'
import capitalizeFirstLetter from '../../functions/capinalizeFirstLetter'
import CustomerGreeting from './customerGreeting/CustomerGreeting'
import { PopUpLevel1Styled } from '../../components/popUpWindows/PopUpLevel1Styled'

function CustomerDashboard({ elID, cancel, currentPage }) {
  document.body.style.overflow = 'hidden'

  console.log('pagina de la care plec' + currentPage)

  return (
    <>
      <CustomerDashboardStyled>
        <PopUpLevel1Styled>
          <div className='popUpLevel1Container'>
            <div className='cancelButtonContainer'>
              <button
                className='closeButton'
                onClick={() => {
                  // setIsOpen(false)

                  setTimeout(cancel, 300) // Delay cancel to allow fade-out
                  document.body.style.overflow = '' // Re-enable body scroll
                  // window.location.reload()
                  // window.location.href = window.location.href
                  // navigate(`/${customersUrl}`, { state: { elID } })
                }}
              >
                Renunta
              </button>
            </div>
            {/* <CustomerGreeting /> */}
            <CustomerData elID={elID} cancel={cancel} />
            <OrderHistory elID={elID} />
          </div>
        </PopUpLevel1Styled>
      </CustomerDashboardStyled>
    </>
  )
}

export default CustomerDashboard
