import styled from 'styled-components';

// Styled component for the left arrow link
export const BackToSetingsStyled = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 5px;
  /* background-color: grey; */
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    background-color: #007bff3e;
    transition: background-color 0.6s;
    span {
      margin-right: 10px;
    }

    &:hover {
      background-color: #007bff; // Hover color for the link
    }
  }
`;
