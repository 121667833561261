import React from 'react'
import Login from '../login/Login'

function Start({ onLogin }) {
  return (
    <>
      <Login onLogin={onLogin} />
    </>
  )
}
export default Start
