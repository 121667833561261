import { apiRest } from './config'

function getAllOrders(
  orderBy,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/orders?orderby=${orderBy}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getAllPaginatedOrders(
  page,
  orderBy,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/paginated-orders?page=${page}&orderby=${orderBy}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getTodayOrders(
  // cusotmerId,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/today-orders`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// function getOrders(
function getOrdersByCustomerID(
  cusotmerId,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/customer-orders/${cusotmerId}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// get the order by id
function getOrder(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/order/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// change the order status by order_id
function changeOrderStatusByOrderID(
  id,
  orderStatus,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/order/${id}/${orderStatus}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

export {
  getAllOrders,
  getTodayOrders,
  getAllPaginatedOrders,
  getOrdersByCustomerID,
  getOrder,
  changeOrderStatusByOrderID,
}
