import React, { useEffect, useState } from 'react'
import { SingleImageUploaderStyled } from './SingleImageUploaderStyled'
import { ErrorDysplayStyled } from '../alert/AlertStyled'
import SuccesAlert from '../alert/SuccesAlert'
import ErrorAlert from '../alert/ErrorAlert'
import {
  updateAboutUnitPhoto,
  updateSpecialOfferPhoto,
  updateShopCategoryImage,
  updateLocalizationPhoto,
} from '../../api/unitDataAPI'

const apiFunctions = {
  // logoUpload,
  updateAboutUnitPhoto,
  updateSpecialOfferPhoto,
  updateShopCategoryImage,
  updateLocalizationPhoto,
}

const SingleImageUploader = ({
  apiName,
  id,
  formTitle,
  oldImage,
  uploadFolder,
  fileType,
  cancel,
  backUrl,
}) => {
  const [image, setImage] = useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null)
  const [error, setError] = useState(null)
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (oldImage && Object.keys(oldImage).length > 0) {
      setImagePreviewUrl(
        // image: oldImage || '',
        oldImage || ''
      )
    }
    document.body.style.overflow = 'hidden' // Disable body scroll
  }, [oldImage])

  // ------------
  // Handle image change and preview
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0]
  //   if (file) {
  //     // Validate file type
  //     if (fileType === 'png' && file.type !== 'image/png') {
  //       setError('Accept doar fisiere png.')
  //       return
  //     } else if (
  //       fileType !== 'png' &&
  //       !['image/png', 'image/jpeg'].includes(file.type)
  //     ) {
  //       setError('Accept doar fisiere png sau jpg.')
  //       return
  //     }

  //     const reader = new FileReader()
  //     reader.onloadend = () => {
  //       const img = new Image()
  //       img.src = reader.result

  //       img.onload = () => {
  //         // Validate resolution based on file type
  //         if (fileType === 'png' && (img.width > 500 || img.height > 500)) {
  //           setError('Imaginea este mai mare de 500x500 pixeli.')
  //           return
  //         } else if (
  //           fileType !== 'png' &&
  //           // (img.width < 800 || img.height < 800)
  //           img.width < 600
  //         ) {
  //           setError('Imaginea este mai mica de 600 pixeli.')
  //           return
  //         }

  //         setImage(file) // Store the file object
  //         setImagePreviewUrl(reader.result) // Display preview
  //         setError(null) // Clear error if everything is valid
  //       }
  //     }

  //     reader.readAsDataURL(file) // Convert file to base64 string for preview
  //   }
  // }
  // -----------

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0]
  //   if (file) {
  //     // Check file size (10MB)
  //     const fileSizeLimit = 8 * 1024 * 1024 // 10MB in bytes
  //     if (file.size > fileSizeLimit) {
  //       setError('Fișierul depășește limita de 8MB.')
  //       return
  //     }

  //     // Validate file type
  //     if (fileType === 'png' && file.type !== 'image/png') {
  //       setError('Accept doar fisiere png.')
  //       return
  //     } else if (
  //       fileType !== 'png' &&
  //       !['image/png', 'image/jpeg'].includes(file.type)
  //     ) {
  //       setError('Accept doar fisiere png sau jpg.')
  //       return
  //     }

  //     const reader = new FileReader()
  //     reader.onloadend = () => {
  //       const img = new Image()
  //       img.src = reader.result

  //       img.onload = () => {
  //         // Validate resolution based on file type
  //         if (fileType === 'png' && (img.width > 500 || img.height > 500)) {
  //           setError('Rezolutia este mai mare de 500x500 pixeli.')
  //           return
  //         } else if (fileType !== 'png' && img.width < 600) {
  //           setError('Rezolutia este mai mica de 600 pixeli.')
  //           return
  //         }

  //         setImage(file) // Store the file object
  //         setImagePreviewUrl(reader.result) // Display preview
  //         setError(null) // Clear error if everything is valid
  //       }
  //     }

  //     reader.readAsDataURL(file) // Convert file to base64 string for preview
  //   }
  // }

  // ------------
  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      // Check file size (max 8MB)
      const fileSizeLimit = 15 * 1024 * 1024 // 8MB in bytes
      if (file.size > fileSizeLimit) {
        setError('Fișierul depășește limita de 8MB.')
        return
      }

      // Validate file type
      const fileType = file.type.split('/')[1] // get file extension YOU can comment this line to get the fileType form props
      if (fileType === 'png' && file.type !== 'image/png') {
        setError('Accept doar fisiere png.')
        return
      } else if (
        fileType !== 'png' &&
        !['image/png', 'image/jpeg'].includes(file.type)
      ) {
        setError('Accept doar fisiere png sau jpg.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        const img = new Image()
        img.src = reader.result

        img.onload = () => {
          // -------
          // Comment if you don't want specific rezolution based on file type
          // Validate resolution based on file type
          if (fileType === 'png' && (img.width > 2048 || img.height > 2048)) {
            setError('Rezolutia este mai mare de 2048 x 2048 pixeli.')
            return
            // } else if (fileType !== 'png' && img.width < 600) {
          } else if (
            fileType !== 'png' &&
            (img.width > 2048 || img.height > 2048)
          ) {
            setError('Rezolutia este mai mare de 2048 x 2048 pixeli.')
            return
          }
          // -------------

          // Resize the image if size is greater than 5MB
          if (file.size > 5 * 1024 * 1024) {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            const scaleFactor = Math.sqrt((5 * 1024 * 1024) / file.size) // Scale to fit 5MB
            canvas.width = img.width * scaleFactor
            canvas.height = img.height * scaleFactor

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            canvas.toBlob(
              (blob) => {
                if (blob.size > 5 * 1024 * 1024) {
                  setError('Nu am reușit sa reduc imaginea sub 5MB.')
                  return
                }

                const resizedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                })

                setImage(resizedFile) // Store the resized file object
                setImagePreviewUrl(canvas.toDataURL(file.type)) // Display preview
                setError(null) // Clear error if everything is valid
              },
              file.type,
              0.8
            ) // Adjust quality (0.8) if necessary
          } else {
            setImage(file) // Store the file object
            setImagePreviewUrl(reader.result) // Display preview
            setError(null) // Clear error if everything is valid
          }
        }
      }

      reader.readAsDataURL(file) // Convert file to base64 string for preview
    }
  }

  // ------------

  // Delete image preview and reset
  const handleDeleteImage = () => {
    setImage(null)
    setImagePreviewUrl(null)
    setError(null) // Clear error when resetting
  }

  // Replace image by selecting a new one
  const handleReplaceImage = (e) => {
    handleImageChange(e)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!image) {
      // alert('Te rog selecteaza o imagine')
      setError('Te rog selecteaza o imagine')
      return
    }

    // Get the actual API function from the lookup object
    const apiFunction = apiFunctions[apiName]

    if (!apiFunction) {
      setError(`Invalid API function: ${apiName}`)
      return
    }

    // Set loading state to true
    setIsLoading(true)

    // Extract the file name with extension
    const imageName = image.name

    // Create a FormData object to append the file and the image name
    const formData = new FormData()
    formData.append('image', image) // Ensure the file is sent under 'logo'
    formData.append('imageName', imageName) // Optionally include the image name

    // Use the `logoUpload` function from the API file
    apiFunction(
      id, // Unit ID for the API request
      uploadFolder, // Upload folder specified in the request
      formData, // Pass form data including the image and its name
      handleSuccess,
      handleError
    )
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Imaginea a fost incarcata cu succes!')
    setShowAlert(true)
    setImage(null)
    // navigate('/login')
    // Reset loading state
    setIsLoading(false)
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = `/${backUrl}`
      document.body.style.overflow = ''
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>

      <SingleImageUploaderStyled>
        <div className='formContainer'>
          <div className='infoContainer'>
            <h2>{formTitle}</h2>
            <div className='cancelButtonContainer'>
              <button
                className='closeButton'
                onClick={() => {
                  // setIsOpen(false)

                  setTimeout(cancel, 300) // Delay cancel to allow fade-out

                  // document.body.style.overflow = '' // Re-enable body scroll
                }}
              >
                Renunta
              </button>
            </div>
            <div className='uploadInfo'>
              <span>Suport fisiere in format jpg, png</span>
              <span>Maxim 5 MB</span>
              <span>Rezolutie maxima 2048 px</span>
            </div>
          </div>
          <div className='imageUploadFormContainer'>
            {imagePreviewUrl ? (
              <>
                <div className='previewContainer'>
                  <span className='previewText'>Previzualizare</span>
                  <img
                    src={imagePreviewUrl}
                    alt='Uploaded Preview'
                    className='imagePreview'
                  />
                  <button className='deleteButton' onClick={handleDeleteImage}>
                    Sterge
                  </button>
                </div>
                <div>
                  <label className='replaceButton'>
                    Schimba
                    <input
                      className='hiddenInput'
                      type='file'
                      accept='image/*'
                      name='image'
                      onChange={handleReplaceImage}
                    />
                  </label>
                </div>
              </>
            ) : (
              <div>
                <label className='selectButton'>
                  Alege imaginea
                  <input
                    className='hiddenInput'
                    type='file'
                    accept='image/*'
                    name='image'
                    onChange={handleImageChange}
                  />
                </label>
              </div>
            )}
            {imagePreviewUrl && (
              <div>
                <button
                  className='uploadButton'
                  onClick={handleSubmit}
                  disabled={!image && error && isLoading}
                >
                  {!image || error
                    ? ''
                    : isLoading
                    ? 'Asteapta! Se incarca...'
                    : 'Incarca imaginea!'}
                </button>
              </div>
            )}
          </div>
          {error && (
            <ErrorDysplayStyled>
              <span className='errorMsg'>{error}</span>
            </ErrorDysplayStyled>
          )}
        </div>
      </SingleImageUploaderStyled>
    </>
  )
}

export default SingleImageUploader
