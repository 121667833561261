import { apiRest } from './config'

function loginAdmin(
  username,
  password,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post('/admin/login', { username, password })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addAdmin(
  customerData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/customers`, customerData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// function updateAdmin(
//   id,
//   customerData,
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     .post(`/api/customers/${id}`, customerData)
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

function updateAdminData(
  id,
  customerData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/admins/${id}`, customerData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateAdminUsername(
  id,
  customerData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/admins/username/${id}`, customerData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateAdminEmail(
  id,
  customerData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/admins/email/${id}`, customerData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}
export {
  loginAdmin,
  // getAdmin,
  addAdmin,
  // updateAdmin,
  updateAdminData,
  updateAdminUsername,
  updateAdminEmail,
}
