import React from 'react'
import PageAbout from './pageAbout/PageAbout'
import PageShortFacilitys from './pageShortFacilitys/PageShortFacilitys'
import PageSpecialOffers from './pageSpecialOffers/PageSpecialOffers'

import PageStartInfo from './pageStartInfo/PageStartInfo'
import PageLocalization from './pageLocalization/PageLocalization'

function Page() {
  return (
    <>
      <PageShortFacilitys />
      <PageAbout />
      <PageSpecialOffers />
      <PageStartInfo />
      <PageLocalization />
    </>
  )
}
export default Page
