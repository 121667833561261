import styled from 'styled-components'

export const DeleteButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #e9e9e9;
  margin: 3px;

  &:hover {
    background-color: #fff;
    cursor: pointer;
    /* opacity: 0.8;
    filter: alpha(opacity=80); */
  }
  img {
    width: 35px;
    height: 35px;
    margin-top: 0px;
    /* margin-left: 1px; */
    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }
`
